import React from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import useScreenSize from 'hooks/use-screen-size';

import CourseCreateForm from './course-create-form';

export default function CourseCreateDialog({ onClose, ...params }) {
  const screenSize = useScreenSize();
  const isMobile = screenSize === 'xs';

  return (
    <Dialog fullWidth fullScreen={isMobile} onClose={onClose} {...params}>
      <DialogTitle disableTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">Create a course</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you an instructor considering My Digital Hand for your course?
          Enter your course details below to get started.
        </DialogContentText>

        <Box mb={2}>
          <CourseCreateForm />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
