/**
 * Prepares the chart data. This function should be memoized for performance.
 * Input parameters `tickets` and `teachers` must be arrays, but can be empty.
 */

import teacherTickets from '../../../data-shapers/teacher-tickets';

export default function makeData({
  tickets,
  teachers,
  filterEnabled = true,
  filterMs = 45 * 60 * 1000, // 45 minutes
}) {
  const dict = teacherTickets(tickets);

  return teachers
    .map((user) => {
      if (user.accountId in dict) {
        const helpingDurations = dict[user.accountId]
          .filter((t) => t.status === 'completed')
          .map((t) => new Date(t.completedAt) - new Date(t.startedAt))
          .filter((ms) => !filterEnabled || ms < filterMs);
        const ticketCount = helpingDurations.length;
        const totalHelpingMs = helpingDurations.reduce(
          (cum, ms) => cum + ms,
          0
        );
        if (ticketCount > 0) {
          return {
            first: user.first,
            last: user.last,
            name:
              user.first || user.last
                ? `${user.first} ${user.last}`
                : user.email,
            ticketCount,
            totalHelpingMs,
            avgHelpingMs: totalHelpingMs / ticketCount,
          };
        }
      }
      return {
        first: user.first,
        last: user.last,
        name:
          user.first || user.last ? `${user.first} ${user.last}` : user.email,
        ticketCount: 0,
        totalHelpingMs: 0,
        avgHelpingMs: 0,
      };
    })
    .sort(longestFirst);
}

function longestFirst(a, b) {
  return (
    a.avgHelpingMs - b.avgHelpingMs ||
    `${b.last} ${b.first}`.localeCompare(`${a.last} ${a.first}`)
  );
}
