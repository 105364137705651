import { useRef, useCallback } from 'react';

export default function useDebounced(fn, interval) {
  const timer = useRef(null);
  return useCallback(
    (...args) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(fn, interval, ...args);
    },
    [fn, interval]
  );
}
