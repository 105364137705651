/**
 * Gets "waiting" tickets from the store, optionally filtered for a particular
 * course
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

export const getWaitingTicketIds = createSelector(
  orm.Course.tickets,
  (tickets) =>
    tickets
      .flat()
      .filter((t) => 'waiting' === t.status)
      .sort(oldestFirst)
      .map((t) => t.id)
);

export default function useWaitingTicketIds(courseId) {
  return useSelector((state) => getWaitingTicketIds(state, courseId));
}

function oldestFirst(a, b) {
  return new Date(a.requestedAt) - new Date(b.requestedAt);
}
