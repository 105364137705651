import React from 'react';
import pluralize from 'pluralize';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import HelpCountIcon from '@material-ui/icons/BarChart';

import UserNames from 'components/user/user-names';
import useUsages from 'selectors/usages/use-usages';

export default function HelpCountListItems({ ticket }) {
  const studentIds = ticket.students.map((s) => s.userId);
  const usages = useUsages(studentIds);

  return usages
    .filter((u) => u)
    .map((u) => (
      <HelpCountListItem
        key={u.userId}
        userId={u.userId}
        completedCount={u.completedCount}
        canceledCount={u.canceledCount}
      />
    ));
}

function HelpCountListItem({ userId, completedCount, canceledCount }) {
  if (completedCount === 0) {
    return (
      <ListItem>
        <ListItemIcon>
          <HelpCountIcon color="primary" />
        </ListItemIcon>

        <ListItemText>
          <Typography variant="body2">
            Office hours history for <UserNames ids={[userId]} lowercaseYou />
          </Typography>

          <Typography variant="body2" color="textSecondary">
            First time at office hours
          </Typography>
        </ListItemText>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemIcon>
          <HelpCountIcon color="primary" />
        </ListItemIcon>

        <ListItemText>
          <Typography variant="body2">
            Office hours history for <UserNames ids={[userId]} lowercaseYou />
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {completedCount} completed{' '}
            {pluralize('office hours request', Number(completedCount))}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {canceledCount} canceled{' '}
            {pluralize('office hours request', Number(canceledCount))}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }
}
