import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Section from 'components/content/section';
import SurveyQuestionCard from './question-card';
import surveyQuestions from '../../data-shapers/survey-questions';

export default function StudentFeedbackSection({ tickets, isLoading }) {
  const questions = useMemo(() => surveyQuestions(tickets, 'studentFeedback'), [
    tickets,
  ]);

  if (isLoading) {
    return (
      <Section title="Post-interaction student feedback">
        <Typography>
          <em>Loading data...</em>
        </Typography>
      </Section>
    );
  } else if (questions.length === 0) {
    return (
      <Section title="Post-interaction student feedback">
        <Typography>
          <em>No data to display.</em>
        </Typography>
      </Section>
    );
  }

  return (
    <Section title="Post-interaction student feedback">
      <Typography paragraph>
        Students were asked these questions after receiving help at office
        hours.
      </Typography>

      {questions.map(({ question, responses, key }) => (
        <SurveyQuestionCard
          question={question}
          responses={responses}
          ticketCount={tickets.length}
          key={key}
        />
      ))}
    </Section>
  );
}
