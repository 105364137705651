import { ORM } from 'redux-orm';

import Course from './course';
import Chat from './chat';
import Ticket from './ticket';
import Usage from './usage';
import User from './user';

// Create orm
const orm = new ORM({
  stateSelector: (state) => state.orm,
});

// Register models
orm.register(Course, Chat, Ticket, Usage, User);

// Export
export default orm;
