import React, { useCallback, useState } from 'react';
import MaterialTable from 'material-table';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Icons from 'components/tables/table-icons';
import useAccount from 'selectors/auth/use-account';
import StatusColumn from './status-column';
import ActionsColumn from './actions-column';
import AddEmailDialog from '../add-email-dialog/dialog';

export default function EmailsTable() {
  const { emails } = useAccount();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <>
      <Typography variant="h6" paragraph>
        Your emails
      </Typography>

      <Typography paragraph>
        Your account is associated with the following emails. If you use
        multiple email addresses at your university or institution, you may wish
        to add them below.
      </Typography>

      <MaterialTable
        title="Manage emails"
        data={emails}
        icons={Icons}
        options={{
          paging: false,
          thirdSortClick: false,
          toolbar: false,
        }}
        columns={[
          {
            title: 'Email address',
            field: 'address',
            defaultSort: 'asc',
          },
          {
            title: 'Status',
            field: 'status',
            render: StatusColumn,
            sorting: false,
          },
          {
            title: 'Actions',
            render: ActionsColumn,
            sorting: false,
            width: null,
            cellStyle: { width: 0 },
          },
        ]}
        components={{ Container: ({ children }) => children }}
      />

      <Box mt={3} mb={1} textAlign="right">
        <Button
          color="primary"
          variant="contained"
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          Add email
        </Button>
      </Box>

      <AddEmailDialog open={isDialogOpen} onClose={handleClose} />
    </>
  );
}
