import { useMediaQuery } from '@material-ui/core';

export default function useScreenSize() {
  const xs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const sm = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const md = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const lg = useMediaQuery((theme) => theme.breakpoints.only('lg'));

  return xs ? 'xs' : sm ? 'sm' : md ? 'md' : lg ? 'lg' : 'xl';
}
