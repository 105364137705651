import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import ReactDataSheet from 'react-datasheet';
import pluralize from 'pluralize';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import AddRowIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import BackIcon from '@material-ui/icons/ChevronLeft';

import inviteUsersByEmail from 'api/routes/courses/invite-users-by-email';
import LoadingButton from 'components/buttons/loading-button';
import emailRegex from 'utils/email-regex';

import 'react-datasheet/lib/react-datasheet.css';
import './react-datasheet-overrides.css';

const HEADERS = ['email', 'first', 'last', 'identifier', 'group'];

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
  },
  wrapper: {
    maxHeight: '300px',
    overflow: 'hidden auto',
  },
}));

export default function RosterTableUpload({
  courseId,
  role,
  data,
  onBack,
  onUpload,
}) {
  const classes = useStyles();
  const [grid, setGrid] = useState([]);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Array.isArray(data)) {
      setGrid(
        data.map((row) => HEADERS.map((value) => ({ value: row[value] })))
      );
    } else {
      setGrid([]);
    }
  }, [data]);

  useEffect(() => {
    if (!isSubmitting) {
      return;
    }
    setError(null);
    const roster = grid.map((row) =>
      HEADERS.reduce((o, k, i) => ({ ...o, [k]: row[i]?.value }), {})
    );
    let isActive = true;
    inviteUsersByEmail({ courseId, role, roster })
      .then(({ status, data }) => {
        if (isActive) {
          switch (status) {
            case 200:
              onUpload instanceof Function && onUpload(data);
              break;
            default:
              setError('An error occurred while uploading your roster.');
          }
        }
      })
      .finally(() => {
        if (isActive) {
          setIsSubmitting(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [isSubmitting, grid, onUpload, courseId, role]);

  const handleBack = () => {
    setError(null);
    onBack instanceof Function && onBack();
  };

  const handleChange = (changes) => {
    const newGrid = grid.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      newGrid[row][col] = { ...newGrid[row][col], value };
    });
    setGrid(newGrid);
  };

  const handleAddRow = () => {
    setGrid([
      ...grid,
      [
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
      ],
    ]);
  };

  const handleSubmit = () => {
    const invalidEmails = grid
      .map((row) => row[HEADERS.indexOf('email')].value)
      .filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 1) {
      setError(
        `Error: '${invalidEmails[0]}' and ${pluralize(
          'other',
          invalidEmails.length - 1,
          true
        )} are invalid emails.`
      );
      return;
    } else if (invalidEmails.length > 0) {
      setError(`Error: '${invalidEmails[0]}' is an invalid email.`);
      return;
    }

    setIsSubmitting(true);
  };

  return (
    <>
      <DialogContent>
        <DialogContentText>
          The following user data was imported. Make modifications as necessary,
          then press the invite button to upload the roster to the server and
          send out invitation emails.
        </DialogContentText>

        <Box my={2} className={classes.wrapper}>
          <ReactDataSheet
            data={grid}
            overflow="clip"
            sheetRenderer={SheetRenderer}
            valueRenderer={(cell) => cell.value}
            className={classes.table}
            onCellsChanged={handleChange}
          />
        </Box>

        <Collapse in={Boolean(error)}>
          <Typography paragraph variant="subtitle2" color="error">
            {error}
          </Typography>
        </Collapse>

        <DialogActions>
          <Button
            color="primary"
            onClick={handleAddRow}
            startIcon={<AddRowIcon />}
          >
            Add row
          </Button>

          <Button color="primary" onClick={handleBack} startIcon={<BackIcon />}>
            Back
          </Button>

          <LoadingButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            startIcon={<SendIcon />}
          >
            Invite {grid.length} by email
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </>
  );
}

function SheetRenderer({ children, ...props }) {
  return (
    <table {...props}>
      <thead>
        <tr>
          {HEADERS.map((col) => (
            <th key={col}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
