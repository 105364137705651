import { useEffect, useState } from 'react';
import schoolCourseIndexRoute from 'api/routes/courses/school-course-index';

export default function useSchoolCourses(school) {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (!school) {
      setCourses([]);
      return;
    }
    let isActive = true;
    schoolCourseIndexRoute(school).then(({ data }) => {
      isActive && Array.isArray(data) && setCourses(data);
    });
    return () => {
      isActive = false;
    };
  }, [school]);

  return courses;
}
