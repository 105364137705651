import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import ExportIcon from '@material-ui/icons/GetApp';
import ExportButton from './button';

export default function InteractionExportSection({
  courseId,
  tickets,
  isLoading,
}) {
  return (
    <Section title="Export interaction data">
      <Typography paragraph>
        Your course interaction data can be downloaded below in CSV file format.
      </Typography>

      <Typography paragraph>
        <ExportButton
          courseId={courseId}
          tickets={tickets}
          isLoading={isLoading}
          color="primary"
          variant="contained"
          startIcon={<ExportIcon />}
        >
          Export interaction data
        </ExportButton>
      </Typography>
    </Section>
  );
}
