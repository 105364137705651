import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '@material-ui/icons/Close';

import Dialog from 'components/content/dialog';
import AddEmailForm from './form';

export default function AddEmailDialog({ onClose, ...params }) {
  return (
    <Dialog fullWidth {...params}>
      <DialogTitle disableTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">Add email to account</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography paragraph>
          Do you use multiple email addresses at your university or institution?
          Use the form below to add another email address to your account.
        </Typography>

        <AddEmailForm onSubmit={onClose} />
      </DialogContent>
    </Dialog>
  );
}
