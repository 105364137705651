import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import fetchEntryCodes from 'actions/courses/fetch-entry-codes';
import useCourse from './use-course';

export default function useCourseEntryCodes(id) {
  const dispatch = useDispatch();
  const course = useCourse(id);

  useEffect(() => {
    dispatch(fetchEntryCodes(id));
  }, [dispatch, id]);

  if (course) {
    return {
      canStudentsEnrollByCode: course.canStudentsEnrollByCode,
      studentEnrollCode: course.studentEnrollCode,
      canStaffEnrollByCode: course.canStaffEnrollByCode,
      staffEnrollCode: course.staffEnrollCode,
    };
  } else {
    return null;
  }
}
