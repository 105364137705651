import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

export default function AlertDialog({
  title,
  content,
  body,
  buttonText = 'Info',
  buttonProps = {},
  closeText = 'Close',
  closeProps = {},
  onOpen,
  onClose,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleClickClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        children={buttonText}
        {...buttonProps}
      />

      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClickClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {content && <DialogContentText children={content} />}
          {body}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary" {...closeProps}>
            {closeText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
