import statusFilter from '../../../data-shapers/status-filter';
import studentTickets from '../../../data-shapers/student-tickets';

export default function makeData({ tickets, studentCount }) {
  const ticketArrays = Object.values(studentTickets(tickets)).map((arr) =>
    statusFilter(arr, 'completed')
  );
  const students = addMissingStudents(
    ticketArrays.sort(mostInteractionsFirst),
    studentCount
  );

  const completedCounts = students
    .map((arr) => arr.length)
    .map((v, i) => ({ x: i + 1, y: v }));

  return [{ id: 'completed', data: completedCounts }];
}

function addMissingStudents(students, studentCount) {
  students = [...students];
  while (students.length < studentCount) {
    students.push([]);
  }
  return students;
}

function mostInteractionsFirst(a, b) {
  return b.length - a.length;
}
