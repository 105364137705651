import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import AttributesPage from './attributes/attributes-page';
import BehaviorPage from './behavior/behavior-page';
import FeedbackPage from './feedback/feedback-page';
import EnrollmentPage from './enrollment/enrollment-page';
import IndexPage from './index/settings-index-page';
import RosterPage from './roster/roster-page';
import CalendarPage from './calendar/calendar-page';

export default function SettingsRouter({ role }) {
  if (role !== 'instructor') {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route exact path="/courses/:courseId/settings/attributes">
        <AttributesPage />
      </Route>

      <Route exact path="/courses/:courseId/settings/behavior">
        <BehaviorPage />
      </Route>

      <Route exact path="/courses/:courseId/settings/feedback">
        <FeedbackPage />
      </Route>

      <Route exact path="/courses/:courseId/settings/enrollment">
        <EnrollmentPage />
      </Route>

      <Route exact path="/courses/:courseId/settings/roster">
        <RosterPage />
      </Route>

      <Route exact path="/courses/:courseId/settings/calendar">
        <CalendarPage />
      </Route>

      <Route exact path="/courses/:courseId/settings">
        <IndexPage />
      </Route>
    </Switch>
  );
}
