/**
 * Fetches a page of results of all recent tickets in a particular course
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import courseTicketIndexRoute from 'api/routes/tickets/course-ticket-index';
import loadRecords from 'actions/load-records';

export default function fetchTicketActivity(courseId, page = 0) {
  return (dispatch) => {
    return courseTicketIndexRoute(courseId, page).then(
      ({ status, headers, data }) => {
        if (status === 200) {
          dispatch(loadRecords(data));
        }
        return { status, headers, data };
      }
    );
  };
}
