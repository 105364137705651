import React, { useState, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

import ActionsIcon from '@material-ui/icons/List';

import CancelMenuItem from './menu-items/cancel-ticket';
import CallStudentMenuItem from './menu-items/call-student';
import OpenMenuItem from './menu-items/open-ticket';
import RemoteHelpMenuItem from './menu-items/remote-help';
import VirtualLinkForm from './virtual-link-form';

export default function ActionMenu({ ticketId, onSelect }) {
  const [anchor, setAnchor] = useState(null);
  const [isVirtual, setIsVirtual] = useState(false);

  const handleOpen = useCallback((event) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const toggleVirtual = useCallback(() => {
    setAnchor(null);
    setIsVirtual((x) => !x);
  }, []);

  if (isVirtual) {
    return (
      <VirtualLinkForm
        ticketId={ticketId}
        onSubmit={onSelect}
        onCancel={toggleVirtual}
      />
    );
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        color="primary"
        variant="contained"
        startIcon={<ActionsIcon />}
      >
        Actions
      </Button>

      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        getContentAnchorEl={null}
      >
        <CancelMenuItem id={ticketId} onClick={onSelect} />
        <OpenMenuItem id={ticketId} onClick={onSelect} />
        <CallStudentMenuItem
          id={ticketId}
          variant="contained"
          onClick={onSelect}
        />
        <RemoteHelpMenuItem id={ticketId} onClick={toggleVirtual} />
      </Menu>
    </>
  );
}
