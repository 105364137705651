import statusFilter from '../../../data-shapers/status-filter';
import numTicketsPerWeekday from '../../../data-shapers/num-tickets-per-weekday';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export default function makeData(tickets) {
  const completedTickets = statusFilter(tickets, 'completed');
  const canceledTickets = statusFilter(tickets, 'canceled');
  const completedCounts = numTicketsPerWeekday(completedTickets);
  const canceledCounts = numTicketsPerWeekday(canceledTickets);
  return days.map((day) => ({
    day,
    completed: completedCounts[day] ?? 0,
    canceled: canceledCounts[day] ?? 0,
  }));
}
