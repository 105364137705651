/**
 * Fetches the entry codes for a course; debounces rapid requests for entry
 * codes from the same course
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import readEntryCodesRoute from 'api/routes/courses/read-entry-codes';
import { courseChanged } from './actions';

let promise = null;

export default function fetchEntryCodes(courseId) {
  return (dispatch) => {
    if (!(promise instanceof Promise)) {
      promise = readEntryCodesRoute(courseId)
        .then(({ data }) => {
          dispatch(courseChanged(data));
        })
        .finally(() => {
          promise = null;
        });
    }
    return promise;
  };
}
