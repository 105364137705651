import React, { useEffect, useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Done';

import useAccountUser from 'selectors/users/use-account-user';
import LoadingButton from 'components/buttons/loading-button';
import updateUserRoute from 'api/routes/users/update-user';

export default function EditUserForm({
  userId,
  courseId,
  fields,
  onComplete,
  onClickDelete,
}) {
  fields = useMemo(() => fields ?? {}, [fields]);
  const user = useAccountUser(courseId);
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors,
    control,
  } = useForm({ defaultValues: fields });

  const submitForm = useCallback(
    (data) => {
      updateUserRoute({ ...data, courseId, userId }).then(({ status }) => {
        if (status === 200 && onComplete instanceof Function) {
          onComplete();
        }
      });
    },
    [courseId, userId, onComplete]
  );

  useEffect(() => {
    reset(fields);
  }, [reset, fields]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box pb={1} clone>
        <FormControl fullWidth>
          <Controller
            name="role"
            control={control}
            disabled={userId === user?.id}
            as={
              <Select variant="outlined">
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="staff">Staff member</MenuItem>
                <MenuItem value="instructor">Instructor</MenuItem>
              </Select>
            }
          />
          <FormHelperText>The user&apos;s role in your course</FormHelperText>
        </FormControl>
      </Box>

      <TextField
        name="first"
        label="First name"
        helperText="The user's first or given name"
        variant="outlined"
        margin="normal"
        fullWidth
        disabled={isSubmitting}
        error={Boolean(errors.first)}
        inputRef={register()}
      />

      <TextField
        name="last"
        label="Last name"
        helperText="The user's last or family name"
        variant="outlined"
        margin="normal"
        fullWidth
        disabled={isSubmitting}
        error={Boolean(errors.last)}
        inputRef={register()}
      />

      <TextField
        name="identifier"
        label="User ID"
        helperText="The user's unique institutional ID"
        variant="outlined"
        margin="normal"
        fullWidth
        disabled={isSubmitting}
        error={Boolean(errors.identifier)}
        inputRef={register()}
      />

      <TextField
        name="group"
        label="Group"
        helperText="The user's group or section code"
        variant="outlined"
        margin="normal"
        fullWidth
        disabled={isSubmitting}
        error={Boolean(errors.group)}
        inputRef={register()}
      />

      <Box mt={1} mb={2}>
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item>
            <Tooltip title="Delete this user" placement="top">
              <IconButton color="secondary" onClick={onClickDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SaveIcon />}
              loading={isSubmitting}
            >
              Save changes
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
