import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider as MomentProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Router from './router/router';
import store from './store';
import theme from './styles/theme';
import 'react-placeholder/lib/reactPlaceholder.css';
import './api/socket-event-handlers';

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <MomentProvider utils={MomentUtils}>
        <CssBaseline />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </MomentProvider>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
