import React, { forwardRef } from 'react';
import Moment from 'react-moment';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import UserAvatar from 'components/user/user-avatar';
import UserNames from 'components/user/user-names';
import useTicket from 'selectors/tickets/use-ticket';

export default forwardRef(StudentListItem);

function StudentListItem({ ticketId, ...props }, ref) {
  const ticket = useTicket(ticketId);

  if (!ticket || !Array.isArray(ticket.students)) {
    return null;
  }

  const studentIds = ticket.students.map((s) => s.userId);

  return (
    <ListItem>
      <ListItemAvatar>
        <UserAvatar ids={studentIds} />
      </ListItemAvatar>

      <ListItemText
        primary={<UserNames ids={studentIds} />}
        secondary={
          <>
            Waiting for{' '}
            <Moment toNow ago>
              {ticket.requestedAt}
            </Moment>
          </>
        }
      />
    </ListItem>
  );
}
