import React, { useCallback } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import cancelTicketRoute from 'api/routes/tickets/cancel-ticket';
import UserList from 'components/user/user-list';
import ConfirmDialog from 'components/dialogs/confirm';

import HelpDialog from './help-dialog';

export default function HandCardCalled({ ticket }) {
  const { id } = ticket;
  const teachers = ticket.teachers.filter((t) => t.status === 'active');

  const handleCancelClick = useCallback(() => {
    return cancelTicketRoute(id);
  }, [id]);

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5">
          It&apos;s your turn!
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
          The following help staff{' '}
          {teachers.length > 1 ? 'members are' : 'member is'} ready to help!
          Find them now at the designated office hours area.
        </Typography>

        <UserList
          userIds={teachers.map((t) => t.userId)}
          courseId={ticket.courseId}
        />
      </CardContent>

      <CardActions>
        <ConfirmDialog
          title="Cancel this request?"
          content="You will be removed from the waitlist and your request for help will be canceled. If you raise your hand again, you'll be placed back at the end of the line. Is this okay?"
          buttonProps={{ color: 'secondary', variant: 'contained' }}
          buttonText="Cancel request"
          acceptText="I don't need help anymore"
          acceptProps={{ color: 'secondary', variant: 'contained' }}
          rejectText="I still need help"
          rejectProps={{ color: 'primary' }}
          onConfirm={handleCancelClick}
        />

        <HelpDialog currentStep={3} />
      </CardActions>
    </Card>
  );
}
