import React, { useCallback, forwardRef } from 'react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import NotificationIcon from '@material-ui/icons/Notifications';

import callTicketRoute from 'api/routes/tickets/call-ticket';

export default forwardRef(CallMenuItem);

function CallMenuItem({ id, onClick, ...props }, ref) {
  const handleClick = useCallback(
    (event) => {
      onClick instanceof Function && onClick(event);
      return callTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <MenuItem onClick={handleClick} ref={ref} {...props}>
      <ListItemIcon>
        <NotificationIcon color="primary" />
      </ListItemIcon>
      <ListItemText>Alert the student to come find you</ListItemText>
    </MenuItem>
  );
}
