import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import Chart from './chart';

export default function StudentDistSection({
  tickets,
  studentCount,
  isLoading,
}) {
  return (
    <Section title="Student usage distribution">
      <Typography paragraph>
        This graph shows the number of office hours interactions initiated by
        each student. Use it to get a feel for the proportion of students who
        use office hours.
      </Typography>

      <Chart
        tickets={tickets}
        studentCount={studentCount}
        isLoading={isLoading}
      />
    </Section>
  );
}
