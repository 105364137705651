import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import Chart from './chart';

export default function TrafficDuringWeekSection({ tickets, isLoading }) {
  return (
    <Section title="Traffic during the week">
      <Typography paragraph>
        This chart shows which portions of the week see the most traffic. Use it
        to evaluate if office hours are being allocated to the optimal days and
        times of the week.
      </Typography>

      <Chart tickets={tickets} isLoading={isLoading} />
    </Section>
  );
}
