import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import SelectQuestionType from './select-question-type';
import OptionsControlList from './options-control-list';
import ReorderQuestionMenu from './reorder-question-menu';

export default function EditQuestionCard({
  question,
  questionIndex,
  numQuestions,
  onSave,
  onMoveUp,
  onMoveDown,
  onDelete,
  isAutocompleteAnOption,
}) {
  const { reset, register, handleSubmit, watch, control } = useForm({
    defaultValues: question,
  });

  const fields = watch([
    'type',
    'allowCustomResponse',
    'hasAutocomplete',
    'isRequired',
  ]);

  useEffect(() => {
    register({ name: 'allowCustomResponse' });
  }, [register]);

  useEffect(() => {
    reset(question);
  }, [reset, question]);

  return (
    <Box clone my={2} p={1}>
      <Paper elevation={3} variant="outlined">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <TextField
              label={`Question ${questionIndex + 1}`}
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              autoFocus
              name="prompt"
              inputRef={register}
            />
          </Grid>

          <Grid item>
            <Controller
              as={SelectQuestionType}
              name="type"
              control={control}
              onChange={([event]) => event.target.value}
            />
          </Grid>
        </Grid>

        <OptionsControlList
          name="options"
          control={control}
          type={fields.type}
          allowCustomResponse={fields.allowCustomResponse}
        />

        <Collapse in={fields.type === 'short-answer' && isAutocompleteAnOption}>
          <Box my={1}>
            <FormControlLabel
              control={
                <Controller
                  as={Switch}
                  name="hasAutocomplete"
                  control={control}
                  color="primary"
                  onChange={([, value]) => value}
                />
              }
              label={
                fields.hasAutocomplete
                  ? 'Autocomplete is enabled using recent student answers'
                  : 'Autocomplete is disabled for this question'
              }
            />
          </Box>
        </Collapse>

        <Grid container alignItems="center" spacing={1} justify="flex-end">
          <Grid item xs>
            <FormControlLabel
              control={
                <Controller
                  as={Switch}
                  name="isRequired"
                  control={control}
                  color="primary"
                  onChange={([, value]) => value}
                />
              }
              label={fields.isRequired ? 'Required' : 'Optional'}
            />
          </Grid>

          <Grid item>
            <Tooltip title="Delete">
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          {numQuestions > 1 && (
            <Grid item>
              <ReorderQuestionMenu
                currentQuestionIndex={questionIndex}
                numQuestions={numQuestions}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
              />
            </Grid>
          )}

          <Grid item>
            <Tooltip title="Save">
              <IconButton onClick={handleSubmit(onSave)}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
