import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import ThreadList from './thread-list';
import AppBar from 'components/app-bar/app-bar';
import useAccountUser from 'selectors/users/use-account-user';

export default function ChatRouter() {
  const params = useParams();
  const courseId = Number(params.courseId);
  const accountUser = useAccountUser(courseId);

  if (!accountUser) {
    return null;
  }

  return (
    <AppBar title="Chat">
      <Box clone py={1}>
        <Paper>
          <ThreadList userId={accountUser.id} />
        </Paper>
      </Box>
    </AppBar>
  );
}
