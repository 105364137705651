import React from 'react';
import MaterialTable from 'material-table';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AcceptIcon from '@material-ui/icons/Check';

import Icons from 'components/tables/table-icons';
import capitalize from 'utils/capitalize';
import CourseTitleColumn from './course-title-column';
import StatusColumn from './status-column';

export default function CoursesTable({
  onClickAccept,
  enrollments,
  isLoading,
}) {
  return (
    <>
      <MaterialTable
        isLoading={isLoading}
        data={enrollments}
        icons={Icons}
        options={{
          paging: false,
          sorting: false,
          toolbar: false,
        }}
        columns={[
          {
            title: 'Status',
            width: null,
            cellStyle: { width: 0 },
            render: StatusColumn,
          },
          {
            title: 'Course',
            field: 'courseTitle',
            render: CourseTitleColumn,
          },
          {
            title: 'Semester',
            field: 'courseYear',
            render: ({ courseSemester, courseYear }) =>
              `${courseSemester} ${courseYear}`,
          },
          {
            title: 'Role',
            field: 'role',
            render: ({ role }) => capitalize(role),
          },
          {
            title: 'Actions',
            width: null,
            cellStyle: { width: 0 },
            render: (data) => (
              <AcceptInvitationButton
                onClick={() => onClickAccept(data.userId)}
                status={data.status}
                courseStatus={data.courseStatus}
              />
            ),
          },
        ]}
        components={{ Container: ({ children }) => children }}
      />
    </>
  );
}

function AcceptInvitationButton({ status, courseStatus, onClick }) {
  if (status === 'invited' && courseStatus === 'active') {
    return (
      <Tooltip placement="top" title="Accept invitation">
        <IconButton color="primary" onClick={onClick}>
          <AcceptIcon />
        </IconButton>
      </Tooltip>
    );
  } else {
    return null;
  }
}
