import React from 'react';
import { forwardRef } from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import QuestionTypeIcon from './question-type-icon';

export default forwardRef(QuestionOption);

function QuestionOption(
  { onDelete, onClick, type, canDelete = true, ...props },
  ref
) {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item onClick={onClick}>
        <Box pt={1.5}>
          <QuestionTypeIcon type={type} color="disabled" />
        </Box>
      </Grid>

      <Grid item xs onClick={onClick}>
        <TextField
          variant="standard"
          margin="normal"
          required
          multiline
          ref={ref}
          {...props}
        />
      </Grid>

      {canDelete && (
        <Grid item>
          <Box pt={1.5}>
            <IconButton onClick={onDelete}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
