import React from 'react';
import Section from 'components/content/section';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chart from './chart';

export default function TeacherTicketLengthSection({
  tickets,
  teachers,
  isLoading,
}) {
  return (
    <Box mt={7}>
      <Section title="Average interaction length">
        <Typography paragraph>
          This chart shows the average interaction length for each staff member.
          Use it to determine whether student office hours experiences are
          consistent across staff members.
        </Typography>

        <Chart tickets={tickets} teachers={teachers} isLoading={isLoading} />
      </Section>
    </Box>
  );
}
