import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AcceptPage from './accept/accept-page';

export default function InvitationsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`} component={AcceptPage} />
    </Switch>
  );
}
