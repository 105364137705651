import { useMemo } from 'react';

import useMyRecentTickets from 'selectors/tickets/use-my-recent-tickets';
import useAccount from 'selectors/auth/use-account';

// Feedback is only required for tickets submitted in the last 10 days
const period = 10 * 24 * 60 * 60 * 1000;

export default function useRecentTicketsAwaitingFeedback(courseId) {
  const allTickets = useMyRecentTickets(courseId);
  const account = useAccount();

  const tickets = useMemo(
    () =>
      allTickets
        .filter((t) =>
          t.students.some((u) => u.accountId === account.id && !u.hasFeedback)
        )
        .filter((t) => new Date(t.completedAt).getTime() > Date.now() - period),
    [allTickets, account.id]
  );

  return tickets;
}
