import { useEffect, useState } from 'react';
import schoolIndexRoute from 'api/routes/schools/school-index';

export default function useSchools() {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    let isActive = true;
    schoolIndexRoute().then(({ data }) => {
      isActive && Array.isArray(data) && setSchools(data);
    });
    return () => {
      isActive = false;
    };
  }, []);

  return schools;
}
