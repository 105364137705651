import React from 'react';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import ConfirmedIcon from '@material-ui/icons/Check';
import PrimaryIcon from '@material-ui/icons/Check';
import PendingIcon from '@material-ui/icons/MoreHoriz';

// NOTE: Cannot use hooks in this component (material-table limitation)
export default function StatusColumn({ status, isPrimary }) {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <StatusChip status={status} />
      </Grid>
      <Grid item>
        <PrimaryChip isPrimary={isPrimary} />
      </Grid>
    </Grid>
  );
}

function StatusChip({ status }) {
  switch (status) {
    case 'confirmed':
      return (
        <Chip
          icon={<ConfirmedIcon />}
          color="primary"
          variant="outlined"
          label="confirmed"
          size="small"
        />
      );
    case 'unconfirmed':
      return (
        <Chip
          icon={<PendingIcon />}
          color="secondary"
          variant="outlined"
          label="pending"
          size="small"
        />
      );
    default:
      return null;
  }
}

function PrimaryChip({ isPrimary }) {
  if (isPrimary) {
    return (
      <Chip
        icon={<PrimaryIcon />}
        color="primary"
        variant="default"
        label="primary"
        size="small"
      />
    );
  } else {
    return null;
  }
}
