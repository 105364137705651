import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import fetchTicketHistory from 'actions/tickets/fetch-history';
import AppBar from 'components/app-bar/app-bar';
import Section from 'components/content/section';
import TicketCard from 'components/tickets/ticket';

import OfficeHoursMenu from '../menu/office-hours-menu';

export default function HistoryPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const courseId = Number(params.courseId);
  const [page, setPage] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = useCallback(() => {
    dispatch(fetchTicketHistory(courseId, page)).then(({ status, data }) => {
      if (status === 200 && data.tickets.length > 0) {
        setTickets((tickets) => combine([...tickets, ...data.tickets]));
        setPage((p) => p + 1);
      } else {
        setHasMore(false);
      }
    });
  }, [dispatch, courseId, page]);

  useEffect(() => {
    setPage(0);
    setTickets([]);
    setHasMore(true);
  }, [courseId]);

  useEffect(() => {
    if (page === 0 && tickets.length === 0 && hasMore) {
      loadMore();
    }
  }, [page, tickets.length, loadMore, hasMore]);

  return (
    <AppBar title="History" menu={<OfficeHoursMenu courseId={courseId} />}>
      <Box p={2}>
        <Paper>
          <Box p={2}>
            <Section title="History">
              <InfiniteScroll
                initialLoad={false}
                hasMore={hasMore}
                loadMore={loadMore}
              >
                {tickets.map((ticket) => (
                  <TicketCard key={ticket.id} ticket={ticket} />
                ))}
              </InfiniteScroll>

              {tickets.length === 0 && (
                <Typography paragraph>
                  After you&apos;ve been to office hours, your most recent
                  experiences will appear here.
                </Typography>
              )}
            </Section>
          </Box>
        </Paper>
      </Box>
    </AppBar>
  );
}

function combine(tickets) {
  return Object.values(
    tickets.reduce((o, t) => ({ ...o, [t.id]: t }), {})
  ).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
}
