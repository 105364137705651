import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import Chart from './chart';

export default function TrafficByWeekdaySection({ tickets, isLoading }) {
  return (
    <Section title="Traffic by day of the week">
      <Typography paragraph>
        This chart shows which days of the week are busiest on average. Use it
        to evaluate if office hours are being allocated to the optimal days of
        the week.
      </Typography>

      <Chart tickets={tickets} isLoading={isLoading} />
    </Section>
  );
}
