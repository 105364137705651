import { Model, fk, attr } from 'redux-orm';

export default class Chat extends Model {
  static reducer(action, Chat, session) {
    switch (action.type) {
      case 'CHAT_FETCHED':
      case 'CHAT_SENT':
        Chat.upsert(action.payload);
        break;
      case 'CHAT_DELIVERED':
      case 'CHAT_READ':
        if (Chat.idExists(action.payload.id)) {
          Chat.withId(action.payload.id).update(action.payload);
        }
        break;
      case 'AUTH_INVALIDATED':
        Chat.all()
          .toModelArray()
          .forEach((c) => c.delete());
        break;
      default:
    }
  }
}

Chat.modelName = 'Chat';

Chat.fields = {
  id: attr(),

  // Status
  status: attr(),

  // Body
  body: attr(),

  // Timestamps
  sentAt: attr(),
  deliveredAt: attr(),
  readAt: attr(),
  updatedAt: attr(),

  // Related users
  senderId: fk({
    to: 'User',
    as: 'sender',
    relatedName: 'chatsSent',
  }),
  recipientId: fk({
    to: 'User',
    as: 'recipient',
    relatedName: 'chatsReceived',
  }),
};
