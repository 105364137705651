/**
 * Fetches all completed tickets with missing feedback in active courses for the
 * authenticated user
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import loadRecords from 'actions/load-records';
import missingTicketIndexRoute from 'api/routes/tickets/missing-ticket-index';

export default function fetchMissingTickets() {
  return (dispatch) => {
    return missingTicketIndexRoute().then(({ data }) => {
      dispatch(loadRecords(data));
    });
  };
}
