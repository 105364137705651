/**
 * Gets "waiting", "calling", or "helping" tickets from the store, optionally
 *   filtered for a particular course
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

export const getActiveTickets = createSelector(orm.Course.tickets, (tickets) =>
  tickets
    .flat()
    .filter((t) => ['waiting', 'calling', 'helping'].includes(t.status))
    .sort(oldestFirst)
);

export default function useActiveTickets(...args) {
  return useSelector((state) => getActiveTickets(state, ...args));
}

function oldestFirst(a, b) {
  return new Date(a.requestedAt) - new Date(b.requestedAt);
}
