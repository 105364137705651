import React from 'react';

import Dialog from 'components/content/dialog';

import DialogActions from './dialog-actions/dialog-actions';
import DialogContent from './dialog-content/dialog-content';

export default function DetailsDialog({ ticketId, onClose, ...props }) {
  return (
    <Dialog fullWidth onClose={onClose} {...props}>
      <DialogContent ticketId={ticketId} />
      <DialogActions ticketId={ticketId} onAction={onClose} />
    </Dialog>
  );
}
