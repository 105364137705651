import React from 'react';
import { useParams } from 'react-router-dom';

import AppBar from 'components/app-bar/app-bar';

import OfficeHoursMenu from './office-hours-menu';

export default function OfficeHoursIndex() {
  const params = useParams();
  const courseId = Number(params.courseId);
  return (
    <>
      <AppBar title="Office hours" />
      <OfficeHoursMenu courseId={courseId} />
    </>
  );
}
