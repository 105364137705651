import moment from 'moment';

export default function numTicketsPerWeekday(tickets, field = 'requestedAt') {
  const dict = {
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  };
  tickets.forEach((ticket) => {
    const weekday = moment(ticket[field]).format('dddd');
    dict[weekday]++;
  });
  return dict;
}
