import React from 'react';
import TrafficHeatmapSection from './sections/traffic-heatmap/section';
import StudentDistSection from './sections/student-dist/section';
import TrafficDuringWeekSection from './sections/traffic-during-week/section';
import TrafficByHourSection from './sections/traffic-by-hour/section';
import TrafficByWeekdaySection from './sections/traffic-by-weekday/section';

export default function StudentUsageTab({ tickets, studentCount, isLoading }) {
  return (
    <>
      <TrafficHeatmapSection tickets={tickets} isLoading={isLoading} />
      <TrafficDuringWeekSection tickets={tickets} isLoading={isLoading} />
      <TrafficByWeekdaySection tickets={tickets} isLoading={isLoading} />
      <TrafficByHourSection tickets={tickets} isLoading={isLoading} />
      <StudentDistSection
        tickets={tickets}
        studentCount={studentCount}
        isLoading={isLoading}
      />
    </>
  );
}
