import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import useScreenSize from 'hooks/use-screen-size';

import LookupEmailForm from './lookup-email-form';
import LookupResults from './lookup-results';

export default function LookupDialog({ open, closable }) {
  const [email, setEmail] = useState(null);
  const [isOpen, setIsOpen] = useState(Boolean(open));
  const screenSize = useScreenSize();

  useEffect(() => {
    setIsOpen(Boolean(open));
  }, [open]);

  useEffect(() => {
    if (!isOpen) {
      setEmail(null);
    }
  }, [isOpen]);

  const handleClose = () => {
    if (closable) {
      setIsOpen(false);
    }
  };

  const handleClear = () => {
    setEmail(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      fullWidth
      fullScreen={screenSize === 'xs'}
    >
      <DialogTitle>Check email status</DialogTitle>

      <DialogContent dividers>
        <LookupEmailForm onSubmit={setEmail} hidden={Boolean(email)} />

        {email && <LookupResults {...email} onBack={handleClear} />}
      </DialogContent>
    </Dialog>
  );
}
