import accountEnrollmentIndexRoute from 'api/routes/account/account-enrollment-index';
import { useState, useEffect, useCallback } from 'react';

export default function useEnrollments() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    let isActive = true;
    accountEnrollmentIndexRoute()
      .then(({ status, data }) => {
        if (isActive && status === 200) {
          setData(data);
        }
      })
      .finally(() => {
        if (isActive) {
          setIsLoading(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [isLoading]);

  const reload = useCallback(() => {
    setIsLoading(true);
  }, []);

  return [data, isLoading, reload];
}
