import React from 'react';
import List from '@material-ui/core/List';
import BackIcon from '@material-ui/icons/ArrowBack';
import DrawerListItemLink from './drawer-list-item-link';

export default function OrphanedLink({ link, text, icon = BackIcon }) {
  return (
    <List component="div" disablePadding>
      <DrawerListItemLink url={link} icon={icon} text={text} />
    </List>
  );
}
