import React from 'react';
import { useParams } from 'react-router-dom';
import useIsAuthenticated from 'selectors/auth/use-is-authenticated';

import EnrollDialog from './enroll-dialog';

export default function EnrollPage() {
  const isAuthenticated = useIsAuthenticated();
  const { code } = useParams();

  return <EnrollDialog open code={code} isAuthenticated={isAuthenticated} />;
}
