import React from 'react';
import Moment from 'react-moment';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import UserAvatar from 'components/user/user-avatar';
import UserNames from 'components/user/user-names';

import FollowUpListItem from '../list-items/follow-up-list-item';
import RecommendedListItem from '../list-items/recommended-list-item';
import LastHelpedListItem from '../list-items/last-helped-list-item';
import HelpCountListItems from '../list-items/help-count-list-item';

export default function WaitingTicket({ ticket, accountId, onClick }) {
  const studentIds = ticket.students.map((s) => s.userId);
  const isStudent = ticket.students.some((s) => s.accountId === accountId);

  const handleClick = () => {
    onClick instanceof Function && onClick(ticket.id);
  };

  return (
    <Box clone mb={2}>
      <Card elevation={3}>
        <CardActionArea disabled={isStudent} onClick={handleClick}>
          <CardHeader
            titleTypographyProps={{ variant: 'body1' }}
            avatar={<UserAvatar ids={studentIds} />}
            title={<UserNames ids={studentIds} withGroup />}
            subheader="Waiting for help"
            action={
              <>
                <Typography variant="body1" align="right">
                  <Moment
                    date={ticket.requestedAt}
                    durationFromNow
                    interval={1000}
                  />
                </Typography>
                <Typography variant="body2" color="textSecondary" align="right">
                  waiting time
                </Typography>
              </>
            }
          />

          <List disablePadding dense>
            {ticket.isFollowup && (
              <FollowUpListItem parentId={ticket.parentId} />
            )}
            <RecommendedListItem ticket={ticket} />
            <LastHelpedListItem ticket={ticket} />
            <HelpCountListItems ticket={ticket} />
          </List>
        </CardActionArea>
      </Card>
    </Box>
  );
}
