import httpRequest from './drivers/http';
import socketRequest, { socket } from './drivers/socket';

export default function request(...args) {
  if (socket.isConnected()) {
    return socketRequest(...args);
  } else {
    return httpRequest(...args);
  }
}
