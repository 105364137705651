import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Calendar from './calendar';

export default function CalendarSection({ shifts, isLoading }) {
  return (
    <Box mt={6} style={{ maxWidth: '820px' }}>
      <Typography variant="h6" paragraph>
        Shift calendar
      </Typography>

      <Calendar shifts={shifts} isLoading={isLoading} />
    </Box>
  );
}
