import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import NavBar from 'components/app-bar/app-bar';
import ScheduleConfigSection from './schedule-config-section';
import ScheduleUrlSection from './schedule-url-section';

export default function CalendarPage() {
  const params = useParams();
  const courseId = Number(params.courseId);

  return (
    <>
      <NavBar
        title="Calendar settings"
        back={`/courses/${courseId}/settings`}
      />

      <Box p={2}>
        <Paper>
          <Box p={2}>
            <ScheduleConfigSection courseId={courseId} />
            <ScheduleUrlSection courseId={courseId} />
          </Box>
        </Paper>
      </Box>
    </>
  );
}
