import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import AppBar from 'components/app-bar/app-bar';

import CallingSection from './calling-section/calling-section';
import HelpingSection from './helping-section/helping-section';
import WaitingSection from './waiting-section/waiting-section';
import ShiftSection from './shift-section/shift-section';

export default function WaitlistPage({ role }) {
  const params = useParams();
  const courseId = Number(params.courseId);

  if (!['staff', 'instructor'].includes(role)) {
    return <Redirect to="/" />;
  }

  return (
    <AppBar title="Office hours waitlist">
      <Box p={2}>
        <ShiftSection courseId={courseId} />

        <CallingSection courseId={courseId} />

        <HelpingSection courseId={courseId} />

        <WaitingSection courseId={courseId} />
      </Box>
    </AppBar>
  );
}
