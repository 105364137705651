import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ActivityPage from './activity/activity-page';
import HandPage from './hand/hand-page';
import HistoryPage from './history/history-page';
import IndexPage from './menu/office-hours-index';
import WaitlistPage from './waitlist/waitlist-page';

export default function OfficeHoursRouter({ role }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/activity`}>
        <ActivityPage />
      </Route>
      <Route path={`${path}/hand`}>
        <HandPage />
      </Route>
      <Route path={`${path}/history`}>
        <HistoryPage />
      </Route>
      <Route path={`${path}/waitlist`}>
        <WaitlistPage role={role} />
      </Route>
      <Route>
        <IndexPage />
      </Route>
    </Switch>
  );
}
