/**
 * Material UI theme configuration
 *
 * See: https://material-ui.com/customization/theming/
 */

import { createMuiTheme } from '@material-ui/core/styles';

export const MainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#153f79',
    },
    secondary: {
      main: '#ca1313',
    },
    success: {
      main: '#4caf50',
    },
    info: {
      main: '#2196f3',
    },
    warning: {
      main: '#ff9800',
    },
    // background: {
    //   paper: '#ffffff',
    //   default: '#e6e8ea',
    // },
  },
});

export default MainTheme;

export const StudentTheme = createMuiTheme({
  palette: {
    primary: { main: '#AB3347' },
  },
});

export const StaffTheme = createMuiTheme({
  palette: {
    primary: { main: '#EDAA4E' },
  },
});

export const InstructorTheme = createMuiTheme({
  palette: {
    primary: { main: '#3893A6' },
  },
});

export const NavTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#a8cdff',
    },
    text: {
      primary: '#a8cdff',
      secondary: '#ecf4ff',
    },
    divider: '#4d72a5',
    background: {
      paper: '#122d52',
    },
  },
});
