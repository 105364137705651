import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import ExportIcon from '@material-ui/icons/GetApp';
import ExportButton from './button';

export default function SurveyExportSection({ courseId, tickets, isLoading }) {
  return (
    <Section title="Export survey data">
      <Typography paragraph>
        Your course survey data can be downloaded below in JSON file format.
      </Typography>

      <Typography paragraph>
        <ExportButton
          courseId={courseId}
          tickets={tickets}
          isLoading={isLoading}
          color="primary"
          variant="contained"
          startIcon={<ExportIcon />}
        >
          Export survey data
        </ExportButton>
      </Typography>
    </Section>
  );
}
