import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';

import SignupForm from 'pages/signup/signup-form';

export default function SignupContent({
  courseName,
  role,
  onTransition,
  email,
}) {
  const handleClick = useCallback(() => {
    onTransition instanceof Function && onTransition('signin');
  }, [onTransition]);

  const handleSubmit = useCallback(() => {
    onTransition instanceof Function && onTransition('email-sent');
  }, [onTransition]);

  const roleName = {
    student: 'a student',
    staff: 'a staff member',
    instructor: 'an instructor',
  }[role];

  return (
    <>
      <DialogTitle>Accept invitation</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          You were invited to enroll in {courseName} as {roleName}.
        </DialogContentText>

        <DialogContentText>
          Create a My Digital Hand account below to finish enrolling in the
          course.
        </DialogContentText>

        <DialogContentText>
          If you already have an account,{' '}
          <Link component="button" variant="body1" onClick={handleClick}>
            sign in instead
          </Link>{' '}
          to complete enrollment.
        </DialogContentText>

        <Box mb={1}>
          <SignupForm
            hideReset
            hideSignin
            onSignup={handleSubmit}
            email={email}
          />
        </Box>
      </DialogContent>
    </>
  );
}
