import React, { useMemo } from 'react';
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  Toolbar,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState } from '@devexpress/dx-react-scheduler';
import Typography from '@material-ui/core/Typography';
import makeData from './make-data';

export default function ShiftCalendar({ shifts, isLoading }) {
  const { data, startHour, endHour, latestDate } = useMemo(
    () => makeData(shifts),
    [shifts]
  );

  if (isLoading) {
    return (
      <Typography>
        <em>Loading data...</em>
      </Typography>
    );
  } else if (data.length === 0) {
    return (
      <Typography>
        <em>No data to display.</em>
      </Typography>
    );
  }

  return (
    <Scheduler data={data}>
      <ViewState defaultCurrentDate={latestDate} />
      <WeekView
        startDayHour={startHour}
        endDayHour={endHour}
        cellDuration={60}
      />
      <Toolbar />
      <DateNavigator />
      <TodayButton />
      <Appointments />
      <AppointmentTooltip showCloseButton={true} />
    </Scheduler>
  );
}
