import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import useUsers from 'selectors/users/use-users';

import UserAvatar from './user-avatar';
import capitalize from 'utils/capitalize';

export default function UserList({ userIds, hideBadge, numbered }) {
  const users = useUsers(userIds);

  return (
    <List>
      {userIds.map((id, i) => {
        const user = users[i];
        if (user) {
          return (
            <ListItem key={id}>
              {numbered && (
                <Typography variant="h6">{i + 1}.&nbsp;&nbsp;</Typography>
              )}
              <ListItemAvatar>
                <UserAvatar id={id} hideBadge={hideBadge} />
              </ListItemAvatar>
              <ListItemText
                primary={`${user.first} ${user.last}`}
                secondary={capitalize(user.role)}
              />
            </ListItem>
          );
        } else {
          return (
            <ListItem key={id}>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
            </ListItem>
          );
        }
      })}
    </List>
  );
}
