import React, { useCallback, useState } from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import NavDrawer from 'components/drawers/nav-drawer/nav-drawer';
import AppBar from 'components/app-bar/app-bar';
import ProfileTab from './profile-tab/tab';
import EmailsTab from './emails-tab/tab';
import CoursesTab from './courses-tab/tab';

export default function AccountPage() {
  const [tab, setTab] = useState('profile');

  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <NavDrawer orphanedLink="/" orphanedLinkText="Course list">
      <AppBar title="Account settings">
        <Box m={2}>
          <Paper>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Profile" value="profile" />
              <Tab label="Emails" value="emails" />
              <Tab label="Courses" value="courses" />
            </Tabs>

            <Box p={2}>
              {tab === 'profile' && <ProfileTab />}
              {tab === 'emails' && <EmailsTab />}
              {tab === 'courses' && <CoursesTab />}
            </Box>
          </Paper>
        </Box>
      </AppBar>
    </NavDrawer>
  );
}
