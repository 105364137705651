import React, { useCallback, forwardRef } from 'react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import CancelIcon from '@material-ui/icons/Close';

import cancelTicketRoute from 'api/routes/tickets/cancel-ticket';

export default forwardRef(CancelMenuItem);

function CancelMenuItem({ id, onClick, ...props }, ref) {
  const handleClick = useCallback(
    (event) => {
      onClick instanceof Function && onClick(event);
      return cancelTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <MenuItem onClick={handleClick} ref={ref} {...props}>
      <ListItemIcon>
        <CancelIcon color="error" />
      </ListItemIcon>
      <ListItemText>Cancel this request</ListItemText>
    </MenuItem>
  );
}
