import React, { useState, forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default forwardRef(MaskedTextField);

function MaskedTextField({ component: Component = TextField, ...props }, ref) {
  const [isMasked, setIsMasked] = useState(true);
  const Icon = isMasked ? VisibilityOff : Visibility;

  return (
    <Component
      {...props}
      ref={ref}
      type={isMasked ? 'password' : 'text'}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <>
            {
              <IconButton
                onClick={() => setIsMasked((isMasked) => !isMasked)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                <Icon />
              </IconButton>
            }
            {props.InputProps && props.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
}
