import React, { forwardRef } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import useScreenSize from 'hooks/use-screen-size';
import useQueryParams from 'hooks/use-query-params';

import ConfirmContent from './confirm-content';

export default forwardRef(ConfirmDialog);

function ConfirmDialog({ closable, onClose, ...params }, ref) {
  const { email, token } = useQueryParams();
  const screenSize = useScreenSize();
  const isFullScreen = screenSize === 'xs';

  const handleClose = () => {
    if (closable) {
      onClose instanceof Function && onClose();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      fullScreen={isFullScreen}
      ref={ref}
      {...params}
    >
      <DialogContent dividers>
        <ConfirmContent email={email} token={token} />
      </DialogContent>
    </Dialog>
  );
}
