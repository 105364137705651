import React from 'react';
import pluralize from 'pluralize';
import Section from 'components/content/section';
import TicketCard from 'components/tickets/ticket';
import useMyHelpingTickets from 'selectors/tickets/use-my-helping-tickets';

export default function ActiveSection({ courseId }) {
  const tickets = useMyHelpingTickets(courseId);

  if (!Array.isArray(tickets) || tickets.length === 0) {
    return null;
  }

  return (
    <Section
      title={`Currently helping ${pluralize('student', tickets.length, true)}`}
    >
      {tickets.map((ticket) => (
        <TicketCard key={ticket.id} ticket={ticket} />
      ))}
    </Section>
  );
}
