import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import ExportIcon from '@material-ui/icons/GetApp';
import ExportButton from './button';

export default function EventExportSection({ courseId }) {
  return (
    <Section title="Export event data">
      <Typography paragraph>
        Your course event data can be downloaded below in CSV file format.
      </Typography>

      <Typography paragraph>
        <ExportButton
          courseId={courseId}
          color="primary"
          variant="contained"
          startIcon={<ExportIcon />}
        >
          Export event data
        </ExportButton>
      </Typography>
    </Section>
  );
}
