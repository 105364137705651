import React, { useState, useEffect } from 'react';
import Badge from '@material-ui/core/Badge';
import GetHelpIcon from '@material-ui/icons/PanTool';
import useHand from 'hooks/use-hand';

export default function WaitlistBadgeIcon({ courseId, ...props }) {
  const { status } = useHand(courseId);
  const [lastStatus, setLastStatus] = useState(status);
  const hasBadge = ['waiting', 'calling', 'helping'].includes(status);

  useEffect(() => {
    if (lastStatus !== status) {
      if (status !== 'down') {
        new Audio('/sounds/bell.mp3').play();
      }
      setLastStatus(status);
    }
  }, [lastStatus, status]);

  return (
    <Badge color="secondary" badgeContent={hasBadge ? 1 : 0} {...props}>
      <GetHelpIcon />
    </Badge>
  );
}
