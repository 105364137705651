import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import SendIcon from '@material-ui/icons/Send';

import sendChatMessage from 'api/routes/chats/send-chat-message';

export default function Composer({ userId }) {
  const [drafts, setDrafts] = useState({});
  const [isSending, setIsSending] = useState(false);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setDrafts((drafts) => ({ ...drafts, [userId]: newValue }));
  };

  const handleSend = (event) => {
    event.preventDefault();
    setIsSending(true);
  };

  useEffect(() => {
    if (isSending) {
      let isActive = true;
      sendChatMessage({
        recipientId: userId,
        body: drafts[userId],
      })
        .then(() => {
          if (isActive) {
            setIsSending(false);
            setDrafts((drafts) => ({ ...drafts, [userId]: undefined }));
          }
        })
        .catch(() => {
          if (isActive) {
            setIsSending(false);
          }
        });
      return () => {
        isActive = false;
      };
    }
  }, [isSending, userId, drafts]);

  return (
    <form disabled={!drafts[userId] || isSending} onSubmit={handleSend}>
      <TextField
        fullWidth
        autoFocus
        label="Direct message"
        variant="outlined"
        value={drafts[userId] || ''}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                disabled={!drafts[userId] || isSending}
                color="primary"
              >
                {isSending ? <CircularProgress size="1em" /> : <SendIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}
