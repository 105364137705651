import surveyQuestions from '../../data-shapers/survey-questions';

export default function makeData(tickets) {
  return {
    studentPrompts: transform(surveyQuestions(tickets, 'studentPrompts')),
    studentFeedback: transform(surveyQuestions(tickets, 'studentFeedback')),
    staffFeedback: transform(surveyQuestions(tickets, 'staffFeedback')),
  };
}

function transform(questions) {
  return questions.map(({ question, responses }) => ({
    question,
    responses: responses.map(({ answer, ticket }) => {
      return {
        response: resolveAnswer(question, answer),
        ticket: {
          ticketId: ticket.ticketId,
          status: ticket.status,
          isFollowup: ticket.isFollowup,
          parentId: ticket.parentId,
          requestedAt: ticket.requestedAt,
          calledAt: ticket.calledAt,
          startedAt: ticket.startedAt,
          completedAt: ticket.completedAt,
          canceledAt: ticket.canceledAt,
          student: {
            firstName: ticket.studentFirstName,
            lastName: ticket.studentLastName,
            email: ticket.studentEmail,
            group: ticket.studentGroup,
            identifier: ticket.studentIdentifier,
            accountId: ticket.studentAccountId,
            userId: ticket.studentUserId,
            leftFeedback: ticket.studentLeftFeedback,
          },
          teacher: {
            firstName: ticket.teacherFirstName,
            lastName: ticket.teacherLastName,
            email: ticket.teacherEmail,
            group: ticket.teacherGroup,
            identifier: ticket.teacherIdentifier,
            accountId: ticket.teacherAccountId,
            userId: ticket.teacherUserId,
            leftFeedback: ticket.teacherLeftFeedback,
          },
        },
      };
    }),
  }));
}

function resolveAnswer(question, answer) {
  switch (question.type) {
    case 'short-answer':
    case 'free-response':
      return answer;
    case 'multiple-choice':
      return [...question.options, answer.otherValue][answer.selection] ?? null;
    case 'select-all':
      return answer.selections
        .map((i) => [...question.options, answer.otherValue][i] ?? null)
        .filter((x) => x !== null);
    default:
      return null;
  }
}
