import React from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

export default function FreeResponseField({
  value = '',
  isRequired = false,
  isDisabled = false,
  onChange,
}) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChange instanceof Function) {
      onChange(newValue, isValid(newValue));
    }
  };

  return (
    <>
      <TextField
        label="Answer"
        variant="outlined"
        fullWidth
        autoFocus
        multiline
        value={value}
        disabled={isDisabled}
        required={isRequired}
        onChange={handleChange}
      />
      {isRequired && (
        <FormHelperText>This question is required.</FormHelperText>
      )}
    </>
  );
}

function isValid(value) {
  return value && value.length > 2;
}
