import React, { useMemo } from 'react';
import ellipsis from 'text-ellipsis';
import pluralize from 'pluralize';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ResponsiveBar } from '@nivo/bar';

import makeData from './make-data';
import msToStr from '../../data-shapers/ms-to-str';
import asPercent from './as-percent';

export default function AnswerFrequencyChart({ question, responses }) {
  const data = useMemo(() => makeData({ question, responses }), [
    question,
    responses,
  ]);

  if (!Array.isArray(data) || data.length === 0) {
    return (
      <Typography>
        <em>No data to display.</em>
      </Typography>
    );
  }

  const height = Math.max(200, data.length * 40);

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveBar
        data={data}
        width={600}
        indexBy="text"
        keys={['percent']}
        colors={['#122d52']}
        layout="horizontal"
        labelTextColor="#ffffff"
        labelSkipWidth={30}
        margin={{ top: 0, right: 30, bottom: 60, left: 200 }}
        tooltip={Tooltip}
        labelFormat={asPercent}
        axisLeft={{ format: (x) => ellipsis(x, 30) }}
        axisBottom={{
          format: asPercent,
          legend: 'Percent of interactions with feedback data',
          legendOffset: 35,
          legendPosition: 'middle',
        }}
      />
    </div>
  );
}

function Tooltip({ data }) {
  const { percent, count, text, avgHelpingMs, avgWaitingMs } = data;
  return (
    <Box py={0.5} px={0.5} style={{ maxWidth: '400px' }}>
      <Typography variant="body1">&quot;{text}&quot;</Typography>
      <Box pl={2}>
        <Typography variant="body2">
          {pluralize('request', count, true)} ({asPercent(percent)} of
          responses)
        </Typography>
        <Typography variant="body2">
          {msToStr(avgHelpingMs)} average interaction time
        </Typography>
        <Typography variant="body2">
          {msToStr(avgWaitingMs)} average waiting time
        </Typography>
      </Box>
    </Box>
  );
}
