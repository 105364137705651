/**
 * Selects a list of users by id from the store, fetching them from the server
 *   if necessary
 */

import { useSelector, useDispatch } from 'react-redux';
import fetchUser from 'actions/users/fetch';
import getUsers from './get-users';

export default function useUsers(userIds) {
  // Select data from store
  const users = useSelector((store) => {
    if (Array.isArray(userIds)) {
      return getUsers(store, userIds);
    } else {
      return [];
    }
  });

  // Fetch missing records from server
  const dispatch = useDispatch();
  userIds.forEach((id, i) => {
    if (Number.isInteger(id) && id > 0 && !users[i]) {
      dispatch(fetchUser(id));
    }
  });

  // All done!
  return users;
}
