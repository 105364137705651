import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import UpArrowIcon from '@material-ui/icons/ArrowUpward';
import DownArrowIcon from '@material-ui/icons/ArrowDownward';
import ShowMenuIcon from '@material-ui/icons/SwapVert';

export default function ReorderQuestionMenu({
  currentQuestionIndex,
  numQuestions,
  onMoveUp,
  onMoveDown,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const showMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  };

  const handleUpClick = (event) => {
    onMoveUp();
    event.target.scrollIntoView();
    hideMenu();
  };

  const handleDownClick = (event) => {
    onMoveDown();
    event.target.scrollIntoView();
    hideMenu();
  };

  if (numQuestions < 2) {
    return null;
  }

  return (
    <>
      <Tooltip title="Reorder">
        <IconButton onClick={showMenu}>
          <ShowMenuIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={hideMenu}
      >
        {currentQuestionIndex > 0 && (
          <MenuItem onClick={handleUpClick}>
            <ListItemIcon>
              <UpArrowIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Move question up" />
          </MenuItem>
        )}
        {currentQuestionIndex < numQuestions && (
          <MenuItem onClick={handleDownClick}>
            <ListItemIcon>
              <DownArrowIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Move question down" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
