/**
 * Fetches all non-canceled tickets for a particular course, paginated and
 * sorted by recent first
 */

import request from 'api/request';

export default function courseTicketIndexRoute(courseId, page = 0) {
  return request({
    url: '/tickets/activity',
    method: 'GET',
    data: { courseId, page },
  });
}
