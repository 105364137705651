/**
 * Fetches a single course by id from the server; debounces rapid requests for
 * the same course
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import { courseFetching } from './actions';
import loadRecords from 'actions/load-records';
import readCourseRoute from 'api/routes/courses/read-course';

const promises = {};

export default function fetchCourse(courseId) {
  return (dispatch) => {
    if (!Number.isInteger(courseId) || courseId <= 0) {
      return;
    } else if (courseId in promises) {
      return promises[courseId];
    }
    dispatch(courseFetching(courseId));
    const promise = readCourseRoute(courseId)
      .then(({ data }) => {
        dispatch(loadRecords(data));
      })
      .finally(() => {
        delete promises[courseId];
      });
    promises[courseId] = promise;
    return promise;
  };
}
