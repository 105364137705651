import React from 'react';
import { useFieldArray } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';

import QuestionOption from './question-option';

export default function OptionsControlList({
  name,
  control,
  type,
  allowCustomResponse,
}) {
  const { setValue, register } = control;
  const { fields, append, remove } = useFieldArray({ control, name });

  const handleAdd = () => {
    append('');
  };

  const handleRemove = (i) => () => {
    remove(i);
  };

  const handleAllowCustomResponseToggle = () => {
    setValue('allowCustomResponse', !allowCustomResponse);
  };

  return (
    <Collapse in={['multiple-choice', 'select-all'].includes(type)}>
      {fields.map((field, i) => (
        <QuestionOption
          key={field.id}
          defaultValue={field.value}
          name={`${name}[${i}]`}
          inputRef={register()}
          type={type}
          fullWidth
          autoFocus
          onDelete={handleRemove(i)}
          canDelete={fields.length > 1}
        />
      ))}

      <Collapse in={allowCustomResponse}>
        <QuestionOption
          value="Students may add one custom answer to the list"
          type={type}
          disabled
          fullWidth
          onDelete={handleAllowCustomResponseToggle}
        />
      </Collapse>

      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <QuestionOption
            value="Add option"
            type={type}
            disabled
            canDelete={false}
            onClick={handleAdd}
          />
        </Grid>

        {!allowCustomResponse && (
          <>
            <Grid item>or</Grid>
            <Grid item>
              <Button
                style={{ textTransform: 'none' }}
                onClick={handleAllowCustomResponseToggle}
              >
                add &quot;other&quot; option
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Collapse>
  );
}
