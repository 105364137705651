import React, { memo, useCallback } from 'react';
import MaterialTable from 'material-table';
import { ExportToCsv } from 'export-to-csv';

import EditIcon from '@material-ui/icons/Edit';

import Icons from 'components/tables/table-icons';
import useCourse from 'selectors/courses/use-course';

export default memo(RosterTable);

function RosterTable({
  courseId,
  data,
  onClickAddUser,
  onClickEditUser,
  ...props
}) {
  const course = useCourse(courseId);

  const handleClickAddUser = useCallback(() => {
    if (onClickAddUser instanceof Function) {
      onClickAddUser();
    }
  }, [onClickAddUser]);

  const handleClickEditUser = useCallback(
    (event, rowData) => {
      if (onClickEditUser instanceof Function) {
        onClickEditUser(rowData);
      }
    },
    [onClickEditUser]
  );

  const handleExport = useCallback(() => {
    const csvExporter = new ExportToCsv({
      filename: exportRosterFileName(course),
      useKeysAsHeaders: true,
    });
    csvExporter.generateCsv(
      data.map((row) => ({
        first: row.first ?? '',
        last: row.last ?? '',
        email: row.email ?? '',
        status:
          {
            pending: 'Pending email confirmation',
            active: 'Active',
            invited: 'Invited',
          }[row.status] ?? '',
      }))
    );
  }, [course, data]);

  if (!course) {
    return null;
  }

  return (
    <>
      <MaterialTable
        data={data}
        icons={Icons}
        components={{ Container: ({ children }) => children }}
        localization={{ header: { actions: '' } }}
        columns={[
          {
            title: 'Name',
            defaultSort: 'asc',
            customSort: byLastThenFirstThenEmail,
            render: ({ first, last }) =>
              first || last ? `${first} ${last}` : '',
          },
          {
            title: 'Email',
            field: 'email',
            searchable: true,
          },
          {
            title: 'User ID',
            field: 'identifier',
            searchable: true,
          },
          {
            title: 'Group',
            field: 'group',
            searchable: true,
          },
          {
            hidden: true,
            field: 'first',
            searchable: true,
          },
          {
            hidden: true,
            field: 'last',
            searchable: true,
          },
        ]}
        options={{
          paging: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          toolbarButtonAlignment: 'right',
          thirdSortClick: false,
        }}
        actions={[
          {
            isFreeAction: true,
            tooltip: 'Export to CSV',
            icon: Icons.Export,
            onClick: handleExport,
          },
          {
            isFreeAction: true,
            tooltip: 'Add users',
            icon: Icons.Add,
            onClick: handleClickAddUser,
          },
          {
            tooltip: 'Edit entry',
            icon: () => <EditIcon color="action" />,
            onClick: handleClickEditUser,
          },
        ]}
        {...props}
      />
    </>
  );
}

function byLastThenFirstThenEmail(a, b) {
  const strA = `${a.last ?? ''}${a.first ?? ''}${a.email}`.toLowerCase();
  const strB = `${b.last ?? ''}${b.first ?? ''}${b.email}`.toLowerCase();
  return strA.localeCompare(strB);
}

function exportRosterFileName(course) {
  return [course.code, course.semester, course.year, 'roster']
    .filter((x) => x)
    .join('-')
    .replace(' ', '');
}
