/**
 * Fetches a single user by id from the server; debounces rapid requests for the
 * same user
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import { userFetching } from './actions';
import loadRecords from 'actions/load-records';
import readUserRoute from 'api/routes/users/read-user';

const promises = {};

export default function fetchUser(id) {
  return (dispatch) => {
    if (!Number.isInteger(id) || id <= 0) {
      return;
    } else if (id in promises) {
      return promises[id];
    }
    dispatch(userFetching(id));
    const promise = readUserRoute(id)
      .then(({ data }) => {
        dispatch(loadRecords(data));
      })
      .finally(() => {
        delete promises[id];
      });
    promises[id] = promise;
    return promise;
  };
}
