import React, { useState, useCallback } from 'react';

import Button from '@material-ui/core/Button';

import useCourse from 'selectors/courses/use-course';

import PostInteractionDialog from 'components/dialogs/quizzes/post-interaction-dialog';

export default function FeedbackLink({ ticket, ...params }) {
  const course = useCourse(ticket?.courseId);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!ticket || !course) {
    return null;
  }

  return (
    <>
      <Button
        {...params}
        component="button"
        variant="contained"
        color="primary"
        onClick={handleOpen}
      />

      <PostInteractionDialog
        ticketId={ticket.id}
        questions={course.studentFeedback}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
