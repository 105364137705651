import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LoadingButton from 'components/buttons/loading-button';

export default function LoadingContent() {
  return (
    <>
      <DialogTitle>View invitation</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Please wait while this invitation loads.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <LoadingButton color="primary" variant="contained" loading>
          Loading invitation
        </LoadingButton>
      </DialogActions>
    </>
  );
}
