import React, { useState, useCallback } from 'react';
import Papa from 'papaparse';
import { makeStyles } from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import emailRegex from 'utils/email-regex';

const useStyles = makeStyles((theme) => ({
  primaryColor: {
    color: theme.palette.primary.main,
  },

  secondaryColor: {
    color: theme.palette.text.secondary,
  },
}));

export default function RosterCsvImport({ role, onImport }) {
  const [error, setError] = useState(null);
  const classes = useStyles();

  const handleAdd = useCallback(
    ([{ data, file }]) => {
      setError(null);
      parseCsv(file, { header: true, worker: true })
        .then(({ data, errors }) => {
          const users = data.filter((r) => emailRegex.test(r.email));
          if (users.length === 0) {
            setError(
              `Error: ${file.name} contains no valid users. Make sure it is a valid csv file that follows the format requirements listed above.`
            );
          } else if (onImport instanceof Function) {
            onImport(users);
          }
        })
        .catch(() => {
          setError(
            `Error: ${file.name} could not be parsed. Try re-exporting it and check that it is readable.`
          );
        });
    },
    [onImport]
  );

  const handleClick = useCallback(() => {
    if (onImport instanceof Function) {
      onImport([{ email: '', first: '', last: '', identifier: '', group: '' }]);
    }
  }, [onImport]);

  return (
    <>
      <DialogContent>
        <DialogContentText variant="body2" color="textPrimary">
          Invite users to join your course by uploading a roster csv file below.
          An invitation email will be sent to each user on the roster,
          authorizing the recipient to join your course as a{' '}
          {role === 'staff' ? 'staff member' : role}.
        </DialogContentText>

        <DialogContentText variant="body2" color="textPrimary">
          The first row of the uploaded csv file must contain column names. All
          subsequent rows represent users. The following column names are
          supported; all others will be ignored. Column names are
          case-sensitive.
        </DialogContentText>

        <DialogContentText component="div" variant="body2" color="textPrimary">
          <ul>
            <li>
              <strong>email</strong> &ndash; Required. The user&apos;s email
              address.
            </li>
            <li>
              <strong>first</strong> &ndash; Optional. The user&apos;s first
              name.
            </li>
            <li>
              <strong>last</strong> &ndash; Optional. The user&apos;s last name.
            </li>
            <li>
              <strong>identifier</strong> &ndash; Optional. A unique identifier
              for the user (eg. student id or username).
            </li>
            <li>
              <strong>group</strong> &ndash; Optional. A subgroup to which the
              user belongs. This will be shown alongside the user&apos;s name
              throughout the app (eg. course section).
            </li>
          </ul>
        </DialogContentText>

        <Collapse in={Boolean(error)}>
          <Typography paragraph variant="subtitle2" color="error">
            {error}
          </Typography>
        </Collapse>

        <DropzoneAreaBase
          showAlerts={false}
          showPreviewsInDropzone={false}
          filesLimit={1}
          dropzoneText="Drag and drop a roster csv file here, or click to browse your device"
          acceptedFiles={['.csv']}
          onAdd={handleAdd}
          classes={{ text: classes.secondaryColor, icon: classes.primaryColor }}
        />

        <Box mt={3} mb={1}>
          <Typography align="right">
            <Button variant="outlined" color="primary" onClick={handleClick}>
              or, invite by hand
            </Button>
          </Typography>
        </Box>
      </DialogContent>
    </>
  );
}

function parseCsv(file, params) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, { ...params, complete: resolve, error: reject });
  });
}
