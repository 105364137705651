import React, { useState, useEffect, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from 'components/content/dialog';
import destroyUsersRoute from 'api/routes/users/destroy-users';

export default function DeleteUserDialog({
  userId,
  courseId,
  userName = 'this user',
  onClose,
  onCancel,
  onComplete,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    let isActive = true;
    destroyUsersRoute(courseId, [userId])
      .then(({ status }) => {
        if (isActive && status === 200 && onComplete instanceof Function) {
          onComplete();
        }
      })
      .finally(() => {
        if (isActive) {
          setIsLoading(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [userId, onComplete, courseId, isLoading]);

  return (
    <Dialog {...props}>
      <DialogTitle disableTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">Remove user</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography paragraph>
          Are you sure you want to remove {userName} from your course?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          No, keep
        </Button>

        <Button
          onClick={handleDelete}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
        >
          Yes, delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
