import React, { useState, useEffect } from 'react';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';

import useCourse from 'selectors/courses/use-course';
import fetchEvents from 'api/routes/statistics/fetch-events';
import LoadingButton from 'components/buttons/loading-button';

export default function ExportEventsButton({ courseId, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const course = useCourse(courseId);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    let isActive = true;
    fetchEvents(course.id)
      .then(({ data, status }) => {
        if (!isActive || status !== 200) {
          return;
        }
        const events = data.map((row) => ({
          type: row.type,
          createdAt: row.createdAt,
          payload: JSON.stringify(row.payload),
        }));
        const csvExporter = new ExportToCsv({
          filename: exportEventsFilename(course),
          useKeysAsHeaders: true,
        });
        csvExporter.generateCsv(events.length > 0 ? events : [{}]);
      })
      .finally(() => {
        if (isActive) {
          setIsLoading(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [isLoading, course]);

  const handleClick = () => {
    setIsLoading(true);
  };

  return <LoadingButton {...props} loading={isLoading} onClick={handleClick} />;
}

function exportEventsFilename(course) {
  const date = moment().format('YYYY-MM-DD');
  const time = moment().format('HH-mm');
  return [course.code, course.semester, course.year, 'events', date, time]
    .filter((x) => x)
    .join('-')
    .replace(' ', '-');
}
