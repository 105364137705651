import React from 'react';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import EnrollmentIcon from '@material-ui/icons/PersonAdd';
import FeedbackIcon from '@material-ui/icons/RateReview';
import GeneralIcon from '@material-ui/icons/TextFields';
import OfficeHoursIcon from '@material-ui/icons/LiveHelp';
import RosterIcon from '@material-ui/icons/PeopleAlt';
import CalendarIcon from '@material-ui/icons/DateRange';

export default function SettingsNavList({ courseId }) {
  return (
    <List>
      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/settings/attributes`}
      >
        <ListItemAvatar>
          <Avatar>
            <GeneralIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Attributes"
          secondary="Change basic course attributes, like title or semester, and archive or delete your course"
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/settings/enrollment`}
      >
        <ListItemAvatar>
          <Avatar>
            <EnrollmentIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Self-enrollment"
          secondary="Control how new users are allowed to self-enroll in your course"
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/settings/roster`}
      >
        <ListItemAvatar>
          <Avatar>
            <RosterIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Roster"
          secondary="Manage who is currently enrolled in your course and invite new users by email"
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/settings/behavior`}
      >
        <ListItemAvatar>
          <Avatar>
            <OfficeHoursIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Behavior"
          secondary="Encourage good office hours etiquette with tools like interaction timers"
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/settings/feedback`}
      >
        <ListItemAvatar>
          <Avatar>
            <FeedbackIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Feedback"
          secondary="Set questions to ask students and staff before and after each office hours experience"
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/settings/calendar`}
      >
        <ListItemAvatar>
          <Avatar>
            <CalendarIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Schedule"
          secondary="Post an office hours schedule, so students and staff know what to expect"
        />
      </ListItem>
    </List>
  );
}
