import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';

import SettingsRouter from './settings/settings-router';
import OfficeHoursRouter from './office-hours/office-hours-router';
import ChatRouter from './chat/chat-router';
import CalendarPage from './calendar/calendar-page';
import LecturesPage from './lectures/lectures-page';
import HomePage from './home/home-page';
import DiscussionRouter from './discussion/discussion-router';
import StatisticsRouter from './statistics/statistics-router';

import NavDrawer from 'components/drawers/nav-drawer/nav-drawer';
import Loading from 'components/loading/loading';
import useCourse from 'selectors/courses/use-course';
import fetchStaff from 'actions/users/fetch-staff';
import useCourseRole from 'selectors/courses/use-course-role';

export default function CoursesRouter() {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const params = useParams();
  const courseId = Number(params.courseId);
  const course = useCourse(courseId);
  const courseRole = useCourseRole(courseId);

  // Fetch staff
  useEffect(() => {
    dispatch(fetchStaff(courseId));
  }, [dispatch, courseId]);

  // Check course and role
  if (!course) {
    return <Loading message={`Loading course with id ${courseId}...`} />;
  } else if (!['student', 'staff', 'instructor'].includes(courseRole)) {
    return <Redirect to="/" />;
  }

  // Render
  return (
    <NavDrawer>
      <Switch>
        <Route path={`${path}/settings`}>
          <SettingsRouter role={courseRole} />
        </Route>
        <Route path={`${path}/office-hours`}>
          <OfficeHoursRouter role={courseRole} />
        </Route>
        <Route path={`${path}/chat`}>
          <ChatRouter />
        </Route>
        <Route path={`${path}/calendar`}>
          <CalendarPage />
        </Route>
        <Route path={`${path}/lectures`}>
          <LecturesPage />
        </Route>
        <Route path={`${path}/discussion`}>
          <DiscussionRouter />
        </Route>
        <Route path={`${path}/statistics`}>
          <StatisticsRouter />
        </Route>
        <Route path={`${path}`}>
          <HomePage />
        </Route>
      </Switch>
    </NavDrawer>
  );
}
