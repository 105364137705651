import React, { forwardRef } from 'react';

import MuiLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

export default forwardRef(Link);

function Link(params, ref) {
  return <MuiLink component={RouterLink} {...params} ref={ref} />;
}
