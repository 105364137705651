/**
 * Selects a list of courses by id from the store, fetching them from the server
 *   if necessary
 */

import { useSelector, useDispatch } from 'react-redux';
import fetchUser from 'actions/users/fetch';
import getCourses from './get-courses';

export default function useCourses(courseIds) {
  // Select data from store
  const users = useSelector((store) => {
    if (Array.isArray(courseIds)) {
      return getCourses(store, courseIds);
    } else {
      return [];
    }
  });

  // Fetch missing records from server
  const dispatch = useDispatch();
  courseIds.forEach((id, i) => {
    if (Number.isInteger(id) && id > 0 && !users[i]) {
      dispatch(fetchUser(id));
    }
  });

  // All done!
  return users;
}
