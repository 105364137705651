export default function makeData({ question, responses }) {
  switch (question.type) {
    case 'select-all':
      return makeSelectAllData({ question, responses });
    case 'multiple-choice':
      return makeMultipleChoiceData({ question, responses });
    default:
      return null;
  }
}

function makeSelectAllData({ question, responses }) {
  const results = [];

  question.options.forEach((option, i) => {
    const selectedResponses = responses.filter(({ answer }) =>
      answer.selections.includes(i)
    );
    results.push(
      makeResponse({
        responses: selectedResponses,
        text: option,
        totalCount: responses.length,
      })
    );
  });

  if (question.allowCustomResponse) {
    const selectedResponses = responses.filter(({ answer }) =>
      answer.selections.includes(question.options.length)
    );
    results.push(
      makeResponse({
        responses: selectedResponses,
        text: 'Other (custom response)',
        totalCount: responses.length,
      })
    );
  }

  return results;
}

function makeMultipleChoiceData({ question, responses }) {
  const results = [];

  question.options.forEach((option, i) => {
    const selectedResponses = responses.filter(
      ({ answer }) => answer.selection === i
    );
    results.push(
      makeResponse({
        responses: selectedResponses,
        text: option,
        totalCount: responses.length,
      })
    );
  });

  if (question.allowCustomResponse) {
    const selectedResponses = responses.filter(
      ({ answer }) => answer.selection === question.options.length
    );
    results.push(
      makeResponse({
        responses: selectedResponses,
        text: 'Other (custom response)',
        totalCount: responses.length,
      })
    );
  }

  return results;
}

function makeResponse({ responses, text, totalCount }) {
  const tickets = responses.map((r) => r.ticket);
  return {
    text,
    count: responses.length,
    percent: responses.length / totalCount,
    avgHelpingMs: calcAvgHelpingMs(tickets),
    avgWaitingMs: calcAvgWaitingMs(tickets),
  };
}

function calcAvgHelpingMs(
  tickets,
  isFilterEnabled = true,
  filterMsThreshold = 45 * 60 * 1000 // 45 minutes
) {
  const helpingMsArray = tickets
    .filter((t) => t.status === 'completed')
    .map(
      (t) => new Date(t.completedAt).getTime() - new Date(t.startedAt).getTime()
    )
    .filter((ms) => !isFilterEnabled || ms < filterMsThreshold);
  if (helpingMsArray.length > 0) {
    const totalHelpingMs = helpingMsArray.reduce((tot, ms) => tot + ms, 0);
    return totalHelpingMs / helpingMsArray.length;
  } else {
    return String(NaN);
  }
}

function calcAvgWaitingMs(tickets) {
  const activeTickets = tickets.filter((t) =>
    ['helping', 'completed'].includes(t.status)
  );
  if (activeTickets.length > 0) {
    const totalWaitingMs = activeTickets.reduce(
      (x, t) =>
        x + new Date(t.startedAt).getTime() - new Date(t.requestedAt).getTime(),
      0
    );
    return totalWaitingMs / activeTickets.length;
  } else {
    return String(NaN);
  }
}
