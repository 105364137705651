import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DoneIcon from '@material-ui/icons/Done';

export default function SuccessContent({ courseId, courseName, role }) {
  const roleName = {
    student: 'a student',
    staff: 'a staff member',
    instructor: 'an instructor',
  }[role];

  return (
    <>
      <DialogTitle>Enrolled in course</DialogTitle>

      <DialogContent>
        <DialogContentText>
          You were successfully enrolled in {courseName} as {roleName}.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<DoneIcon />}
          color="primary"
          variant="contained"
          component={Link}
          to={`/courses/${courseId}`}
        >
          Go to course
        </Button>
      </DialogActions>
    </>
  );
}
