import request from 'api/request';

export default function fetchRoster(courseId) {
  return request({
    url: `/courses/${courseId}/users`,
  }).then(({ data, headers, status }) => ({
    data: data.users,
    headers,
    status,
  }));
}
