/**
 * Fetches a page of chat messages for a specific chat thread
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import readThreadRoute from 'api/routes/chats/read-thread';
import loadRecords from 'actions/load-records';

export default function loadThread(userId, pageNum = 0, pageSize = 25) {
  return (dispatch) =>
    readThreadRoute(userId, pageNum, pageSize).then(({ data }) => {
      dispatch(loadRecords(data));
    });
}
