import React from 'react';
import WaitingTicketCard from './waiting/waiting-ticket';
import CallingTicketCard from './calling/calling-ticket';
import HelpingTicketCard from './helping/helping-ticket';
import CompletedTicketCard from './completed/completed-ticket';
import TicketPlaceholder from './ticket-placeholder';
import useAccount from 'selectors/auth/use-account';

export default function Ticket({ ticket, onClick }) {
  const account = useAccount();

  if (!account || !ticket) {
    return <TicketPlaceholder />;
  }

  switch (ticket.status) {
    case 'waiting':
      return (
        <WaitingTicketCard
          ticket={ticket}
          accountId={account.id}
          onClick={onClick}
        />
      );
    case 'calling':
      return (
        <CallingTicketCard
          ticket={ticket}
          accountId={account.id}
          onClick={onClick}
        />
      );
    case 'helping':
      return <HelpingTicketCard ticket={ticket} accountId={account.id} />;
    case 'completed':
      return <CompletedTicketCard ticket={ticket} />;
    default:
      return null;
  }
}
