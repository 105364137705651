import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CreateIcon from '@material-ui/icons/Add';

import createCourse from 'api/routes/courses/create-course';
import useSchools from 'selectors/schools/use-schools';
import LoadingButton from 'components/buttons/loading-button';

const semesters = ['Fall', 'Spring', 'Summer I', 'Summer II', 'Maymester'];

export default function CourseCreateForm() {
  const schools = useSchools();
  const [course, setCourse] = useState(null);
  const { control, register, handleSubmit, formState, errors } = useForm();
  const { dirty, isSubmitting } = formState;

  const onSubmit = (data) => {
    return createCourse(data).then(({ data }) => {
      setCourse(data);
    });
  };

  if (course) {
    return <Redirect to={`/courses/${course.id}`} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justify="flex-end">
        <Grid item xs={12}>
          <Controller
            as={Autocomplete}
            name="school"
            control={control}
            freeSolo
            options={schools}
            defaultValue=""
            onChange={([, x]) => x}
            onChangeName="onInputChange"
            rules={{ required: true }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="School or university"
                margin="normal"
                variant="outlined"
                fullWidth
                error={Boolean(errors.school)}
                disabled={isSubmitting}
                helperText={Boolean(errors.school) ? 'Required' : undefined}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Subject and course number"
            variant="outlined"
            margin="normal"
            fullWidth
            name="code"
            error={Boolean(errors.code)}
            helperText={Boolean(errors.code) ? 'Required' : 'eg. "COMP 401"'}
            disabled={isSubmitting}
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Official title"
            variant="outlined"
            margin="normal"
            fullWidth
            name="title"
            error={Boolean(errors.title)}
            disabled={isSubmitting}
            helperText={
              Boolean(errors.title)
                ? 'Required'
                : 'eg. "Foundations of Programming"'
            }
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            as={Autocomplete}
            name="semester"
            control={control}
            freeSolo
            options={semesters}
            defaultValue=""
            onChange={([, x]) => x}
            onChangeName="onInputChange"
            rules={{ required: true }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Semester"
                margin="normal"
                variant="outlined"
                fullWidth
                error={Boolean(errors.semester)}
                disabled={isSubmitting}
                helperText={Boolean(errors.semester) ? 'Required' : undefined}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Year"
            variant="outlined"
            margin="normal"
            fullWidth
            type="number"
            name="year"
            error={Boolean(errors.year)}
            disabled={isSubmitting}
            defaultValue={new Date().getFullYear()}
            helperText={Boolean(errors.year) ? 'Required' : undefined}
            inputRef={register({ required: true, min: 2000 })}
          />
        </Grid>

        <Grid item>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isSubmitting}
            disabled={!dirty}
            type="submit"
            startIcon={<CreateIcon />}
          >
            Create course
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
