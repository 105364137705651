import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import useScreenSize from 'hooks/use-screen-size';
import SigninForm from './signin-form';

export default function SigninDialog(params) {
  const screenSize = useScreenSize();

  return (
    <Dialog fullWidth fullScreen={screenSize === 'xs'} {...params}>
      <DialogTitle>Log in to My Digital Hand</DialogTitle>

      <DialogContent dividers>
        <SigninForm />
      </DialogContent>
    </Dialog>
  );
}
