import { Model, attr } from 'redux-orm';

export default class Usage extends Model {
  static reducer(action, Usage, session) {
    switch (action.type) {
      case 'USAGE_FETCHED':
        Usage.upsert(action.payload);
        break;
      case 'USAGE_CHANGED':
        if (Usage.idExists(action.payload)) {
          Usage.withId(action.payload).delete(); // Record must be reloaded
        }
        break;
      case 'AUTH_INVALIDATED':
        Usage.all()
          .toModelArray()
          .forEach((u) => u.delete());
        break;
      default:
    }
  }
}

Usage.modelName = 'Usage';

Usage.fields = {
  // Primary key
  userId: attr(),

  // Ticket counts
  completedCount: attr(),
  canceledCount: attr(),

  // Id of latest completed ticket
  latestCompletedTicketId: attr(),
};

Usage.options = {
  idAttribute: 'userId',
  // mapName: 'itemsById',
  // arrName: 'items',
};
