import socketEventsRoute from 'api/routes/subscriptions/socket-events';
import loadActiveTickets from 'actions/tickets/fetch-active';
import loadMissingTickets from 'actions/tickets/fetch-missing';
import loadLastCompletedTickets from 'actions/tickets/fetch-last-completed';
import loadThreadIndex from 'actions/chats/load-thread-index';
import loadAccountUsers from 'actions/users/load-account-users';

export default function syncSubscriptions() {
  return (dispatch) => {
    return Promise.all([
      socketEventsRoute(),
      dispatch(loadActiveTickets()),
      dispatch(loadMissingTickets()),
      dispatch(loadLastCompletedTickets()),
      dispatch(loadThreadIndex()),
      dispatch(loadAccountUsers()),
    ]);
  };
}
