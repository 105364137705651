import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
  },
}));

export default function DrawerListItemLink({
  url,
  icon: Icon,
  text,
  isVisible = true,
  IconProps,
  ...props
}) {
  const classes = useStyles();

  if (isVisible) {
    return (
      <ListItem button component={Link} to={url} {...props}>
        <ListItemIcon>
          <Icon className={classes.icon} {...IconProps} />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  } else {
    return null;
  }
}
