import React from 'react';
import ShiftExportSection from './shift-data/section';
import EventExportSection from './event-data/section';
import SurveyExportSection from './survey-data/section';
import InteractionExportSection from './interaction-data/section';

export default function ExportTab({ courseId, tickets, shifts, isLoading }) {
  return (
    <>
      <ShiftExportSection
        courseId={courseId}
        shifts={shifts}
        isLoading={isLoading}
      />
      <InteractionExportSection
        courseId={courseId}
        tickets={tickets}
        isLoading={isLoading}
      />
      <EventExportSection courseId={courseId} isLoading={isLoading} />
      <SurveyExportSection
        courseId={courseId}
        tickets={tickets}
        isLoading={isLoading}
      />
    </>
  );
}
