import getCourses from './get-courses';
import { useSelector, useDispatch } from 'react-redux';
import fetchCourse from 'actions/courses/fetch';

export default function useCourse(id) {
  const dispatch = useDispatch();
  const course = useSelector((store) => {
    if (Number.isInteger(id) && id > 0) {
      return getCourses(store, id);
    } else {
      return null;
    }
  });
  if (Number.isInteger(id) && id > 0 && !course) {
    dispatch(fetchCourse(id));
  }
  return course;
}
