/**
 * Fetches all users in which the authenticated user is enrolled
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import accountUserIndexRoute from 'api/routes/users/account-user-index';
import loadRecords from 'actions/load-records';

export default function loadAccountUsers() {
  return (dispatch) => {
    return accountUserIndexRoute().then(({ data, status }) => {
      if (status === 200) {
        dispatch(loadRecords(data));
      }
    });
  };
}
