import React, { useState, useEffect, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SubmitIcon from '@material-ui/icons/Done';
import BackIcon from '@material-ui/icons/ArrowBack';
import LoadingButton from 'components/buttons/loading-button';
import updateAccountRoute from 'api/routes/account/update-account';

export default function EditName({ first, last, onClick }) {
  const [isSaving, setIsSaving] = useState(false);
  const [localFirst, setFirst] = useState('');
  const [localLast, setLast] = useState('');

  useEffect(() => {
    setFirst(first);
    setLast(last);
  }, [first, last]);

  useEffect(() => {
    if (!isSaving) {
      return;
    }
    let isActive = true;
    updateAccountRoute({ first: localFirst, last: localLast })
      .then(({ status }) => {
        if (isActive && status === 200 && onClick instanceof Function) {
          onClick();
        }
      })
      .finally(() => {
        if (isActive) {
          setIsSaving(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [onClick, isSaving, localFirst, localLast]);

  const handleSubmit = useCallback((event) => {
    setIsSaving(true);
    event.preventDefault();
  }, []);

  const handleChangeFirst = useCallback((event) => {
    setFirst(event.target.value);
  }, []);

  const handleChangeLast = useCallback((event) => {
    setLast(event.target.value);
  }, []);

  return (
    <form disabled={isSaving} onSubmit={handleSubmit}>
      <Box my={2}>
        <TextField
          name="First name"
          variant="outlined"
          fullWidth
          size="small"
          helperText="Your first or given name"
          disabled={isSaving}
          value={localFirst}
          onChange={handleChangeFirst}
        />
      </Box>

      <Box my={2}>
        <TextField
          name="Last name"
          variant="outlined"
          fullWidth
          size="small"
          helperText="Your last or family name"
          disabled={isSaving}
          value={localLast}
          onChange={handleChangeLast}
        />
      </Box>

      <Grid container justify="flex-end" spacing={2}>
        <Grid item>
          <Button
            color="primary"
            type="submit"
            disabled={isSaving}
            onClick={onClick}
            startIcon={<BackIcon />}
          >
            Back
          </Button>
        </Grid>

        <Grid item>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isSaving}
            startIcon={<SubmitIcon />}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
