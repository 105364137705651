import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';

import EmailIcon from '@material-ui/icons/AlternateEmail';
import PasswordIcon from '@material-ui/icons/Fingerprint';
import Done from '@material-ui/icons/Done';
import NameIcon from '@material-ui/icons/Person';

import createAccountRoute from 'api/routes/account/create-account';
import readEmailRoute from 'api/routes/emails/read-email';
import LoadingButton from 'components/buttons/loading-button';
import MaskedTextField from 'components/inputs/masked-text-field';

import emailRegex from 'utils/email-regex';
import useQueryParams from 'hooks/use-query-params';

export default function SignupForm({ onSignup, hideSignin, code, email }) {
  const params = useQueryParams();
  const { register, handleSubmit, formState, errors, setError } = useForm({
    defaultValues: { email: email ?? '' },
  });
  const { isSubmitting } = formState;
  email = email ?? params.email ?? '';

  useEffect(() => {
    register({ name: 'server' });
  }, [register]);

  const submitForm = useCallback(
    (data) => {
      return createAccountRoute({
        enrollCodes: code ? [code] : undefined,
        ...data,
      }).then(({ status }) => {
        switch (status) {
          case 200:
            onSignup instanceof Function && onSignup(data);
            break;
          default:
            setError(
              'server',
              'serverMessage',
              'Something went wrong and your account was not created'
            );
        }
      });
    },
    [onSignup, setError, code]
  );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={1} alignItems="center">
        <Hidden xsDown>
          <Grid item>
            <NameIcon
              color={errors.first || errors.last ? 'error' : 'primary'}
            />
          </Grid>
        </Hidden>
        <Grid item xs>
          <TextField
            name="first"
            label="First name"
            variant="filled"
            margin="normal"
            fullWidth
            autoFocus
            disabled={isSubmitting}
            error={Boolean(errors.first)}
            helperText="Your first or given name"
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs>
          <TextField
            name="last"
            label="Last name"
            variant="filled"
            margin="normal"
            fullWidth
            disabled={isSubmitting}
            error={Boolean(errors.last)}
            helperText="Your last or family name"
            inputRef={register({ required: true })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Hidden xsDown>
          <Grid item>
            <EmailIcon color={Boolean(errors.email) ? 'error' : 'primary'} />
          </Grid>
        </Hidden>
        <Grid item xs>
          <TextField
            name="email"
            label="Email"
            variant="filled"
            margin="normal"
            fullWidth
            defaultValue={email || ''}
            disabled={isSubmitting}
            error={Boolean(errors.email)}
            helperText={emailHelperText(errors)}
            inputRef={register({
              required: true,
              pattern: emailRegex,
              validate: {
                taken: (value) =>
                  readEmailRoute(value).then(
                    ({ status, data }) =>
                      status === 404 || (status === 200 && !data.userId)
                  ),
              },
            })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Hidden xsDown>
          <Grid item>
            <PasswordIcon
              color={Boolean(errors.password) ? 'error' : 'primary'}
            />
          </Grid>
        </Hidden>
        <Grid item xs>
          <MaskedTextField
            name="password"
            label="Password"
            variant="filled"
            margin="normal"
            fullWidth
            disabled={isSubmitting}
            error={Boolean(errors.password)}
            helperText="Passwords must be at least 8 characters long"
            inputRef={register({ required: true, minLength: 8 })}
          />
        </Grid>
      </Grid>

      <Collapse in={Boolean(errors?.server)}>
        <Box
          mb={3}
          textAlign="right"
          component="p"
          fontWeight="bold"
          color="error.main"
        >
          {errors?.server?.message}
        </Box>
      </Collapse>

      <Box clone mt={1}>
        <Grid container justify="flex-end" alignItems="center" spacing={2}>
          {!hideSignin && (
            <Grid item>
              <Button
                component={Link}
                to="/signin"
                color="primary"
                disabled={isSubmitting}
              >
                Sign in
              </Button>
            </Grid>
          )}

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<Done />}
              loading={isSubmitting}
            >
              Sign up
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

function emailHelperText(errors) {
  switch (errors.email?.type) {
    case 'required':
      return 'Required field';
    case 'pattern':
      return 'Not a valid email address';
    case 'taken':
      return 'That email address already belongs to an account';
    default:
      return 'Your school or university email is recommended';
  }
}
