import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import Chart from './chart';

export default function TrafficByHourSection({ tickets, isLoading }) {
  return (
    <Section title="Traffic by time of day">
      <Typography paragraph>
        This chart shows which hours of the day are busiest on average. Use it
        to evaluate if office hours are being allocated to the optimal times of
        the day.
      </Typography>

      <Chart tickets={tickets} isLoading={isLoading} />
    </Section>
  );
}
