import { useState, useEffect } from 'react';
import useCourse from 'selectors/courses/use-course';
import useMyLastCompletedTicket from 'selectors/tickets/use-my-last-completed-ticket';

export default function useStudentTimeout(courseId) {
  const [timeoutMs, setTimeoutMs] = useState(0);
  const lastCompletedTicket = useMyLastCompletedTicket(courseId);
  const course = useCourse(courseId);

  useEffect(() => {
    const lastCompletedDate = new Date(lastCompletedTicket?.completedAt);
    const timeoutDuration = course?.timeoutDuration * 60 * 1000;
    const newTimeoutMs =
      lastCompletedDate.getTime() + timeoutDuration - Date.now();
    if (course?.timeoutAction === 'enabled' && newTimeoutMs > 0) {
      setTimeoutMs(newTimeoutMs);
    } else {
      setTimeoutMs(0);
    }
  }, [lastCompletedTicket, course]);

  useEffect(() => {
    if (timeoutMs > 0) {
      const timer = setTimeout(() => {
        setTimeoutMs(0);
      }, timeoutMs);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeoutMs]);

  return timeoutMs;
}
