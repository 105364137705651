import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CourseSearchDialog from './course-search-dialog';

export default function CourseSearchButton({ children, ...params }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick} startIcon={<SearchIcon />} {...params}>
        {children || 'Course search'}
      </Button>

      <CourseSearchDialog open={open} onClose={handleClose} />
    </>
  );
}
