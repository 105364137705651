import moment from 'moment';

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const hours = [
  '12am - 3am',
  '3am - 6am',
  '6am - 9am',
  '9am - 12pm',
  '12pm - 3pm',
  '3pm - 6pm',
  '6pm - 9pm',
  '9pm - 12am',
];

export default function weekdayHistogram(tickets, field = 'requestedAt') {
  const results = initResults();
  tickets.forEach((ticket) => {
    const hour = Math.floor(
      Number.parseInt(moment(ticket[field]).format('H')) / 3
    );
    const day = Number.parseInt(moment(ticket[field]).format('d'));
    results[days[day]][hours[hour]]++;
  });
  return results;
}

function initResults() {
  const results = {};
  days.forEach((day) => {
    results[day] = hours.reduce((obj, hour) => ({ ...obj, [hour]: 0 }), {});
  });
  return results;
}
