import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import EnrollIcon from '@material-ui/icons/Add';

import LoadingButton from 'components/buttons/loading-button';
import acceptInvitationRoute from 'api/routes/invitations/accept-invitation';

export default function AcceptButtonContent({
  invitationId,
  courseName,
  role,
  onTransition,
}) {
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isSubmitting) {
      return;
    }
    let isActive = true;
    setError(null);
    acceptInvitationRoute(invitationId)
      .then(({ data, status }) => {
        if (isActive) {
          switch (status) {
            case 200:
              if (onTransition instanceof Function) {
                if (data.status === 'active') {
                  onTransition('success');
                } else {
                  onTransition('email-sent');
                }
              }
              break;
            default:
              setError(
                'Something went wrong and the invitation could not be accepted.'
              );
          }
        }
      })
      .finally(() => {
        if (isActive) {
          setIsSubmitting(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [isSubmitting, invitationId, onTransition]);

  const handleClick = useCallback(() => {
    setIsSubmitting(true);
  }, []);

  const roleName = {
    student: 'a student',
    staff: 'a staff member',
    instructor: 'an instructor',
  }[role];

  return (
    <>
      <DialogTitle>View invitation</DialogTitle>

      <DialogContent>
        <DialogContentText>
          You were invited to join {courseName} as {roleName}.
        </DialogContentText>

        <DialogContentText>
          Would you like to accept this invitation?
        </DialogContentText>

        <Collapse in={Boolean(error)}>
          <DialogContentText color="error">{error}</DialogContentText>
        </Collapse>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          component={Link}
          to={`/`}
          disabled={isSubmitting}
        >
          Ignore
        </Button>

        <LoadingButton
          startIcon={<EnrollIcon />}
          color="primary"
          variant="contained"
          loading={isSubmitting}
          onClick={handleClick}
        >
          Accept invitation
        </LoadingButton>
      </DialogActions>
    </>
  );
}
