import React, { forwardRef } from 'react';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ActionMenu from './action-menu';

export default forwardRef(DialogActions);

function DialogActions({ ticketId, onAction, ...props }, ref) {
  return (
    <MuiDialogActions {...props} ref={ref}>
      <ActionMenu ticketId={ticketId} onSelect={onAction} />
    </MuiDialogActions>
  );
}
