import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import InfoIcon from '@material-ui/icons/Info';

import JoinByCodeForm from './join-by-code-form';
import useCourseRole from 'selectors/courses/use-course-role';

export default function JoinByCode({ course, onJoin }) {
  const [expanded, setExpanded] = useState(false);
  const courseRole = useCourseRole(course?.id);

  if (!course || courseRole) {
    return null;
  }

  const handleExpand = () => {
    setExpanded(true);
  };

  return (
    <Grid container spacing={2} alignContent="center">
      <Grid item>
        <InfoIcon color="disabled" />
      </Grid>

      <Grid item xs>
        <Typography variant="body1" color="textSecondary">
          Have an entry code for {course.code}?
        </Typography>

        {!expanded && (
          <LinkButton
            paragraph
            students={course.canStudentsEnrollByCode}
            staff={course.canStaffEnrollByCode}
            onClick={handleExpand}
          />
        )}

        <Collapse in={expanded}>
          <Box mb={2}>
            <JoinByCodeForm courseId={course.id} onSubmit={onJoin} />
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
}

function LinkButton({ students, staff, onClick, ...props }) {
  if (students && staff) {
    return (
      <Link variant="body2" component="button" onClick={onClick} {...props}>
        Enroll as a <strong>student</strong> or <strong>staff member</strong>{' '}
        using an entry code
      </Link>
    );
  } else if (students) {
    return (
      <Link variant="body2" component="button" onClick={onClick} {...props}>
        Enroll as a <strong>student</strong> using an entry code
      </Link>
    );
  } else if (staff) {
    return (
      <Link variant="body2" component="button" onClick={onClick} {...props}>
        Enroll as a <strong>staff member</strong> using an entry code
      </Link>
    );
  } else {
    return (
      <Typography variant="body2" color="textSecondary" {...props}>
        Entry code enrollment is disabled for this course
      </Typography>
    );
  }
}
