import React from 'react';
import ShiftCalendarSection from './sections/shift-calendar/section';
import TeacherTable from './sections/teacher-table/table';
import TeacherTicketLengthSection from './sections/teacher-ticket-length/section';

export default function TeacherUsageTab({
  tickets,
  teachers,
  shifts,
  isLoading,
}) {
  return (
    <>
      <TeacherTable
        tickets={tickets}
        teachers={teachers}
        shifts={shifts}
        isLoading={isLoading}
      />
      <TeacherTicketLengthSection
        tickets={tickets}
        teachers={teachers}
        isLoading={isLoading}
      />
      <ShiftCalendarSection shifts={shifts} isLoading={isLoading} />
    </>
  );
}
