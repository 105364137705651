/**
 * Exports a driver function for making requests via WebSockets. Uses
 *   sails.io.js under the hood.
 *
 * Driver functions have the following signature:
 *    driver({ method, url, data, headers }) => { data, headers, status }
 *
 * See: https://sailsjs.com/documentation/reference/web-sockets/socket-client
 */

import * as socketIOClient from 'socket.io-client';
import * as sailsIOClient from 'sails.io.js';

// Instantiate sails socket client
const io = sailsIOClient(socketIOClient);

// Configure sails socket client
io.sails.reconnection = true;
io.sails.autoConnect = false;
io.sails.useCORSRouteToGetCookie = false;
io.sails.url = process.env.REACT_APP_HOST_URL ?? 'http://localhost:1337';
if (process.env.NODE_ENV === 'production') {
  io.sails.environment = 'production';
} else {
  io.sails.environment = 'development';
}

// Export the underlying sails socket client
export const { socket } = io;

// Export helper function to make an async request
export default function request({ method = 'get', url, data, headers }) {
  return new Promise((resolve, reject) => {
    socket.request(
      { url, data, headers, method },
      (data, { headers, statusCode, error }) => {
        if (error) {
          //reject({ status: statusCode, headers, data });
          resolve({ status: statusCode, headers, data });
        } else {
          resolve({ status: statusCode, headers, data });
        }
      }
    );
  });
}
