import { useMemo } from 'react';
import useCourses from './use-courses';
import useAccountUsers from 'selectors/users/use-account-users';

export default function useAccountCourses() {
  const users = useAccountUsers();
  const courseIds = useMemo(() => users.map((u) => u.courseId), [users]);
  const courses = useCourses(courseIds);
  return courses;
}
