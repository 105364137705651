import React from 'react';
import Typography from '@material-ui/core/Typography';
import Section from 'components/content/section';
import TimeoutForm from './timeout-form';

export default function TimeoutSection({ courseId }) {
  return (
    <Section title="Limit Overuse">
      <Typography paragraph>
        To reduce the urge for students to immediately rejoin the waitlist after
        receiving help, My Digital Hand offers a &quot;timeout&quot; feature.
      </Typography>

      <Typography paragraph>
        If this setting is enabled, students will be timed out from adding
        themselves to the waitlist for a customizable amount of time after each
        successful interaction. Assistants are able to override the timeout on
        an individual basis by explicitly re-adding a student to the waitlist
        after an interaction is completed.
      </Typography>

      <TimeoutForm courseId={courseId} />
    </Section>
  );
}
