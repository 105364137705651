import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContinueIcon from '@material-ui/icons/ChevronRight';
import EmailSentIcon from '@material-ui/icons/Drafts';

export default function SignupComplete({ first, email }) {
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar>
            <EmailSentIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            Your account was set up, {first}!
          </Typography>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Typography paragraph>Go ahead and log in.</Typography>
      </Box>

      <Box mt={2} textAlign="right">
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/signin"
          endIcon={<ContinueIcon />}
        >
          Continue to login
        </Button>
      </Box>
    </>
  );
}
