import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import capitalize from 'utils/capitalize';
import pluralize from 'pluralize';
import QuestionTypeIcon from 'pages/courses/settings/feedback/questions/question-type-icon';
import Chart from './chart';
import asPercent from './as-percent';

export default function SurveyQuestionCard({
  question,
  responses,
  ticketCount,
}) {
  return (
    <Box my={4}>
      <Card>
        <CardHeader
          disableTypography
          avatar={
            <Avatar>
              <QuestionTypeIcon type={question.type} isFilled />
            </Avatar>
          }
          title={<Typography variant="body1">{question.prompt}</Typography>}
          subheader={
            <Typography variant="body2" color="textSecondary">
              {capitalize(question.type.split('-').join(' '), false)} question
            </Typography>
          }
        />

        <CardContent>
          <Typography>
            {pluralize('response', responses.length, true)} recorded for this
            question
          </Typography>

          <Typography variant="body2" paragraph>
            {pluralize('total interaction', ticketCount, true)},{' '}
            {asPercent(responses.length / ticketCount)} response rate
          </Typography>

          {['select-all', 'multiple-choice'].includes(question.type) && (
            <Chart question={question} responses={responses} />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
