import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  link: {
    border: `1px solid ${theme.palette.primary.main}`,
    width: '410px',
    margin: theme.spacing(1),
    display: 'inline-block',
    textDecoration: 'none',
    color: 'inherit',
    '&.is-disabled': {
      borderColor: theme.palette.text.disabled,
    },
  },

  container: {
    height: '230px',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      '&.is-disabled': {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  header: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&.is-disabled': {
      backgroundColor: theme.palette.text.disabled,
    },
  },
}));

export default function CourseCard({
  userStatus,
  courseStatus,
  role,
  courseId,
  school,
  code,
  title,
  semester,
  year,
}) {
  const classes = useStyles();

  const isArchived = courseStatus === 'archived';

  return (
    <Link
      to={`/courses/${courseId}`}
      className={clsx(classes.link, { 'is-disabled': isArchived })}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        className={clsx(classes.container, { 'is-disabled': isArchived })}
      >
        <Box clone p={1}>
          <Grid
            item
            className={clsx(classes.header, { 'is-disabled': isArchived })}
          >
            <Typography variant="subtitle1">{school}</Typography>
          </Grid>
        </Box>

        <Box clone p={1}>
          <Grid item>
            <Typography variant="h5">{code}</Typography>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="subtitle2">
              {semester} {year}
            </Typography>
          </Grid>
        </Box>

        <Box clone p={1} textAlign="right">
          <Grid item>
            <Typography variant="subtitle1">Enrolled as {role}</Typography>
          </Grid>
        </Box>
      </Grid>
    </Link>
  );
}
