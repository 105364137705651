import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';

import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import UserAvatar from 'components/user/user-avatar';
import capitalize from 'utils/capitalize';

const useStyles = makeStyles((theme) => ({
  body: {
    borderRadius: '1em',
    padding: '0.6em',
  },

  sentBody: {
    backgroundColor: '#afcaf1',
  },

  receivedBody: {
    backgroundColor: '#757580',
    color: 'white',
  },

  meta: {
    padding: '0.2em 0.6em',
  },

  spacer: {
    width: '40px',
    display: 'inline-block',
  },
}));

export default function ChatBubble({
  senderId,
  recipientId,
  body,
  updatedAt,
  status,
  authUserId,
  hideTime,
  hideAvatar,
}) {
  const isFromMe = authUserId === senderId;
  const otherUserId = isFromMe ? recipientId : senderId;
  const classes = useStyles();
  const [isShowingTime, setIsShowingTime] = useState(!hideTime);
  const classPrefix = isFromMe ? 'sent' : 'received';
  const bodyClass = `${classPrefix}Body`;

  useEffect(() => {
    setIsShowingTime(!hideTime);
  }, [hideTime]);

  return (
    <Grid
      container
      alignItems="baseline"
      spacing={1}
      justify={isFromMe ? 'flex-end' : 'flex-start'}
      wrap="nowrap"
      onClick={() => setIsShowingTime((isShowingTime) => !isShowingTime)}
    >
      {!isFromMe && (
        <Grid item>
          {hideAvatar ? (
            <span className={classes.spacer}></span>
          ) : (
            <UserAvatar id={otherUserId} />
          )}
        </Grid>
      )}
      <Grid item>
        <Typography
          variant="body2"
          className={`${classes.body} ${classes[bodyClass]}`}
        >
          {body}
        </Typography>
        <Collapse in={isShowingTime}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.meta}
            align={isFromMe ? 'right' : 'left'}
          >
            <Moment format="h:mm a">{updatedAt}</Moment> &middot;{' '}
            {capitalize(status)}
          </Typography>
        </Collapse>
      </Grid>
    </Grid>
  );
}
