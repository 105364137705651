import React, { useState, useCallback } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BackIcon from '@material-ui/icons/KeyboardBackspace';

import ResendButton from './resend-button';

export default function LookupResults({
  id,
  address,
  status,
  userId,
  confirmationTokenSentAt,
  onBack,
}) {
  const [isResent, setIsResent] = useState(false);
  const isConfirmed = status === 'confirmed';

  const handleClick = useCallback(() => {
    setIsResent(true);
  }, []);

  if (!id || !userId) {
    return (
      <>
        <Typography paragraph>
          The email address <strong>{address}</strong> is not registered with My
          Digital Hand.
        </Typography>

        <Typography paragraph>
          If the address belongs to you, you may use it to{' '}
          <Link to={`/signup?email=${address}`}>create a new account</Link>.
        </Typography>

        <Grid container justify="flex-end">
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={onBack}
              startIcon={<BackIcon />}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Typography paragraph>
          The email address <strong>{address}</strong>{' '}
          {isConfirmed
            ? 'has already been successfully confirmed'
            : 'is pending confirmation'}
          .
        </Typography>

        {!isConfirmed && (
          <>
            <Typography paragraph>
              The latest confirmation email was sent on{' '}
              <Moment format="M/D/YYYY">{confirmationTokenSentAt}</Moment> at{' '}
              <Moment format="h:mm a">{confirmationTokenSentAt}</Moment>.
            </Typography>

            {isResent && (
              <Typography paragraph color="error" variant="subtitle2">
                Confirmation email successfully resent
              </Typography>
            )}
          </>
        )}

        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              startIcon={<BackIcon />}
              onClick={onBack}
            >
              Back
            </Button>
          </Grid>

          {!isConfirmed && (
            <Grid item>
              <ResendButton email={address} onClick={handleClick} />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}
