import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import useScreenSize from 'hooks/use-screen-size';
import RecoverForm from './recover-form';

export default function RecoverDialog(params) {
  const [email, setEmail] = useState(null);
  const screenSize = useScreenSize();
  const isFullScreen = screenSize === 'xs';

  if (email) {
    return (
      <Dialog fullWidth fullScreen={isFullScreen} {...params}>
        <DialogTitle>Check your email</DialogTitle>

        <DialogContent>
          <DialogContentText>
            A password reset email has been sent to {email}.
          </DialogContentText>

          <DialogContentText>
            Please check your inbox for a recovery email from My Digital Hand.
          </DialogContentText>

          <DialogContentText>
            You may now close this browser window.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog fullWidth fullScreen={isFullScreen} {...params}>
        <DialogTitle>Account recovery</DialogTitle>

        <DialogContent>
          <DialogContentText>
            To reset your password, enter the email address associated with your
            My Digital Hand account.
          </DialogContentText>

          <RecoverForm onSubmit={setEmail} />
        </DialogContent>
      </Dialog>
    );
  }
}
