import md5 from 'md5';

export default function surveyQuestions(tickets, field) {
  const dict = {};

  tickets
    .filter((t) => Array.isArray(t[field]))
    .forEach((ticket) => {
      ticket[field]
        .filter((p) => p)
        .forEach((prompt) => {
          const hash = md5(JSON.stringify(prompt.question));
          if (hash in dict) {
            dict[hash].responses.push({ ticket, answer: prompt.answer });
          } else {
            dict[hash] = {
              key: hash,
              question: prompt.question,
              responses: [{ answer: prompt.answer, ticket }],
            };
          }
        });
    });

  return Object.values(dict);
}
