export function socketConnected() {
  return {
    type: 'SOCKET_CONNECTED',
  };
}

export function socketConnecting() {
  return {
    type: 'SOCKET_CONNECTING',
  };
}

export function socketDisconnected() {
  return {
    type: 'SOCKET_DISCONNECTED',
  };
}
