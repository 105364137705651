/**
 * See: https://github.com/ReactTraining/react-router/issues/5785#issuecomment-359427800
 *
 * react-router doesn't have support for React fragments in <Switch />. This
 *   component wraps react-router's <Switch /> so that it gets fragment support.
 *
 */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

export default function NestedSwitch({ children }) {
  const flattened = [];
  flatten(flattened, children);
  return React.createElement.apply(React, [Switch, null].concat(flattened));
}

function flatten(arr, children) {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if ([Route, Redirect].includes(child.type)) {
        arr.push(child);
      } else {
        flatten(arr, child.props.children);
      }
    }
  });
}
