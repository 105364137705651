/**
 * Manages authentication status independently from React
 */
import httpRequest from './http';

const auth = {
  listeners: {},

  signin(credentials) {
    return httpRequest({
      url: '/auth/signin',
      method: 'POST',
      data: credentials,
    }).then(({ data, status }) => {
      switch (status) {
        case 200:
          this.trigger('signin', data);
          return { data, status, message: 'You are now signed in' };
        case 401:
          return { data, status, message: 'You are already signed in' };
        case 403:
          return { data, status, message: 'Incorrect username or password' };
        default:
          return { data, status, message: 'Check your internet connection' };
      }
    });
  },

  signout() {
    return httpRequest({
      url: '/auth/signout',
      method: 'POST',
    }).then(({ data, status }) => {
      switch (status) {
        case 200:
          this.trigger('signout', data);
          return {
            data,
            status,
            message: 'You are now signed out',
          };
        default:
          return {
            data: { isAuthenticated: false, user: null },
            status,
            message: 'Something went wrong',
          };
      }
    });
  },

  check() {
    return httpRequest({
      url: '/auth',
      method: 'GET',
    }).then(({ data, status }) => {
      switch (status) {
        case 200:
          if (data.isAuthenticated) {
            this.trigger('signin', data);
            return { data, status, message: 'You are signed in' };
          } else {
            this.trigger('signout', data);
            return { data, status, message: 'You are signed out' };
          }
        default:
          return {
            data: { isAuthenticated: false, user: null },
            status,
            message: 'Something went wrong',
          };
      }
    });
  },

  on(event, handler) {
    if (!Array.isArray(this.listeners[event])) {
      this.listeners[event] = [];
    }
    if (!this.listeners[event].includes(handler)) {
      this.listeners[event].push(handler);
    }
  },

  off(event, handler) {
    const handlers = this.listeners[event];
    if (Array.isArray(handlers)) {
      let i = handlers.indexOf(handler);
      while (i > -1) {
        handlers.splice(i, 1);
        i = handlers.indexOf(handler);
      }
    }
  },

  trigger(event, ...data) {
    const handlers = this.listeners[event];
    if (Array.isArray(handlers)) {
      for (const handler of handlers) {
        handler(...data);
      }
    }
  },
};

export default auth;
