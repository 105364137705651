import React from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function EmailSentContent({ email }) {
  return (
    <>
      <DialogTitle>Check your email inbox</DialogTitle>

      <DialogContent>
        <DialogContentText>
          An email was sent to {email ?? 'your email address'}.
        </DialogContentText>

        <DialogContentText>
          To complete enrollment, check your inbox for a My Digital Hand
          confirmation email address.
        </DialogContentText>

        <DialogContentText>You may now close this window.</DialogContentText>
      </DialogContent>
    </>
  );
}
