export default function newestDate(tickets) {
  if (Array.isArray(tickets) && tickets.length > 0) {
    let newest = new Date(tickets[0].requestedAt);
    tickets.forEach((ticket) => {
      const date = new Date(ticket.requestedAt);
      if (newest.getTime() < date.getTime()) {
        newest = date;
      }
    });
    return newest;
  } else {
    return new Date(NaN);
  }
}
