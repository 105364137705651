import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
  },
  formControlLabel: {
    flexGrow: 1,
  },
});

export default function SelectAllField({
  options = [],
  selections = [],
  isDisabled = false,
  isRequired = false,
  isOtherEnabled = false,
  otherValue = '',
  onChange,
}) {
  const classes = useStyles();

  const toggleSelectOption = (i) => () => {
    if (onChange instanceof Function) {
      let newSelections = [...selections];
      if (selections.includes(i)) {
        while (newSelections.includes(i)) {
          newSelections.splice(selections.indexOf(i), 1);
        }
      } else {
        newSelections.push(i);
      }
      onChange(
        newSelections,
        otherValue,
        isValid(options, newSelections, isOtherEnabled, otherValue)
      );
    }
  };

  const handleSelectOption = (i) => () => {
    if (!selections.includes(i)) {
      toggleSelectOption(i);
    }
  };

  const handleChangeOther = (event) => {
    const newOtherValue = event.target.value;
    if (onChange instanceof Function) {
      onChange(
        selections,
        newOtherValue,
        isValid(options, selections, isOtherEnabled, newOtherValue)
      );
    }
  };

  return (
    <FormControl
      component="fieldset"
      classes={{ root: classes.formControl }}
      disabled={isDisabled}
    >
      <FormLabel component="legend">Select all that apply</FormLabel>
      <FormGroup>
        {options.map((label, i) => (
          <FormControlLabel
            key={label}
            label={label}
            control={
              <Checkbox
                checked={selections.includes(i)}
                onChange={toggleSelectOption(i)}
              />
            }
          />
        ))}

        {isOtherEnabled && (
          <FormControlLabel
            value={options.length}
            control={
              <Checkbox
                checked={selections.includes(options.length)}
                onChange={toggleSelectOption(options.length)}
              />
            }
            classes={{ label: classes.formControlLabel }}
            label={
              <TextField
                variant="standard"
                fullWidth
                placeholder="Custom answer"
                value={otherValue}
                color="secondary"
                onChange={handleChangeOther}
                onFocus={handleSelectOption(options.length)}
              />
            }
          />
        )}
      </FormGroup>
      {isRequired && (
        <FormHelperText>This question is required.</FormHelperText>
      )}
    </FormControl>
  );
}

function isValid(options, selections, isOtherEnabled, otherValue) {
  if (selections.length === 0) {
    return false;
  } else if (selections.some((i) => i > options.length || i < 0)) {
    return false;
  } else if (isOtherEnabled) {
    return !selections.includes(options.length) || otherValue.length > 2;
  } else {
    return selections.every((i) => i < options.length);
  }
}
