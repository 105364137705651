import React, { useCallback, forwardRef } from 'react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import LaunchIcon from '@material-ui/icons/Launch';

import openTicketRoute from 'api/routes/tickets/open-ticket';

export default forwardRef(OpenMenuItem);

function OpenMenuItem({ id, onClick, ...props }, ref) {
  const handleClick = useCallback(
    (event) => {
      onClick instanceof Function && onClick(event);
      return openTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <MenuItem onClick={handleClick} ref={ref} {...props}>
      <ListItemIcon>
        <LaunchIcon color="primary" />
      </ListItemIcon>
      <ListItemText>Immediately start helping this student</ListItemText>
    </MenuItem>
  );
}
