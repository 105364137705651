import numTicketsPerHour from '../../../data-shapers/num-tickets-per-hour';
import statusFilter from '../../../data-shapers/status-filter';

export default function makeData(tickets) {
  const completedTickets = statusFilter(tickets, 'completed');
  const canceledTickets = statusFilter(tickets, 'canceled');
  const completedCounts = numTicketsPerHour(completedTickets);
  const canceledCounts = numTicketsPerHour(canceledTickets);
  return [
    {
      time: '12am - 6am',
      completed: sum(completedCounts, 0, 6),
      canceled: sum(canceledCounts, 0, 6),
    },
    {
      time: '6am - 9am',
      completed: sum(completedCounts, 6),
      canceled: sum(canceledCounts, 6),
    },
    {
      time: '9am - 12pm',
      completed: sum(completedCounts, 9),
      canceled: sum(canceledCounts, 9),
    },
    {
      time: '12pm - 3pm',
      completed: sum(completedCounts, 12),
      canceled: sum(canceledCounts, 12),
    },
    {
      time: '3pm - 6pm',
      completed: sum(completedCounts, 15),
      canceled: sum(canceledCounts, 15),
    },
    {
      time: '6pm - 12am',
      completed: sum(completedCounts, 18, 6),
      canceled: sum(canceledCounts, 18, 6),
    },
  ];
}

function sum(arr, start, num = 3) {
  let total = 0;
  for (let i = 0; i < num; i++) {
    total += arr[start + i];
  }
  return total;
}
