import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function CourseTitleColumn({ courseTitle, courseCode }) {
  return (
    <>
      <Typography variant="body1">{courseCode}</Typography>
      <Typography variant="body2" color="textSecondary">
        {courseTitle}
      </Typography>
    </>
  );
}
