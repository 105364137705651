import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import AppBar from 'components/app-bar/app-bar';
import useChatThread from 'selectors/chats/use-chat-thread';
import ChatComposer from './chat-composer';
import ChatBubbleList from './chat-bubble-list';
import useUser from 'selectors/users/use-user';
import useAccountUser from 'selectors/users/use-account-user';

export default function ThreadPage() {
  const params = useParams();
  const userId = Number(params.userId);
  const courseId = Number(params.courseId);
  const user = useUser(userId);
  const accountUser = useAccountUser(user?.courseId);
  const thread = useChatThread(userId);
  const messages = [...thread].reverse();

  if (!accountUser) {
    return null;
  }

  return (
    <AppBar
      title={user ? `${user.first} ${user.last}` : 'Thread'}
      back={`/courses/${courseId}/chat`}
    >
      <Box p={2} clone>
        <Paper>
          <Box mt={2}>
            <ChatBubbleList
              messages={messages}
              user={user}
              authUserId={accountUser.id}
            />
          </Box>

          <Box mt={2}>
            <ChatComposer userId={userId} />
          </Box>
        </Paper>
      </Box>
    </AppBar>
  );
}
