import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import AttachmentIcon from '@material-ui/icons/Attachment';

import QuestionPrompt from './question-prompt';

const useStyles = makeStyles(() => ({
  details: {
    alignItems: 'flex-start',
  },
}));

export default forwardRef(PromptsListItem);

function PromptsListItem({ prompts, ...props }, ref) {
  const classes = useStyles();

  prompts = Array.isArray(prompts) ? prompts.filter((p) => p) : [];

  if (prompts.length === 0) {
    return null;
  }

  return (
    <ListItem className={classes.details} ref={ref} {...props}>
      <ListItemIcon>
        <AttachmentIcon color="primary" />
      </ListItemIcon>

      <ListItemText>
        <Typography paragraph variant="subtitle2">
          Help request information
        </Typography>

        {prompts.map((p) => (
          <QuestionPrompt key={p.question.prompt} {...p} />
        ))}
      </ListItemText>
    </ListItem>
  );
}
