import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';

import { NavTheme } from 'styles/theme';
import CourseDropdown from './course-dropdown';
import CourseNavList from './course-nav-list';
import UserNavMenu from './user-nav';
import OrphanedLink from './orphaned-link';

const useStyles = makeStyles((theme) => ({
  // A <Box> which contains two children:
  //   1. the <Drawer>
  //   2. a <Box> containing the page content
  root: {
    display: 'flex',
  },

  // The <Drawer>
  drawer: {
    flex: '0 0 275px',
  },

  // A <Box> containing the page content
  content: {
    width: 'calc(100% - 275px)',
  },

  // The drawer's material element
  drawerPaper: {
    width: '275px',
  },

  // Spacer for heading
  spacer: theme.mixins.toolbar,
}));

export default function NavDrawer({
  orphanedLink,
  orphanedLinkText,
  children,
}) {
  const classes = useStyles();
  const params = useParams();
  const courseId = Number(params.courseId);

  return (
    <Box className={classes.root}>
      <ThemeProvider theme={NavTheme}>
        <Drawer
          variant="permanent"
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.spacer} />
          <Divider />

          {orphanedLink && (
            <OrphanedLink link={orphanedLink} text={orphanedLinkText} />
          )}

          {Boolean(courseId) && (
            <>
              <CourseDropdown courseId={courseId} />
              <CourseNavList courseId={courseId} />
            </>
          )}
          <Grid item xs />
          <UserNavMenu />
        </Drawer>
      </ThemeProvider>

      <Box className={classes.content} children={children} />
    </Box>
  );
}
