import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

export default function CourseRole(params) {
  return (
    <Grid container spacing={2} alignContent="center">
      <Grid item>
        <InfoIcon color="disabled" />
      </Grid>

      <Grid item xs>
        <Typography variant="body1" color="textSecondary">
          <RoleText {...params} />
        </Typography>
      </Grid>
    </Grid>
  );
}

function RoleText({ role, name = 'this course' }) {
  switch (role) {
    case 'instructor':
      return `You are enrolled as an instructor in ${name}`;
    case 'staff':
      return `You are enrolled as a staff member in ${name}`;
    case 'student':
      return `You are enrolled as a student in ${name}`;
    default:
      return `You are not enrolled in ${name}`;
  }
}
