import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import SignoutIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropUp';
import HelpIcon from '@material-ui/icons/Info';
import AccountSettingsIcon from '@material-ui/icons/Settings';

import MainTheme from 'styles/theme';
import { UserWithStatusBadge } from 'components/user/user-avatar';
import Auth from 'api/drivers/auth';
import useAccount from 'selectors/auth/use-account';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
  },
}));

export default function UserNav() {
  const [anchorElement, setAnchorElement] = useState(null);
  const account = useAccount();
  const classes = useStyles();

  return (
    <Box>
      <List component="div" disablePadding>
        <ListItem
          component="div"
          button
          onClick={(e) => setAnchorElement(e.currentTarget)}
        >
          <ListItemAvatar>
            <UserWithStatusBadge {...account} />
          </ListItemAvatar>
          <ListItemText
            primary={`${account.first} ${account.last}`}
            primaryTypographyProps={{ variant: 'body2' }}
          />
          <ExpandMoreIcon className={classes.icon} />
        </ListItem>
      </List>

      <ThemeProvider theme={MainTheme}>
        <Menu
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={() => setAnchorElement(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem button component={Link} to="/account">
            <ListItemIcon>
              <AccountSettingsIcon color="inherit" />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </MenuItem>

          <MenuItem button component={Link} to="/help">
            <ListItemIcon>
              <HelpIcon color="inherit" />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </MenuItem>

          <MenuItem button onClick={Auth.signout.bind(Auth)}>
            <ListItemIcon>
              <SignoutIcon color="inherit" />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </Box>
  );
}
