import React, { useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import useAccount from 'selectors/auth/use-account';
import Section from 'components/content/section';
import EditName from './edit-name';
import ViewName from './view-name';

export default function NameSection() {
  const [isEditing, setIsEditing] = useState(false);
  const account = useAccount();

  const handleEditToggle = useCallback(() => {
    setIsEditing((x) => !x);
  }, []);

  return (
    <Section title="Display name">
      <Typography paragraph>
        You can edit your account display name here. Note that instructors have
        the ability to override your display name if you are enrolled in their
        course.
      </Typography>

      {!isEditing && (
        <ViewName
          first={account.first}
          last={account.last}
          onClick={handleEditToggle}
        />
      )}

      {isEditing && (
        <EditName
          first={account.first}
          last={account.last}
          onClick={handleEditToggle}
        />
      )}
    </Section>
  );
}
