import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import RecoverPage from './recover/recover-page';
import ResetPage from './reset/reset-page';

export default function ResetRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/recover`} component={RecoverPage} />
      <Route exact path={`${path}/reset`} component={ResetPage} />
    </Switch>
  );
}
