import React from 'react';
import pluralize from 'pluralize';

import Typography from '@material-ui/core/Typography';

import UserList from 'components/user/user-list';
import useCourse from 'selectors/courses/use-course';
import useWaitingTicketIds from 'selectors/tickets/use-waiting-ticket-ids';
import useWaitingStudentIds from 'selectors/tickets/use-waiting-student-ids';
import useWaitingCount from 'selectors/tickets/use-waiting-count';

import ordinal from 'utils/ordinal';

export default function WaitingQueueInfo({ ticketId, courseId }) {
  const course = useCourse(courseId);

  switch (course?.queueStatusPolicy) {
    case 'names':
      return <CompleteQueue courseId={courseId} />;
    case 'position':
      return <PositionInQueue courseId={courseId} ticketId={ticketId} />;
    case 'total':
      return <TotalInQueue courseId={courseId} />;
    case 'none':
    default:
      return <InQueue />;
  }
}

function CompleteQueue({ courseId, ticketId }) {
  const waitingStudentIds = useWaitingStudentIds(courseId);

  return (
    <>
      <PositionInQueue courseId={courseId} ticketId={ticketId} />
      <UserList userIds={waitingStudentIds} numbered />
    </>
  );
}

function PositionInQueue({ courseId, ticketId, ...params }) {
  const ticketIds = useWaitingTicketIds(courseId);
  const numOthersInLine = ticketIds.length - 1;
  const placeInLine = ticketIds.indexOf(ticketId);

  if (numOthersInLine < 1) {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are the only one in line
      </Typography>
    );
  } else {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are {placeInLine <= 0 ? 'next' : ordinal(placeInLine + 1)} in line (
        {ticketIds.length} total)
      </Typography>
    );
  }
}

function TotalInQueue({ courseId, ...params }) {
  const numOthersInLine = useWaitingCount(courseId) - 1;

  if (numOthersInLine < 1) {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are the only one in line
      </Typography>
    );
  } else {
    return (
      <Typography gutterBottom variant="h5" {...params}>
        You are in line with {pluralize('other student', numOthersInLine, true)}
      </Typography>
    );
  }
}

function InQueue(params) {
  return (
    <Typography gutterBottom variant="h5" {...params}>
      You are waiting in line
    </Typography>
  );
}
