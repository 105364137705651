import React from 'react';
import Moment from 'react-moment';

import StartIcon from '@material-ui/icons/Schedule';
import EndIcon from '@material-ui/icons/Timer';
import LocationIcon from '@material-ui/icons/LocationOn';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

export default function ShiftDetails({ shift }) {
  if (isActive(shift)) {
    return (
      <List dense>
        <ListItem>
          <ListItemIcon>
            <StartIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            Started at{' '}
            <strong>
              <Moment format="LT">{startDate(shift)}</Moment>
            </strong>
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <EndIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            Ends at{' '}
            <strong>
              <Moment format="LT">{endDate(shift)}</Moment>
            </strong>{' '}
            (<Moment date={endDate(shift)} fromNow />)
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <LocationIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            {shift.location || (
              <Typography color="textSecondary" variant="body2" component="em">
                Location unspecified
              </Typography>
            )}
          </ListItemText>
        </ListItem>
      </List>
    );
  } else {
    return (
      <Typography paragraph>
        Currently you are not on a shift and will appear unavailable to
        students. Start a shift before helping students at office hours to show
        when you&apos;re available to help.
      </Typography>
    );
  }
}

function startDate(shift) {
  return new Date(shift?.startAt);
}

function endDate(shift) {
  return new Date(shift?.endAt);
}

function isActive(shift) {
  return endDate(shift).getTime() > Date.now();
}
