import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import HomeIcon from '@material-ui/icons/Home';

export default function ErrorContent() {
  return (
    <>
      <DialogTitle>Invalid invitation link</DialogTitle>

      <DialogContent>
        <DialogContentText>
          That invitation link is expired or invalid.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<HomeIcon />}
          color="primary"
          variant="contained"
          component={Link}
          to="/"
        >
          Back to home
        </Button>
      </DialogActions>
    </>
  );
}
