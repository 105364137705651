import React, { useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line';
import Typography from '@material-ui/core/Typography';
import makeData from './make-data';

export default function StudentDistChart({ isLoading, tickets, studentCount }) {
  const data = useMemo(() => makeData({ tickets, studentCount }), [
    tickets,
    studentCount,
  ]);

  if (isLoading) {
    return (
      <Typography>
        <em>Loading data...</em>
      </Typography>
    );
  } else if (tickets.length === 0) {
    return (
      <Typography>
        <em>No data to display.</em>
      </Typography>
    );
  }

  const tickMarkInterval = Math.max(1, Math.round(studentCount / 20 / 5)) * 5;

  return (
    <div style={{ height: '300px' }}>
      <ResponsiveLine
        data={data}
        width={820}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        enableGridX={false}
        enablePoints={false}
        isInteractive={false}
        enableArea={true}
        areaOpacity={1}
        colors={['#122d52']}
        curve="basis"
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Students',
          legendOffset: 36,
          legendPosition: 'middle',
          format: (v) => (v % tickMarkInterval === 0 ? v : ''),
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Number of interactions',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
      />
    </div>
  );
}
