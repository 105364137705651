/**
 * Selects a list of usages by id from the store, fetching them from the server
 *   if necessary
 */

import { useSelector, useDispatch } from 'react-redux';
import fetchUsage from 'actions/usages/fetch';
import getUsages from './get-usages';

export default function useUsages(usageIds) {
  // Ensure ids is an array of positive integers
  if (Array.isArray(usageIds)) {
    usageIds = usageIds.filter(isPositiveInteger);
  } else {
    usageIds = [];
  }

  // Select data from store
  const usages = useSelector((store) => getUsages(store, usageIds));

  // Fetch missing records from server
  const dispatch = useDispatch();
  usageIds.forEach((id, i) => {
    if (!usages[i]) {
      dispatch(fetchUsage(id));
    }
  });

  // All done!
  return usages;
}

function isPositiveInteger(id) {
  return Number.isInteger(id) && id > 0;
}
