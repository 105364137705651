import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import getAccountId from 'selectors/auth/get-account-id';
import getUsers from './get-users';

export const getAccountUsers = createSelector(
  getAccountId,
  getUsers,
  (accountId, users) =>
    Number.isInteger(accountId) && accountId > 0
      ? users.filter((user) => user.accountId === accountId)
      : []
);

export default function useAccountUsers() {
  return useSelector(getAccountUsers);
}
