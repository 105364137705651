import React from 'react';
import { Route, Switch } from 'react-router';

import IndexPage from './index/chat-index-page';
import NewMessagePage from './new-message/new-message-page';
import ThreadPage from './thread/thread-page';

export default function ChatRouter() {
  return (
    <Switch>
      <Route exact path="/courses/:courseId/chat/new">
        <NewMessagePage />
      </Route>

      <Route exact path="/courses/:courseId/chat/:userId">
        <ThreadPage />
      </Route>

      <Route exact path="/courses/:courseId/chat">
        <IndexPage />
      </Route>
    </Switch>
  );
}
