import React from 'react';
import Moment from 'react-moment';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import LastHelpedIcon from '@material-ui/icons/History';

import useTicket from 'selectors/tickets/use-ticket';
import UserNames from 'components/user/user-names';
import useUsages from 'selectors/usages/use-usages';

export default function LastHelpedListItems({ ticket }) {
  const studentIds = ticket.students.map((s) => s.userId);
  const usages = useUsages(studentIds);

  return usages
    .filter((u) => u && Number.isInteger(u.latestCompletedTicketId))
    .map((u) => (
      <LastHelpedListItem
        key={u.userId}
        userId={u.userId}
        ticketId={u.latestCompletedTicketId}
      />
    ));
}

function LastHelpedListItem({ userId, ticketId }) {
  const ticket = useTicket(ticketId);

  if (!ticket) {
    return null;
  }

  return (
    <ListItem>
      <ListItemIcon>
        <LastHelpedIcon color="primary" />
      </ListItemIcon>

      <ListItemText>
        <Typography variant="body2">
          Request history for <UserNames ids={[userId]} lowercaseYou />
        </Typography>
        {ticket && (
          <Typography variant="body2" color="textSecondary">
            Last helped <Moment fromNow>{ticket.completedAt}</Moment> by{' '}
            <UserNames
              ids={ticket.teachers.map((t) => t.userId)}
              lowercaseYou
            />
          </Typography>
        )}
      </ListItemText>
    </ListItem>
  );
}
