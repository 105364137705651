import React from 'react';

import useCourse from 'selectors/courses/use-course';
import useAccount from 'selectors/auth/use-account';

import PostInteractionDialog from 'components/dialogs/quizzes/post-interaction-dialog';

export default function FeedbackLoader({ ticket, onComplete }) {
  const course = useCourse(ticket?.courseId);
  const account = useAccount();

  if (!ticket || !course) {
    return null;
  }

  const isTeacher = ticket.teachers
    .map((u) => u.accountId)
    .includes(account.id);
  const isStudent = ticket.students
    .map((u) => u.accountId)
    .includes(account.id);
  const questions = isTeacher
    ? course.teacherFeedback
    : isStudent
    ? course.studentFeedback
    : [];

  return (
    <PostInteractionDialog
      ticketId={ticket.id}
      questions={questions}
      open={true}
      onClose={onComplete}
    />
  );
}
