import React from 'react';
import { makeStyles } from '@material-ui/core';
import Placeholder from 'react-placeholder';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import FollowupIcon from '@material-ui/icons/KeyboardReturn';

import QuestionPrompt from './question-prompt';
import useTicket from 'selectors/tickets/use-ticket';

const useStyles = makeStyles(() => ({
  details: {
    alignItems: 'flex-start',
  },
}));

export default function FollowupListItem({ parentId, ...props }) {
  const classes = useStyles();
  const ticket = useTicket(parentId);

  if (!Number.isInteger(parentId)) {
    return null;
  } else if (!ticket || !Array.isArray(ticket.prompts)) {
    return <Placeholder rows={1} />;
  } else if (Number.isInteger(ticket.parentId)) {
    return <FollowupListItem parentId={ticket.parentId} {...props} />;
  }

  const prompts = Array.isArray(ticket.prompts)
    ? ticket.prompts.filter((p) => p)
    : [];

  return (
    <ListItem className={classes.details} {...props}>
      <ListItemIcon>
        <FollowupIcon color="primary" />
      </ListItemIcon>

      <ListItemText>
        <Typography paragraph variant="subtitle2">
          Follow-up to a previous question
        </Typography>

        {prompts.map((p) => (
          <QuestionPrompt key={p.question.prompt} {...p} />
        ))}
      </ListItemText>
    </ListItem>
  );
}
