import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import LookupPage from './lookup/lookup-page';
import ConfirmPage from './confirm/confirm-page';

export default function EmailsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/lookup`}>
        <LookupPage />
      </Route>
      <Route path={`${path}/confirm`}>
        <ConfirmPage />
      </Route>
    </Switch>
  );
}
