import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import PostInteractionDialog from 'components/dialogs/quizzes/post-interaction-dialog';

export default function FeedbackDialogButton({ ticketId, prompts, ...props }) {
  const [open, setOpen] = useState(false);

  if (!Array.isArray(prompts)) {
    return null;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link component="button" color="primary" onClick={handleClick} {...props}>
        Leave feedback now
      </Link>

      <PostInteractionDialog
        ticketId={ticketId}
        questions={prompts}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
