import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import FollowUpIcon from '@material-ui/icons/KeyboardReturn';

export default function FollowUpListItem({ parentId }) {
  return (
    <ListItem>
      <ListItemIcon>
        <FollowUpIcon color="primary" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2">Follow-up request</Typography>
        <Typography variant="body2" color="textSecondary">
          This student is asking for more help after being seen earlier today
        </Typography>
      </ListItemText>
    </ListItem>
  );
}
