import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import ShiftForm from './shift-form';
import { Typography } from '@material-ui/core';

export default function ShiftDialog({ shift, ...params }) {
  const [isOpen, setIsOpen] = useState(false);
  const isActive = new Date(shift?.endAt).getTime() > Date.now();

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isActive && (
        <Button onClick={handleClick} startIcon={<EditIcon />} color="primary">
          Edit shift
        </Button>
      )}

      {!isActive && (
        <Button variant="contained" color="primary" onClick={handleClick}>
          Start shift
        </Button>
      )}

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h6">
                {isActive ? 'Modify shift' : 'Start a shift'}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <ShiftForm shift={shift} onSave={handleClose} {...params} />
        </DialogContent>
      </Dialog>
    </>
  );
}
