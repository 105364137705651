import { Model, attr } from 'redux-orm';

export default class Course extends Model {
  static reducer({ type, payload }, Course, session) {
    switch (type) {
      case 'COURSE_FETCHED':
      case 'COURSE_CREATED':
      case 'COURSE_CHANGED':
        Course.upsert(payload);
        break;
      case 'COURSE_DESTROYED':
        if (Course.idExists(payload)) {
          Course.withId(payload).delete();
        }
        break;
      case 'USER_CHANGED':
        if ('role' in payload && Course.exists({ userId: payload.id })) {
          Course.get({ userId: payload.id }).update({ role: payload.role });
        }
        break;
      case 'USER_REMOVED':
        if (Course.exists({ userId: payload })) {
          Course.get({ userId: payload }).update({
            role: undefined,
            userId: undefined,
          });
        }
        break;
      case 'AUTH_INVALIDATED':
        Course.all()
          .toModelArray()
          .forEach((c) => c.delete());
        break;
      default:
    }
  }
}

Course.modelName = 'Course';

Course.fields = {
  id: attr(),

  // Status
  role: attr(),
  status: attr(),

  // Information
  title: attr(),
  code: attr(),
  semester: attr(),
  year: attr(),
  description: attr(),

  // Schedule
  scheduleConfig: attr(),
  scheduleUrl: attr(),

  // Enrollment
  canStudentsEnrollByEmail: attr(),
  emailDomain: attr(),
  canStudentsEnrollByCode: attr(),
  studentEnrollCode: attr(),
  canStaffEnrollByCode: attr(),
  staffEnrollCode: attr(),

  // Behavior
  timerAction: attr(),
  timerDuration: attr(),
  queueStatusPolicy: attr(),
  shiftPolicy: attr(),

  // Prompts and feedback
  prompts: attr(),
  isStudentFeedbackRequired: attr(),
  studentFeedback: attr(),
  isTeacherFeedbackRequired: attr(),
  teacherFeedback: attr(),
};
