import React from 'react';
import Typography from '@material-ui/core/Typography';
import Section from 'components/content/section';
import QueuePolicyForm from './queue-policy-form.jsx';

export default function QueuePolicySection({ courseId }) {
  return (
    <Section title="Queue visibility">
      <Typography paragraph>
        Adjust the information that students can see about the office hours
        waitlist and their position in the waitlist.
      </Typography>

      <QueuePolicyForm courseId={courseId} />
    </Section>
  );
}
