import React, { useMemo } from 'react';
import pluralize from 'pluralize';
import { ResponsiveBar } from '@nivo/bar';
import Typography from '@material-ui/core/Typography';
import makeData from './make-data';
import msToStr from '../../../data-shapers/ms-to-str';

export default function TeacherTicketLengthChart({
  isLoading,
  tickets,
  teachers,
}) {
  const data = useMemo(() => makeData({ tickets, teachers }), [
    tickets,
    teachers,
  ]);

  if (isLoading) {
    return (
      <Typography>
        <em>Loading data...</em>
      </Typography>
    );
  } else if (data.length === 0) {
    return (
      <Typography>
        <em>No data to display.</em>
      </Typography>
    );
  }

  const height = Math.max(200, data.length * 37);

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveBar
        data={data}
        width={820}
        height={height}
        margin={{ top: 50, right: 50, bottom: 50, left: 160 }}
        enableGridX={false}
        keys={['avgHelpingMs']}
        indexBy="name"
        layout="horizontal"
        colors={['#122d52']}
        curve="basis"
        labelFormat={msToStr}
        labelTextColor="#ffffff"
        labelSkipWidth={30}
        tooltip={Tooltip}
        axisBottom={{
          legend: 'Average interaction length',
          legendOffset: 36,
          legendPosition: 'middle',
          format: msToStr,
        }}
      />
    </div>
  );
}

function Tooltip({ data }) {
  return (
    <>
      <Typography variant="body1">{data.name}</Typography>
      <Typography variant="body2">
        {pluralize('interaction', data.ticketCount, true)}
      </Typography>
      <Typography variant="body2">
        {msToStr(data.avgHelpingMs)} average interaction time
      </Typography>
    </>
  );
}
