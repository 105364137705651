import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import useCourse from 'selectors/courses/use-course';
import LoadingButton from 'components/buttons/loading-button';

const headers = [
  'ticketId',
  'status',
  'studentPrompts',
  'isFollowup',
  'parentId',
  'requestedAt',
  'calledAt',
  'startedAt',
  'completedAt',
  'canceledAt',
  'virtualUrl',
  'teacherFirstName',
  'teacherLastName',
  'teacherEmail',
  'teacherGroup',
  'teacherIdentifier',
  'teacherAccountId',
  'teacherUserId',
  'teacherLeftFeedback',
  'teacherFeedback',
  'studentFirstName',
  'studentLastName',
  'studentEmail',
  'studentGroup',
  'studentIdentifier',
  'studentAccountId',
  'studentUserId',
  'studentLeftFeedback',
  'studentFeedback',
];

export default function ExportInteractionsButton({
  isLoading,
  courseId,
  tickets,
  ...props
}) {
  const course = useCourse(courseId);

  const handleClick = () => {
    const csvExporter = new ExportToCsv({
      filename: exportInteractionsFilename(course),
      headers,
      showLabels: true,
    });
    csvExporter.generateCsv(format(tickets));
  };

  return <LoadingButton {...props} loading={isLoading} onClick={handleClick} />;
}

function exportInteractionsFilename(course) {
  const date = moment().format('YYYY-MM-DD');
  const time = moment().format('HH-mm');
  return [course.code, course.semester, course.year, 'interactions', date, time]
    .filter((x) => x)
    .join('-')
    .replace(' ', '-');
}

function format(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return [{}];
  } else {
    return data.map((t) =>
      headers.reduce((obj, k) => ({ ...obj, [k]: formatValue(t[k]) }), {})
    );
  }
}

function formatValue(value) {
  if ([null, undefined].includes(value)) {
    return '';
  } else if (Array.isArray(value)) {
    return JSON.stringify(value);
  } else {
    return value;
  }
}
