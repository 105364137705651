import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import nameList from 'utils/name-list';

import CourseRole from './course-role';
import JoinByCode from './join-by-code';
import JoinByEmail from './join-by-email';
import JoinSuccess from './join-success';
import useCourseRole from 'selectors/courses/use-course-role';

export default function CourseDetails({ course }) {
  const [joined, setJoined] = useState(null);
  const courseRole = useCourseRole(course?.id);

  useEffect(() => {
    setJoined(null);
  }, [course]);

  if (!course) {
    return null;
  }

  return (
    <Box mt={5} mb={2}>
      <Typography variant="h6">
        {course.code}: {course.title}
      </Typography>

      <Typography variant="subtitle2" paragraph>
        Instructed by{' '}
        {nameList(course.instructors.map((i) => `${i.first} ${i.last}`))} |{' '}
        <Typography variant="subtitle2" color="textSecondary" component="span">
          {course.semester} {course.year}
        </Typography>
      </Typography>

      {joined ? (
        <JoinSuccess
          status={joined.status}
          email={joined.email}
          role={joined.role}
          courseName={course.code}
          courseId={course.id}
        />
      ) : (
        <>
          <CourseRole role={courseRole} name={course.code} />

          <JoinByCode course={course} onJoin={setJoined} />

          <JoinByEmail course={course} onJoin={setJoined} />
        </>
      )}
    </Box>
  );
}
