import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import useScreenSize from 'hooks/use-screen-size';
import SignupForm from './signup-form';
import SignupComplete from './signup-complete';

export default function SignupDialog({ open, onSignup, ...params }) {
  const [user, setUser] = useState(null);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (!open) {
      setUser(null);
    }
  }, [open]);

  const handleSignup = (data) => {
    setUser(data);
    onSignup instanceof Function && onSignup(data);
  };

  if (user) {
    return (
      <Dialog
        fullWidth
        fullScreen={screenSize === 'xs'}
        open={open}
        {...params}
      >
        <DialogContent dividers>
          <SignupComplete {...user} />
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        fullWidth
        fullScreen={screenSize === 'xs'}
        open={open}
        {...params}
      >
        <DialogTitle>Start using My Digital Hand</DialogTitle>

        <DialogContent dividers>
          <SignupForm onSignup={handleSignup} />
        </DialogContent>
      </Dialog>
    );
  }
}
