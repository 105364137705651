import gradient from 'gradient-color';

import newestDate from '../../../data-shapers/newest-date';
import numTicketsPerDate from '../../../data-shapers/num-tickets-per-date';
import oldestDate from '../../../data-shapers/oldest-date';

export default function makeData(tickets) {
  const from = oldestDate(tickets);
  const to = newestDate(tickets);
  const counts = numTicketsPerDate(tickets);
  const data = Object.keys(counts).map((day) => ({ day, value: counts[day] }));
  const colors = makeColors(Math.max(0, ...data.map((x) => x.value)));
  return { data, from, to, colors };
}

function makeColors(numColors) {
  switch (numColors) {
    case 0:
    case 1:
      return ['#d6e3f5'];
    case 2:
      return ['#d6e3f5', '#1c447d'];
    default:
      return gradient(['#d6e3f5', '#1c447d'], Math.min(numColors, 15));
  }
}
