/**
 * Fetches all tickets for the authenticated user in a particular course,
 * paginated and sorted by recent first
 */

import request from 'api/request';

export default function accountTicketIndexRoute(courseId, page = 0, size = 10) {
  return request({
    url: '/tickets/history',
    method: 'GET',
    data: { courseId, page, size },
  });
}
