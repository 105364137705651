import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/DateRange';
import StatisticsIcon from '@material-ui/icons/Equalizer';
// import ChatIcon from '@material-ui/icons/Message';
import SettingsIcon from '@material-ui/icons/Tune';
import HistoryIcon from '@material-ui/icons/History';
import ActivityIcon from '@material-ui/icons/Today';
// import DiscussionIcon from '@material-ui/icons/QuestionAnswerOutlined';
// import LectureIcon from '@material-ui/icons/LibraryBooks';
// import OfficeHoursIcon from '@material-ui/icons/PeopleAlt';

import WaitlistBadgeIcon from './waitlist-badge-icon';
import HandBadgeIcon from './hand-badge-icon';
import DrawerListItemLink from './drawer-list-item-link';

import useCourse from 'selectors/courses/use-course';
import useCourseRole from 'selectors/courses/use-course-role';

export default function CourseNavList({ courseId }) {
  const course = useCourse(courseId);
  const role = useCourseRole(courseId);

  if (!course) {
    return <List />;
  }

  const isInstructor = role === 'instructor';
  const isStaff = role === 'staff';

  return (
    <List component="div" disablePadding>
      <DrawerListItemLink
        url={`/courses/${courseId}`}
        icon={HomeIcon}
        text="Home"
      />

      {/* <DrawerListItemLink
        url={`/courses/${courseId}/office-hours`}
        icon={OfficeHoursIcon}
        text="Office hours"
      /> */}

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/waitlist`}
        icon={WaitlistBadgeIcon}
        text="Waitlist"
        IconProps={{ courseId }}
        isVisible={isInstructor || isStaff}
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/hand`}
        icon={HandBadgeIcon}
        IconProps={{ courseId }}
        text="Get help"
      />

      {/* <DrawerListItemLink
        url={`/courses/${courseId}/lectures`}
        icon={LectureIcon}
        text="Lectures"
      /> */}

      <Collapse
        in={course.scheduleConfig === 'external' && Boolean(course.scheduleUrl)}
      >
        <DrawerListItemLink
          href={course.scheduleUrl}
          target="_blank"
          rel="noopener noreferrer"
          icon={CalendarIcon}
          text="Schedule"
          component="a"
        />
      </Collapse>

      {/* <DrawerListItemLink
        url={`/courses/${courseId}/discussion`}
        icon={DiscussionIcon}
        text="Discussion"
      /> */}

      {/* <DrawerListItemLink
        url={`/courses/${courseId}/chat`}
        icon={ChatIcon}
        text="Chat"
      /> */}

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/history`}
        icon={HistoryIcon}
        text="History"
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/office-hours/activity`}
        icon={ActivityIcon}
        text="Latest activity"
        isVisible={isInstructor || isStaff}
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/statistics`}
        icon={StatisticsIcon}
        text="Statistics"
        isVisible={isInstructor}
      />

      <DrawerListItemLink
        url={`/courses/${courseId}/settings`}
        icon={SettingsIcon}
        text="Settings"
        isVisible={isInstructor}
      />
    </List>
  );
}
