import pluralize from 'pluralize';
import moment from 'moment';

export default function dateDifference(a, b) {
  a = moment(a);
  b = moment(b);
  const duration = moment.duration(b.diff(a));
  //const seconds = duration.seconds();
  const minutes = duration.minutes() + 1;
  const hours = duration.hours();
  const days = duration.days();
  return [
    days > 0 && pluralize('day', days, true),
    hours > 0 && pluralize('hour', hours, true),
    minutes > 0 && pluralize('minute', minutes, true),
    //seconds > 0 && pluralize('second', seconds, true),
  ]
    .filter((x) => x)
    .join(' ');
}
