/**
 * Selects a user by id from the store, fetching it from the server if necessary
 */

import { useSelector, useDispatch } from 'react-redux';
import fetchUser from 'actions/users/fetch';
import getUsers from './get-users';

export default function useUser(id) {
  const dispatch = useDispatch();
  const user = useSelector((store) => {
    if (Number.isInteger(id) && id > 0) {
      return getUsers(store, id);
    } else {
      return null;
    }
  });
  if (Number.isInteger(id) && id > 0 && !user) {
    dispatch(fetchUser(id));
  }
  return user;
}
