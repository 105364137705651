import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import SubmitIcon from '@material-ui/icons/Add';

import createEmailRoute from 'api/routes/emails/create-email';
import LoadingButton from 'components/buttons/loading-button';
import emailRegex from 'utils/email-regex';
import readEmailRoute from 'api/routes/emails/read-email';

export default function AddEmailForm({ onSubmit }) {
  const { register, handleSubmit, formState, errors, setError } = useForm({
    defaultValues: { email: '' },
  });
  const { isSubmitting } = formState;

  const submitForm = useCallback(
    (data) => {
      return createEmailRoute(data.email).then(({ status }) => {
        switch (status) {
          case 200:
            onSubmit instanceof Function && onSubmit(data);
            break;
          default:
            setError('email', 'server');
        }
      });
    },
    [onSubmit, setError]
  );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <TextField
        name="email"
        label="Email address"
        helperText={emailHelperText(errors.email)}
        variant="filled"
        margin="normal"
        fullWidth
        autoFocus
        disabled={isSubmitting}
        error={Boolean(errors.email)}
        inputRef={register({
          required: true,
          pattern: emailRegex,
          validate: {
            taken: (value) =>
              readEmailRoute(value).then(
                ({ status, data }) =>
                  status === 404 || (status === 200 && !data.userId)
              ),
          },
        })}
      />

      <Box mt={1} mb={2} textAlign="right">
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SubmitIcon />}
          loading={isSubmitting}
        >
          Add email
        </LoadingButton>
      </Box>
    </form>
  );
}

function emailHelperText(error) {
  switch (error?.type) {
    case 'required':
      return 'This field is required';
    case 'pattern':
      return 'Invalid email address';
    case 'taken':
      return 'That email address already belongs to an account';
    case 'server':
      return 'A server error occurred and the email address was not added';
    default:
      return 'Add a new email address to your account';
  }
}
