import moment from 'moment';

export default function msToStr(ms) {
  const time = moment.duration(ms);
  const hours = 24 * time.days() + time.hours();
  const minutes = `0${time.minutes()}`.slice(-2);
  const seconds = `0${time.seconds()}`.slice(-2);
  if (hours > 0) {
    return `${hours}:${minutes}:${seconds}`;
  } else {
    return `${time.minutes()}:${seconds}`;
  }
}
