export default function teacherShifts(shifts) {
  const dict = {};
  shifts.forEach((shift) => {
    const teacherId = shift.accountId;
    if (!Number.isInteger(teacherId) || teacherId <= 0) {
      return;
    } else if (teacherId in dict) {
      dict[teacherId].push(shift);
    } else {
      dict[teacherId] = [shift];
    }
  });
  return dict;
}
