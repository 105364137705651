export function ticketFetching(id) {
  return {
    type: 'TICKET_FETCHING',
    payload: id,
  };
}

export function ticketFetched(attrs) {
  return {
    type: 'TICKET_FETCHED',
    payload: attrs,
  };
}

export function ticketCreated(attrs) {
  return {
    type: 'TICKET_CREATED',
    payload: attrs,
  };
}

export function ticketWaiting(attrs) {
  return {
    type: 'TICKET_WAITING',
    payload: attrs,
  };
}

export function ticketCalling(attrs) {
  return {
    type: 'TICKET_CALLING',
    payload: attrs,
  };
}

export function ticketHelping(attrs) {
  return {
    type: 'TICKET_HELPING',
    payload: attrs,
  };
}

export function ticketCompleted(attrs) {
  return {
    type: 'TICKET_COMPLETED',
    payload: attrs,
  };
}

export function ticketCanceled(attrs) {
  return {
    type: 'TICKET_CANCELED',
    payload: attrs,
  };
}

export function ticketJoined(attrs) {
  return {
    type: 'TICKET_JOINED',
    payload: attrs,
  };
}

export function ticketUnjoined(attrs) {
  return {
    type: 'TICKET_UNJOINED',
    payload: attrs,
  };
}

export function feedbackSubmitted(attrs) {
  return {
    type: 'FEEDBACK_SUBMITTED',
    payload: attrs,
  };
}
