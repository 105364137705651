/**
 * Exports a driver function for making requests via https. Uses axios under
 *   the hood.
 *
 * Driver functions have the following signature:
 *    driver({ method, url, data, headers }) => { data, headers, status }
 *
 * See: https://github.com/axios/axios#custom-instance-defaults
 */

import axiosLibrary from 'axios';

// Set base url
const base = process.env.REACT_APP_HOST_URL ?? 'http://localhost:1337';

// Configure ms delay before a request is retried
const retryPeriod = 2000;

// Configure number of attempts before a request is canceled (-1 for infinite)
const numRetries = -1;

// Make and export the underlying custom axios instance
export const axios = axiosLibrary.create({
  baseURL: base,
  withCredentials: true,
});

// Export helper function to make an async request
export default function request({ method = 'get', url, data, headers }) {
  return new Promise((resolve, reject) => {
    const tryRequest = (triesLeft) => {
      axios({ method, url, data, headers })
        .then((response) => {
          const { status, headers, data } = response;
          resolve({ status, headers, data });
        })
        .catch(({ response }) => {
          if (response) {
            const { status, headers, data } = response;
            resolve({ status, headers, data });
          } else if (triesLeft === 0) {
            reject({ status: 0, headers: {}, data: {} });
          } else {
            setTimeout(tryRequest, retryPeriod, triesLeft - 1);
          }
        });
    };

    tryRequest(numRetries);
  });
}
