import React, { useCallback, useEffect, useState } from 'react';
import useTicketsAwaitingFeedback from './use-tickets-awaiting-feedback';
import FeedbackLoader from './feedback-loader';

export default function FeedbackManager() {
  const [tickets, markCompleted] = useTicketsAwaitingFeedback();
  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    if (ticket === null && tickets.length > 0) {
      setTicket(tickets[0]);
    }
  }, [tickets, ticket]);

  const handleCompleted = useCallback(() => {
    markCompleted(ticket);
    setTicket(null);
  }, [markCompleted, ticket]);

  return ticket ? (
    <FeedbackLoader ticket={ticket} onComplete={handleCompleted} />
  ) : null;
}
