import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default forwardRef(Section);

const useStyles = makeStyles((theme) => ({
  section: {
    maxWidth: '600px',
  },
}));

function Section({ title, children, ...props }, ref) {
  const classes = useStyles();
  return (
    <Box
      ref={ref}
      mb={6}
      component="section"
      className={classes.section}
      {...props}
    >
      {title && (
        <Typography variant="h6" paragraph>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
}
