import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
  },
  formControlLabel: {
    flexGrow: 1,
  },
});

export default function MultipleChoiceField({
  options = [],
  selection = -1,
  isDisabled = false,
  isRequired = false,
  isOtherEnabled = false,
  otherValue = '',
  onChange,
}) {
  const classes = useStyles();

  const handleSelectOption = (event) => {
    const newSelection = Number(event.target.value);
    if (onChange instanceof Function) {
      onChange(
        newSelection,
        otherValue,
        isValid(newSelection, otherValue, options.length)
      );
    }
  };

  const handleSelectOther = () => {
    const newSelection = options.length;
    if (onChange instanceof Function) {
      onChange(
        newSelection,
        otherValue,
        isValid(newSelection, otherValue, options.length)
      );
    }
  };

  const handleChangeOther = (event) => {
    const newOtherValue = event.target.value;
    if (onChange instanceof Function) {
      onChange(
        selection,
        newOtherValue,
        isValid(selection, newOtherValue, options.length)
      );
    }
  };

  return (
    <FormControl
      component="fieldset"
      classes={{ root: classes.formControl }}
      disabled={isDisabled}
    >
      <FormLabel component="legend">Select one answer</FormLabel>
      <RadioGroup value={selection} onChange={handleSelectOption}>
        {options.map((label, i) => (
          <FormControlLabel
            key={i}
            value={i}
            control={<Radio />}
            label={label}
          />
        ))}

        {isOtherEnabled && (
          <FormControlLabel
            value={options.length}
            control={<Radio />}
            classes={{ label: classes.formControlLabel }}
            label={
              <TextField
                variant="standard"
                fullWidth
                placeholder="Custom answer"
                value={otherValue}
                color="secondary"
                onChange={handleChangeOther}
                onFocus={handleSelectOther}
              />
            }
          />
        )}
      </RadioGroup>
      {isRequired && (
        <FormHelperText>This question is required.</FormHelperText>
      )}
    </FormControl>
  );
}

function isValid(selection, otherOption, numOptions) {
  return (
    Number.isInteger(selection) &&
    ((0 <= selection && selection < numOptions) ||
      (selection === numOptions && otherOption.length > 2))
  );
}
