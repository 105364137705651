import React from 'react';
import Moment from 'react-moment';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import FollowupIcon from '@material-ui/icons/KeyboardReturn';
import DurationIcon from '@material-ui/icons/Timer';
import WaitingIcon from '@material-ui/icons/HourglassEmpty';

import dateDifference from 'utils/date-difference';
import UserAvatar from 'components/user/user-avatar';
import UserNames from 'components/user/user-names';

import CardPlaceholder from '../ticket-placeholder';
import FeedbackListItem from './feedback-list-item';

export default function CompletedTicket({ ticket, onClick }) {
  if (!ticket) {
    return <CardPlaceholder />;
  }

  const studentIds = ticket.students.map((s) => s.userId);
  const teacherIds = ticket.teachers.map((s) => s.userId);

  return (
    <Box clone mb={2}>
      <Card elevation={3}>
        <CardHeader
          titleTypographyProps={{ variant: 'body1' }}
          avatar={<UserAvatar ids={studentIds} />}
          title={<UserNames ids={studentIds} />}
          subheader={
            <>
              helped by <UserNames ids={teacherIds} />
            </>
          }
          action={
            <Typography variant="body2" color="textSecondary" align="right">
              <Moment format="ddd, MM/DD">{ticket.completedAt}</Moment>
              <br />
              <Moment format="h:mm a">{ticket.completedAt}</Moment>
            </Typography>
          }
        />

        <List disablePadding dense>
          <FeedbackListItem ticket={ticket} />

          <ListItem>
            <ListItemAvatar>
              <WaitingIcon color="primary" />
            </ListItemAvatar>
            <ListItemText
              primary={`Waited ${dateDifference(
                ticket.requestedAt,
                ticket.calledAt ?? ticket.startedAt
              )}`}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <DurationIcon color="primary" />
            </ListItemAvatar>
            <ListItemText
              primary={`Lasted ${dateDifference(
                ticket.startedAt,
                ticket.completedAt
              )}`}
            />
          </ListItem>

          {ticket.isFollowup && (
            <ListItem>
              <ListItemAvatar>
                <FollowupIcon color="primary" />
              </ListItemAvatar>
              <ListItemText primary="This was a follow-up request" />
            </ListItem>
          )}
        </List>
      </Card>
    </Box>
  );
}
