import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import SaveIcon from '@material-ui/icons/Save';
import SuccessIcon from '@material-ui/icons/Check';
import UndoIcon from '@material-ui/icons/Undo';

import LoadingButton from 'components/buttons/loading-button';
import updateCourse from 'api/routes/courses/update-course';
import useCourse from 'selectors/courses/use-course';

const useStyles = makeStyles((theme) => ({
  actions: {
    textAlign: 'right',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  margin: {
    marginLeft: theme.spacing(1),
  },
}));

export default function BasicAttributesForm({ courseId }) {
  const classes = useStyles();
  const course = useCourse(courseId);
  const { register, handleSubmit, reset, formState, errors } = useForm();
  const { dirty, isSubmitting, isSubmitted } = formState;

  useEffect(() => {
    reset(course);
  }, [reset, course]);

  const handleReset = () => {
    reset(course);
  };

  const onSubmit = (data) => {
    return updateCourse({ id: courseId, ...data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Course code"
            variant="outlined"
            margin="normal"
            fullWidth
            name="code"
            error={Boolean(errors.code)}
            helperText="Required"
            disabled={isSubmitting}
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Official title"
            variant="outlined"
            margin="normal"
            fullWidth
            name="title"
            error={Boolean(errors.title)}
            helperText="Required"
            disabled={isSubmitting}
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Semester"
            variant="outlined"
            margin="normal"
            style={{ marginRight: '1em' }}
            fullWidth
            name="semester"
            error={Boolean(errors.semester)}
            helperText="Required"
            disabled={isSubmitting}
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Year"
            variant="outlined"
            margin="normal"
            fullWidth
            type="number"
            name="year"
            error={Boolean(errors.year)}
            helperText="Required"
            disabled={isSubmitting}
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Description"
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            name="description"
            error={Boolean(errors.description)}
            disabled={isSubmitting}
            inputRef={register()}
          />
        </Grid>

        {isSubmitted && !dirty && (
          <Grid item xs={12} container justify="flex-end">
            <Grid item>
              <SuccessIcon color="primary" />
            </Grid>
            <Grid item>
              <Typography
                color="primary"
                variant="subtitle2"
                className={classes.margin}
              >
                Your changes were saved
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} className={classes.actions}>
          <Button
            color="primary"
            disabled={isSubmitting || !dirty}
            type="button"
            onClick={handleReset}
            startIcon={<UndoIcon />}
          >
            Reset
          </Button>

          <LoadingButton
            variant="contained"
            color="primary"
            loading={isSubmitting}
            disabled={!dirty}
            type="submit"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
