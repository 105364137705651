import React from 'react';
import { useParams } from 'react-router-dom';
import useIsAuthenticated from 'selectors/auth/use-is-authenticated';

import AcceptDialog from './accept-dialog';

export default function AcceptPage() {
  const isAuthenticated = useIsAuthenticated();
  const params = useParams();
  const id = Number(params.id);

  return <AcceptDialog open id={id} isAuthenticated={isAuthenticated} />;
}
