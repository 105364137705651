/**
 * Gets "waiting" tickets from the store, optionally filtered for a particular
 * course
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

export const getWaitingTickets = createSelector(orm.Course.tickets, (tickets) =>
  tickets
    .flat()
    .filter((t) => t.status === 'waiting')
    .sort(oldestFirst)
);

export default function useWaitingTickets(courseId) {
  return useSelector((state) => getWaitingTickets(state, courseId));
}

function oldestFirst(a, b) {
  return new Date(a.requestedAt) - new Date(b.requestedAt);
}
