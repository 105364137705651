import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Typography from '@material-ui/core/Typography';
import makeData from './make-data';

export default function TrafficByWeekdayChart({ tickets, isLoading }) {
  const data = useMemo(() => makeData(tickets), [tickets]);

  if (isLoading) {
    return (
      <Typography>
        <em>Loading data...</em>
      </Typography>
    );
  } else if (tickets.length === 0) {
    return (
      <Typography>
        <em>No data to display.</em>
      </Typography>
    );
  }

  return (
    <div style={{ height: '300px' }}>
      <ResponsiveBar
        data={data}
        height={300}
        width={820}
        keys={['canceled', 'completed']}
        colors={['#f50057', '#205092']}
        labelTextColor="#ffffff"
        indexBy="day"
        groupMode="stacked"
        isInteractive={false}
        labelSkipHeight={20}
        margin={{ top: 50, right: 160, bottom: 50, left: 50 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Number of interactions',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 110,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 5,
            symbolSize: 20,
            itemDirection: 'left-to-right',
          },
        ]}
      />
    </div>
  );
}
