import React, { useCallback } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import CancelIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/AssignmentTurnedIn';
import LaunchIcon from '@material-ui/icons/Launch';
import NotificationIcon from '@material-ui/icons/Notifications';
import ReplayIcon from '@material-ui/icons/Replay';
import VirtualIcon from '@material-ui/icons/Computer';

import createFollowupTicketRoute from 'api/routes/tickets/create-followup-ticket';
import callTicketRoute from 'api/routes/tickets/call-ticket';
import openTicketRoute from 'api/routes/tickets/open-ticket';
import closeTicketRoute from 'api/routes/tickets/close-ticket';
import cancelTicketRoute from 'api/routes/tickets/cancel-ticket';
import Button from 'components/buttons/async-button';

export function CancelTicketButton({ id, onClick, ...props }) {
  const handleClick = useCallback(
    (e) => {
      onClick instanceof Function && onClick(e);
      return cancelTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <Tooltip title="Cancel the ticket" placement="top">
      <Button
        color="secondary"
        startIcon={<CancelIcon />}
        onClick={handleClick}
        {...props}
      >
        Cancel ticket
      </Button>
    </Tooltip>
  );
}

export function CallStudentButton({ id, onClick, ...props }) {
  const handleClick = useCallback(
    (e) => {
      onClick instanceof Function && onClick(e);
      return callTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <Tooltip title="Alert the student to come find you" placement="top">
      <Button
        color="primary"
        startIcon={<NotificationIcon />}
        onClick={handleClick}
        {...props}
      >
        Call student in
      </Button>
    </Tooltip>
  );
}

export function OpenTicketButton({ id, onClick, ...props }) {
  const handleClick = useCallback(
    (e) => {
      onClick instanceof Function && onClick(e);
      return openTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <Tooltip title="Immediately start helping the student" placement="top">
      <Button
        color="primary"
        startIcon={<LaunchIcon />}
        onClick={handleClick}
        {...props}
      >
        Student is here
      </Button>
    </Tooltip>
  );
}

export function MarkCompletedButton({ id, onClick, ...props }) {
  const handleClick = useCallback(
    (e) => {
      onClick instanceof Function && onClick(e);
      return closeTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <Tooltip title="Done helping this student" placement="top">
      <Button
        color="primary"
        startIcon={<DoneIcon />}
        onClick={handleClick}
        {...props}
      >
        Done
      </Button>
    </Tooltip>
  );
}

export function FollowupButton({ id, onClick, ...props }) {
  const handleClick = useCallback(
    (e) => {
      onClick instanceof Function && onClick(e);
      return createFollowupTicketRoute(id);
    },
    [id, onClick]
  );

  return (
    <Tooltip
      title="Done for now, but re-add the student to the waitlist"
      placement="top"
    >
      <Button
        color="primary"
        startIcon={<ReplayIcon />}
        onClick={handleClick}
        {...props}
      >
        Done for now
      </Button>
    </Tooltip>
  );
}

export function VirtualHelpButton({ id, onClick, ...props }) {
  const handleClick = useCallback(
    (e) => {
      onClick instanceof Function && onClick(e);
      // TODO
    },
    [onClick /* , id */]
  );

  return (
    <Tooltip
      title="Start virtual office hours with the student"
      placement="top"
    >
      <Button
        color="primary"
        startIcon={<VirtualIcon />}
        onClick={handleClick}
        {...props}
      >
        Virtual help
      </Button>
    </Tooltip>
  );
}
