/**
 * This reducer handles all dispatched state change actions by delegating to
 *   more specialized reducers
 */

import { combineReducers } from 'redux';

import authReducer from './auth-reducer';
import socketReducer from './socket-reducer';
import ormReducer from './orm-reducer';

export default combineReducers({
  auth: authReducer,
  socket: socketReducer,
  orm: ormReducer,
});
