import { useState, useEffect } from 'react';
import useStaff from 'selectors/courses/use-staff';

export default function useIsStaffOnDuty(courseId) {
  const staff = useStaff(courseId);

  const lastShiftEndTimestamp = Math.max(
    ...staff
      .map((u) => u?.shift?.endAt)
      .filter((x) => x)
      .map((d) => new Date(d).getTime())
  );

  const [isStaffOnDuty, setIsStaffOnDuty] = useState(
    lastShiftEndTimestamp > Date.now()
  );

  useEffect(() => {
    const msUntilLastShiftEnds = lastShiftEndTimestamp - Date.now();
    setIsStaffOnDuty(msUntilLastShiftEnds > 0);
    if (msUntilLastShiftEnds > 0) {
      const timer = setTimeout(() => {
        setIsStaffOnDuty(false);
      }, msUntilLastShiftEnds);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [lastShiftEndTimestamp]);

  return isStaffOnDuty;
}
