/**
 * Gets only "closed" tickets from the store for which the authenticated user
 *   was a participant, optionally filtered for a particular course
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getAccount } from 'selectors/auth/use-account';
import { getRecentTickets } from './use-recent-tickets';

export const getMyRecentTickets = createSelector(
  getRecentTickets,
  getAccount,
  (tickets, account) =>
    tickets.filter(
      (t) =>
        t.teachers.some((u) => u.accountId === account.id) ||
        t.students.some((u) => u.accountId === account.id)
    )
);

export default function useMyRecentTickets(courseId) {
  return useSelector((state) => getMyRecentTickets(state, courseId));
}
