import React from 'react';
import Typography from '@material-ui/core/Typography';
import Section from 'components/content/section';
import TimerForm from './timer-form';

export default function TimerSection({ courseId }) {
  return (
    <Section title="Shorten interactions">
      <Typography paragraph>
        By deliberately shortening office hours interactions between students
        and staff, My Digital Hand can help students get the instruction they
        need, while also encouraging them to work independently when they can.
      </Typography>

      <Typography paragraph>
        If this setting is enabled, any time an interaction exceeds a
        configurable time limit, the staff member will be asked to finish by
        suggesting next steps so the student can keep working on their own.
      </Typography>

      <Typography paragraph>
        This frees the staff member to move on to the next student, and gives
        the student an opportunity to try out what they learned.
      </Typography>

      <TimerForm courseId={courseId} />
    </Section>
  );
}
