import React from 'react';

import Typography from '@material-ui/core/Typography';

import DropdownSelect from 'components/inputs/dropdown-select';
import Section from 'components/content/section';
import useCourse from 'selectors/courses/use-course';
import updateCourse from 'api/routes/courses/update-course';

const options = [
  {
    value: 'external',
    text: 'Links to an external calendar',
  },
  {
    value: 'disabled',
    text: 'Is disabled and hidden',
  },
];

export default function ScheduleEnabledSection({ courseId }) {
  const course = useCourse(courseId);

  const handleChange = (scheduleConfig) => {
    return updateCourse({ id: courseId, scheduleConfig });
  };

  if (!course) {
    return null;
  }

  return (
    <Section title="Office hours schedule">
      <Typography paragraph>
        In most courses, staff members need a way to share the times and
        locations of their office hours with students. Coming soon, My Digital
        Hand will offer a built-in office hours scheduling feature.
      </Typography>

      <Typography paragraph>
        For now, an external calendar (such as a{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.google.com/calendar/answer/37083?hl=en"
        >
          public Google Calendar
        </a>
        ) can optionally be linked by URL. When enabled, this feature adds a
        schedule link to the course menu of My Digital Hand. The menu item will
        open your configured URL in a new tab.
      </Typography>

      <Typography paragraph component="div">
        <DropdownSelect
          options={options}
          value={course.scheduleConfig}
          title="The office hours schedule"
          onChange={handleChange}
        />
      </Typography>
    </Section>
  );
}
