/**
 * Fetches the latest message from each chat message thread involving the
 * authenticated user
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import threadIndexRoute from 'api/routes/chats/thread-index';
import loadRecords from 'actions/load-records';

export default function loadThreadIndex() {
  return (dispatch) => {
    return threadIndexRoute().then(({ data, status }) => {
      if (status === 200) {
        dispatch(loadRecords(data));
      }
    });
  };
}
