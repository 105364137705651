import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';

import sendResetEmailRoute from 'api/routes/passwords/send-reset-email';
import LoadingButton from 'components/buttons/loading-button';
import emailRegex from 'utils/email-regex';

export default function RecoverForm({ onSubmit }) {
  const { register, handleSubmit, formState, errors, setError } = useForm();
  const { isSubmitting } = formState;

  const submitForm = useCallback(
    ({ email }) => {
      return sendResetEmailRoute(email).then(({ status }) => {
        switch (status) {
          case 200:
            onSubmit instanceof Function && onSubmit(email);
            break;
          case 404:
            setError('email', 'not found');
            break;
          default:
            setError('email', 'unknown');
        }
      });
    },
    [onSubmit, setError]
  );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <TextField
        name="email"
        label="Email"
        variant="outlined"
        margin="normal"
        fullWidth
        autoFocus
        disabled={isSubmitting}
        error={Boolean(errors.email)}
        helperText={emailHelperText(errors)}
        inputRef={register({ required: true, pattern: emailRegex })}
      />

      <Box my={2}>
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item>
            <Button color="primary" component={Link} to="/signin">
              Back to sign in
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SendIcon />}
              loading={isSubmitting}
            >
              Recover account
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

function emailHelperText(errors) {
  switch (errors.email?.type) {
    case 'required':
      return 'Required field';
    case 'pattern':
      return 'Not a valid email address';
    case 'not found':
      return 'This email address is not associated with an active account';
    case 'unknown':
      return 'An unknown server error occurred';
    default:
      return 'Your primary account email address';
  }
}
