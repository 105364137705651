import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import PrimaryIcon from '@material-ui/icons/GpsNotFixed';
import ResendIcon from '@material-ui/icons/MailOutline';

import makePrimaryEmailRoute from 'api/routes/emails/make-primary-email';
import resendConfirmationRoute from 'api/routes/emails/resend-confirmation';

// NOTE: Cannot use hooks in this component (material-table limitation)
export default function ActionsColumn({ status, isPrimary, address }) {
  const handleResend = () => {
    return resendConfirmationRoute(address).then(({ status }) => {
      if (status === 200) {
        alert(`Confirmation email resent to ${address}.`);
      }
    });
  };

  const handlePrimary = () => {
    return makePrimaryEmailRoute(address);
  };

  return (
    <Grid container spacing={1}>
      {!isPrimary && status === 'confirmed' && (
        <Grid item>
          <Tooltip title="Make primary">
            <IconButton onClick={handlePrimary}>
              <PrimaryIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {status === 'unconfirmed' && (
        <Grid item>
          <Tooltip title="Resend confirmation email">
            <IconButton onClick={handleResend}>
              <ResendIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}
