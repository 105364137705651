import request from 'api/request';

export default function updateQuestionRoute({
  courseId,
  field,
  index,
  ...data
}) {
  return request({
    url: `/courses/${courseId}/${field}/${index}`,
    method: 'PUT',
    data,
  });
}
