export default function teacherTickets(tickets) {
  const dict = {};
  tickets.forEach((ticket) => {
    const teacherId = ticket.teacherAccountId;
    if (!Number.isInteger(teacherId) || teacherId <= 0) {
      return;
    } else if (teacherId in dict) {
      dict[teacherId].push(ticket);
    } else {
      dict[teacherId] = [ticket];
    }
  });
  return dict;
}
