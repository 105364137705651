import React from 'react';
import QRCode from 'qrcode.react';
import pluralize from 'pluralize';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import capitalize from 'utils/capitalize';
import Section from 'components/content/section';

export default function QrCodeSection({ role, codes }) {
  const enrollCode = codes[`${role}EnrollCode`];
  const canEnrollByCode =
    codes[`can${pluralize(capitalize(role))}EnrollByCode`];

  return (
    <Section title={`Enroll ${pluralize(role)} by QR code`} m={2}>
      <Collapse in={!canEnrollByCode}>
        <Alert severity="error">
          The QR code method for {role} enrollment is currently disabled for
          this course. It can be enabled below.
        </Alert>
      </Collapse>

      <Collapse in={canEnrollByCode}>
        <Typography paragraph>
          The easiest way to invite {pluralize(role)} to your My Digital Hand
          course is by sharing this QR code with them.
        </Typography>

        <Box textAlign="center" my={3}>
          <QRCode
            value={`${window.location.origin}/enroll/${enrollCode}`}
            size={180}
          />
        </Box>

        <Typography paragraph>
          Anyone who scans this QR code will be automatically directed to{' '}
          <a href={window.location.origin}>the My Digital Hand website</a> and
          authorized to join your course as a{' '}
          {role === 'staff' ? 'staff member' : role}.
        </Typography>

        {role === 'student' && (
          <Typography paragraph>
            Consider copying this image in your lecture slides or printing and
            posting it at your designated office hours location.
          </Typography>
        )}
      </Collapse>
    </Section>
  );
}
