import React, { useState, useCallback } from 'react';
import pluralize from 'pluralize';
import Typography from '@material-ui/core/Typography';
import Section from 'components/content/section';
import TicketCard from 'components/tickets/ticket';
import useWaitingTickets from 'selectors/tickets/use-waiting-tickets';
import DetailsDialog from './details-dialog/details-dialog';

export default function WaitingSection({ courseId }) {
  const [selection, setSelection] = useState(null);
  const tickets = useWaitingTickets(courseId);
  tickets.sort(oldestFirst);

  const handleClose = useCallback(() => {
    setSelection(null);
  }, []);

  if (tickets.length > 0) {
    return (
      <Section title={pluralize('waiting student', tickets.length, true)}>
        {tickets.map((t) => (
          <TicketCard key={t.id} ticket={t} onClick={setSelection} />
        ))}

        {Number.isInteger(selection) && (
          <DetailsDialog open ticketId={selection} onClose={handleClose} />
        )}
      </Section>
    );
  } else {
    return (
      <Section title="Waiting students">
        <Typography paragraph>
          Great job! The office hours waitlist is empty.
        </Typography>
      </Section>
    );
  }
}

function oldestFirst(t1, t2) {
  return (
    new Date(t1.requestedAt).getTime() - new Date(t2.requestedAt).getTime()
  );
}
