/**
 * Gets "waiting" student ids from the store, optionally filtered for a
 * particular course
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

export const getWaitingStudentIds = createSelector(
  orm.Course.tickets,
  (tickets) =>
    tickets
      .flat()
      .filter((t) => t.status === 'waiting')
      .sort(oldestFirst)
      .map((t) => t.students[0])
      .filter((p) => p)
      .map((p) => p.userId)
);

export default function useWaitingStudentIds(courseId) {
  return useSelector((state) => getWaitingStudentIds(state, courseId));
}

function oldestFirst(a, b) {
  return new Date(a.requestedAt) - new Date(b.requestedAt);
}
