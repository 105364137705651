import React, { useCallback } from 'react';
import ExitIcon from '@material-ui/icons/ExitToApp';
import ConfirmDialog from 'components/dialogs/confirm';
import deleteShiftRoute from 'api/routes/shifts/delete-shift';

export default function ShiftEndButton({ shiftId }) {
  const handleClick = useCallback(() => {
    return deleteShiftRoute(shiftId);
  }, [shiftId]);

  return (
    <ConfirmDialog
      title="End your shift?"
      content="This will end your shift and let students know that you're no longer available at office hours."
      buttonProps={{
        color: 'secondary',
        variant: 'contained',
        startIcon: <ExitIcon />,
      }}
      buttonText="End now"
      acceptText="End shift now"
      acceptProps={{ color: 'secondary', variant: 'contained' }}
      rejectText="Keep helping"
      rejectProps={{}}
      onConfirm={handleClick}
    />
  );
}
