import React from 'react';
import { useForm } from 'react-hook-form';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import SubmitIcon from '@material-ui/icons/Done';
import { CircularProgress } from '@material-ui/core';

import joinCourse from 'api/routes/courses/join-course';
import emailRegex from 'utils/email-regex';

export default function JoinByEmailForm({ courseId, domain, onSubmit }) {
  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: 'onChange',
  });
  const { dirty, isSubmitting } = formState;

  const handleJoin = (data) => {
    return joinCourse({ id: courseId, email: data.email }).then(
      ({ status, data: user }) => {
        switch (status) {
          case 200:
            onSubmit instanceof Function &&
              onSubmit({
                status: user.status,
                email: data.email,
                role: user.role,
              });
            break;
          case 409:
            setError('email', 'disallowed');
            break;
          case 403:
            setError('email', 'taken');
            break;
          default:
            setError('email', 'unknown');
        }
      }
    );
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton variant="contained" type="submit">
        {isSubmitting ? (
          <CircularProgress size="1em" color="inherit" />
        ) : (
          <SubmitIcon color="primary" />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <form onSubmit={handleSubmit(handleJoin)}>
      <TextField
        label="Email address"
        variant="outlined"
        margin="normal"
        fullWidth
        autoFocus
        name="email"
        error={Boolean(errors.email)}
        helperText={helperText(errors, domain)}
        InputProps={dirty && !errors.email ? { endAdornment } : undefined}
        disabled={isSubmitting}
        inputRef={register({
          required: true,
          pattern: emailRegex,
          validate: { ending: (value) => value?.endsWith(domain) },
        })}
      />
    </form>
  );
}

function helperText(errors, domain) {
  switch (errors.email?.type) {
    case 'taken':
      return 'That email address belongs to a different account';
    case 'disallowed':
      return 'You are not allowed to join this course right now';
    case 'unknown':
      return 'Something went wrong and you were not enrolled';
    case 'pattern':
      return 'Not a valid email address';
    case 'ending':
    default:
      return (
        <>
          Email address must end in <strong>{domain}</strong>
        </>
      );
  }
}
