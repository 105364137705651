import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function QuestionPrompt({ question, answer }) {
  return (
    <>
      <Typography variant="body2" color="textSecondary">
        {question.prompt}
      </Typography>
      <AnswerText question={question} answer={answer} />
    </>
  );
}

function AnswerText({ question, answer }) {
  switch (question.type) {
    case 'multiple-choice':
      if (Number.isInteger(answer.selection)) {
        return (
          <Typography variant="body1" paragraph>
            {answer.selection < question.options.length
              ? question.options[answer.selection]
              : answer.otherValue}
          </Typography>
        );
      }
      break;
    case 'select-all':
      if (answer.selections.length > 0) {
        return answer.selections.map((i, j) =>
          i < question.options.length ? (
            <Typography
              variant="body1"
              paragraph={j + 1 === answer.selections.length}
              key={question.options[i]}
            >
              {question.options[i]}
            </Typography>
          ) : (
            <Typography variant="body1" key={answer.otherValue}>
              {answer.otherValue}
            </Typography>
          )
        );
      }
      break;
    case 'short-answer':
      return (
        <Typography variant="body1" paragraph>
          {answer}
        </Typography>
      );
    case 'free-response':
      return (
        <Typography variant="body1" paragraph component="pre">
          {answer}
        </Typography>
      );
    default:
      return null;
  }
  return null;
}
