import React from 'react';

import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ShortText from '@material-ui/icons/ShortText';
import Subject from '@material-ui/icons/Subject';

export default function QuestionTypeIcon({ type, isFilled = false, ...props }) {
  switch (type) {
    case 'short-answer':
      return <ShortText {...props} />;
    case 'free-response':
      return <Subject {...props} />;
    case 'multiple-choice':
      if (isFilled) {
        return <RadioButtonChecked {...props} />;
      } else {
        return <RadioButtonUnchecked {...props} />;
      }
    case 'select-all':
      if (isFilled) {
        return <CheckBox {...props} />;
      } else {
        return <CheckBoxOutlineBlank {...props} />;
      }
    default:
      return null;
  }
}
