/**
 * Fetches all active tickets (i.e. status is "waiting", "calling", or
 * "helping") for all active courses in which the authenticated user is enrolled
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import loadRecords from 'actions/load-records';
import activeTicketIndexRoute from 'api/routes/tickets/active-ticket-index';

export default function fetchActiveTickets() {
  return (dispatch) => {
    return activeTicketIndexRoute().then(({ status, headers, data }) => {
      if (status === 200) {
        dispatch(loadRecords(data));
      }
      return { status, headers, data };
    });
  };
}
