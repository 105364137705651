import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

import useCourse from 'selectors/courses/use-course';
import EditQuestionCard from './questions/edit-question-card';
import ViewQuestionCard from './questions/view-question-card';

import createQuestionRoute from 'api/routes/questions/create-question';
import updateQuestionRoute from 'api/routes/questions/update-question';
import destroyQuestionRoute from 'api/routes/questions/destroy-question';
import moveQuestionDownRoute from 'api/routes/questions/move-question-down';
import moveQuestionUpRoute from 'api/routes/questions/move-question-up';

export default function EditableQuestionsList({ courseId, field }) {
  const course = useCourse(courseId);
  const [questionIndex, setQuestionIndex] = useState(null);

  const handleEdit = (i) => () => {
    setQuestionIndex(i);
  };

  const handleSave = async (data) => {
    setQuestionIndex(null);
    await updateQuestionRoute({
      courseId,
      field: field,
      index: questionIndex,
      ...data,
    });
  };

  const handleDelete = async () => {
    setQuestionIndex(null);
    await destroyQuestionRoute({
      courseId,
      field: field,
      index: questionIndex,
    });
  };

  const handleMoveUp = async () => {
    setQuestionIndex((i) => i - 1);
    return moveQuestionUpRoute({
      courseId,
      field: field,
      index: questionIndex,
    });
  };

  const handleMoveDown = async () => {
    setQuestionIndex((i) => i + 1);
    return moveQuestionDownRoute({
      courseId,
      field: field,
      index: questionIndex,
    });
  };

  const handleAddQuestion = async () => {
    setQuestionIndex(course[field].length);
    return createQuestionRoute({ courseId, field: field });
  };

  if (!course) {
    return null;
  }

  const questions = course[field];

  if (!Array.isArray(questions)) {
    return null;
  }

  const Content = () => {
    if (questions.length > 0) {
      return questions.map((question, i) => {
        if (i === questionIndex) {
          return (
            <EditQuestionCard
              key={i}
              question={question}
              questionIndex={i}
              numQuestions={questions.length}
              onSave={handleSave}
              onMoveUp={handleMoveUp}
              onMoveDown={handleMoveDown}
              onDelete={handleDelete}
              isAutocompleteAnOption={field === 'prompts'}
            />
          );
        } else {
          return (
            <ViewQuestionCard
              key={i}
              question={question}
              questionIndex={i}
              onClick={handleEdit(i)}
            />
          );
        }
      });
    } else {
      return (
        <Box textAlign="center">
          <Typography variant="h6" color="textSecondary">
            No questions
          </Typography>
          <Typography paragraph color="textSecondary">
            Click the add button below to add a question
          </Typography>
        </Box>
      );
    }
  };

  return (
    <>
      <Content />

      <AddQuestionFab onClick={handleAddQuestion} />
    </>
  );
}

function AddQuestionFab(props) {
  return (
    <Box textAlign="right" mt={3}>
      <Tooltip title="Add question" placement="left">
        <Fab color="primary" {...props}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
}
