import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const options = ['names', 'position', 'total', 'none'];
const optionText = {
  names:
    'The complete waitlist, including the names and positions of waiting students',
  position:
    'Their own position on the waitlist and how many other students are waiting',
  total: 'How many students are waiting for help',
  none: 'No information about waiting students',
};

export default function QueuePolicySelect({ value, onChange }) {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleSelectItem = (selectedValue) => () => {
    onChange instanceof Function && onChange(selectedValue);
    setMenuAnchor(null);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <List component="nav">
        <ListItem button onClick={handleOpenMenu}>
          <ListItemText
            primary="Students can see"
            secondary={optionText[value]}
          />
        </ListItem>
      </List>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={value === option}
            onClick={handleSelectItem(option)}
          >
            {optionText[option]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
