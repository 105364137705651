// import React from 'react';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import RecommendedIcon from '@material-ui/icons/Star';

export default function RecommendedListItem({ ticket, role }) {
  return null;
  // return (
  //   <ListItem>
  //     <ListItemIcon>
  //       <RecommendedIcon color="primary" />
  //     </ListItemIcon>
  //     <ListItemText
  //       primary="Recommended"
  //       secondary={`You've helped this student before`}
  //     />
  //   </ListItem>
  // );
}
