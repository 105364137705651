import request from 'api/request';

export default function updateUserRoute({
  courseId,
  userId,
  first,
  last,
  group,
  identifier,
  role,
}) {
  return request({
    url: `/courses/${courseId}/users/${userId}`,
    method: 'PUT',
    data: { first, last, group, identifier, role },
  });
}
