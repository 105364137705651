import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContinueIcon from '@material-ui/icons/NavigateNext';

import useScreenSize from 'hooks/use-screen-size';
import ResetForm from './reset-form';
import useQueryParams from 'hooks/use-query-params';

export default function ResetDialog(params) {
  const { token } = useQueryParams();
  const [isFinished, setIsFinished] = useState(false);
  const screenSize = useScreenSize();
  const isFullScreen = screenSize === 'xs';

  const handleSubmit = useCallback(() => {
    setIsFinished(true);
  }, []);

  if (isFinished) {
    return (
      <Dialog fullWidth fullScreen={isFullScreen} {...params}>
        <DialogTitle>Success</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Your password was successfully reset.
          </DialogContentText>

          <DialogContentText>
            You may now sign in with your new account password.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            component={Link}
            to="/signin"
            color="primary"
            variant="contained"
            endIcon={<ContinueIcon />}
          >
            Continue to sign in
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog fullWidth fullScreen={isFullScreen} {...params}>
        <DialogTitle>Reset password</DialogTitle>

        <DialogContent>
          <DialogContentText>
            You are about to reset your My Digital Hand account password.
          </DialogContentText>

          <DialogContentText>
            Enter your desired new account password below.
          </DialogContentText>

          <ResetForm onSubmit={handleSubmit} token={token} />
        </DialogContent>
      </Dialog>
    );
  }
}
