import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import NavBar from 'components/app-bar/app-bar';
import capitalize from 'utils/capitalize';
import fetchRoster from 'api/routes/statistics/fetch-roster';

import RosterTable from './table/table';
import EditUserDialog from './edit-user-dialog/dialog';
import DeleteUserDialog from './delete-user-dialog/dialog';
import InviteDialog from './invite-dialog/dialog';

export default function RosterPage() {
  const params = useParams();
  const courseId = Number(params.courseId);
  const [tab, setTab] = useState('student');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogData, setDialogData] = useState(null);
  const [dialog, setDialog] = useState(null);

  const usersByRole = useMemo(
    () => ({
      student: users.filter((u) => u.role === 'student'),
      staff: users.filter((u) => u.role === 'staff'),
      instructor: users.filter((u) => u.role === 'instructor'),
    }),
    [users]
  );

  useEffect(() => {
    setIsLoading(true);
  }, [courseId]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    let isActive = true;
    fetchRoster(courseId)
      .then(({ data, status }) => {
        if (isActive && status === 200) {
          setUsers(data);
        }
      })
      .finally(() => {
        if (isActive) {
          setIsLoading(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [isLoading, courseId]);

  const handleCloseDialog = useCallback(() => {
    setDialog(null);
    setDialogData(null);
  }, []);

  const handleReload = useCallback(() => {
    setDialog(null);
    setDialogData(null);
    setIsLoading(true);
  }, []);

  const handleClickAddUser = useCallback(() => {
    setDialog('add-user');
  }, []);

  const handleClickEditUser = useCallback((userData) => {
    setDialogData(userData);
    setDialog('edit-user');
  }, []);

  const handleClickDeleteUser = useCallback(() => {
    setDialog('delete-user');
  }, []);

  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  const handleClickCancelDelete = useCallback(() => {
    setDialog('edit-user');
  }, []);

  return (
    <>
      <NavBar
        title={`${capitalize(tab)} roster`}
        back={`/courses/${courseId}/settings`}
      />

      <Box m={2}>
        <Paper>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Students" value="student" />
            <Tab label="Staff" value="staff" />
            <Tab label="Instructors" value="instructor" />
          </Tabs>

          <Box m={2}>
            <RosterTable
              courseId={courseId}
              data={usersByRole[tab]}
              onClickAddUser={handleClickAddUser}
              onClickEditUser={handleClickEditUser}
              isLoading={isLoading}
              title={`${capitalize(tab)} roster`}
            />
          </Box>
        </Paper>
      </Box>

      <InviteDialog
        open={dialog === 'add-user'}
        onClose={handleCloseDialog}
        onComplete={handleReload}
        role={tab}
        courseId={courseId}
      />

      <EditUserDialog
        open={dialog === 'edit-user'}
        onClose={handleCloseDialog}
        onComplete={handleReload}
        onClickDelete={handleClickDeleteUser}
        fields={dialogData}
      />

      <DeleteUserDialog
        open={dialog === 'delete-user'}
        onClose={handleCloseDialog}
        onComplete={handleReload}
        onCancel={handleClickCancelDelete}
        userId={dialogData?.id}
        userName={
          dialogData?.first || dialogData?.last
            ? `${dialogData?.first ?? ''} ${dialogData?.last ?? ''}`
            : undefined
        }
        courseId={courseId}
        fullWidth
      />
    </>
  );
}
