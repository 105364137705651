import React, { useState, useEffect } from 'react';
import pluralize from 'pluralize';

import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import useDebounced from 'hooks/use-debounced';
import useCourse from 'selectors/courses/use-course';
import updateCourse from 'api/routes/courses/update-course';

export default function TimeoutForm({ courseId }) {
  const course = useCourse(courseId);
  const [actionValue, setActionValue] = useState('enabled');
  const [durationValue, setDurationValue] = useState(10);
  const debouncedUpdate = useDebounced(updateCourse, 500);

  useEffect(() => {
    if (course) {
      setActionValue(course.timeoutAction);
      setDurationValue(course.timeoutDuration);
    }
  }, [course]);

  const onTimerChange = (event) => {
    const { value } = event.target;
    if (value > 0) {
      setDurationValue(value);
      return debouncedUpdate({ id: courseId, timeoutDuration: value });
    }
  };

  const onActionChange = () => {
    const newActionValue = actionValue === 'enabled' ? 'disabled' : 'enabled';
    setActionValue(newActionValue);
    return debouncedUpdate({ id: courseId, timeoutAction: newActionValue });
  };

  if (!course) {
    return null;
  }

  return (
    <>
      <FormControlLabel
        label={
          <>
            The interaction timeout is{' '}
            <strong>
              {actionValue === 'enabled' ? 'enabled' : 'disabled'}
            </strong>
          </>
        }
        control={
          <Switch
            checked={actionValue === 'enabled'}
            onChange={onActionChange}
            color="primary"
          />
        }
      />

      <Collapse in={actionValue !== 'disabled'}>
        <TextField
          label="Timeout duration"
          type="number"
          variant="outlined"
          margin="normal"
          helperText="Changes are saved automatically"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {pluralize('minute', durationValue)}
              </InputAdornment>
            ),
          }}
          value={durationValue}
          error={durationValue <= 0}
          onChange={onTimerChange}
        />
      </Collapse>
    </>
  );
}
