import React, { useState } from 'react';
import DropdownSelect from 'components/inputs/dropdown-select';
import Section from 'components/content/section';
import StudentPromptsSection from './survey-answer-freq/student-prompts-section';
import StudentFeedbackSection from './survey-answer-freq/student-feedback-section';
import TeacherFeedbackSection from './survey-answer-freq/teacher-feedback-section';

const options = [
  {
    value: 'studentPrompts',
    text: 'Pre-interaction student prompts',
  },
  {
    value: 'studentFeedback',
    text: 'Post-interaction student feedback',
  },
  {
    value: 'teacherFeedback',
    text: 'Post-interaction staff feedback',
  },
];

export default function SurveyTab({ tickets, isLoading }) {
  const [survey, setSurvey] = useState('studentPrompts');

  return (
    <>
      <Section title="Select survey to display">
        <DropdownSelect
          value={survey}
          onChange={setSurvey}
          title="Currently displaying"
          options={options}
        />
      </Section>

      {survey === 'studentPrompts' && (
        <StudentPromptsSection tickets={tickets} isLoading={isLoading} />
      )}

      {survey === 'studentFeedback' && (
        <StudentFeedbackSection tickets={tickets} isLoading={isLoading} />
      )}

      {survey === 'teacherFeedback' && (
        <TeacherFeedbackSection tickets={tickets} isLoading={isLoading} />
      )}
    </>
  );
}
