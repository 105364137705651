import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import LoadingButton from 'components/buttons/loading-button';

export default function ConfirmDialog({
  title,
  content,
  body,
  buttonProps = {},
  buttonText = 'Confirm',
  acceptText = 'Agree',
  acceptProps = {},
  rejectText = 'Disagree',
  rejectProps = {},
  onOpen,
  onConfirm,
  onReject,
  children,
}) {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleClickAccept = () => {
    setIsSubmitting(true);
  };

  const handleClickReject = () => {
    setOpen(false);
    onReject && onReject();
  };

  useEffect(() => {
    if (!isSubmitting) {
      return;
    } else if (!(onConfirm instanceof Function)) {
      setOpen(false);
      setIsSubmitting(false);
      return;
    }
    const promise = onConfirm();
    if (promise instanceof Promise) {
      setIsSubmitting(true);
      let isActive = true;
      promise
        .then(() => {
          if (isActive) {
            setOpen(false);
          }
        })
        .finally(() => {
          if (isActive) {
            setIsSubmitting(false);
          }
        });
      return () => {
        isActive = false;
      };
    } else {
      setOpen(false);
      setIsSubmitting(false);
    }
  }, [isSubmitting, onConfirm]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        children={buttonText}
        {...buttonProps}
      />

      <Dialog open={open} onClose={handleClickReject} fullWidth>
        <DialogTitle disableTypography>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          {content && <DialogContentText children={content} />}
          {body || children}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickReject}
            color="primary"
            disabled={isSubmitting}
            {...rejectProps}
          >
            {rejectText}
          </Button>

          <LoadingButton
            onClick={handleClickAccept}
            color="primary"
            loading={isSubmitting}
            autoFocus
            {...acceptProps}
          >
            {acceptText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
