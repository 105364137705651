import React from 'react';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';

const useRippleStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: ({ color }) => color,
    color: ({ color }) => color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '50%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const useNormalStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: ({ color }) => color,
    color: ({ color }) => color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

const colors = {
  online: '#44b700',
  idle: '#44b700',
  busy: '#e0e000',
  offline: '#c0c0c0',
};

export default function StatusBadge({
  overlap = 'circle',
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  variant = 'dot',
  component: Component = Badge,
  hasRipple = true,
  status = '',
  ...props
}) {
  const rippleClasses = useRippleStyles({ color: colors[status] });
  const normalClasses = useNormalStyles({ color: colors[status] });
  const classes = hasRipple ? rippleClasses : normalClasses;

  return (
    <Component
      overlap={overlap}
      anchorOrigin={anchorOrigin}
      variant={variant}
      classes={classes}
      {...props}
    />
  );
}
