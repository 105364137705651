/**
 * Gets only "helping" tickets from the store for which the authenticated
 *   user is an active teacher, optionally filtered for a particular course
 */

import { useSelector } from 'react-redux';
import { createSelector as createOrmSelector } from 'redux-orm';
import { createSelector } from 'reselect';
import { getAccount } from 'selectors/auth/use-account';
import orm from 'models/orm';

export const getHelpingTickets = createOrmSelector(
  orm.Course.tickets,
  (tickets) =>
    tickets
      .flat()
      .filter((t) => t.status === 'helping')
      .sort(oldestFirst)
);

export const getMyHelpingTickets = createSelector(
  getHelpingTickets,
  getAccount,
  (tickets, account) =>
    tickets.filter((t) =>
      t.teachers.some(
        (p) => p.accountId === account.id && p.status === 'active'
      )
    )
);

export default function useMyHelpingTickets(courseId) {
  return useSelector((store) => getMyHelpingTickets(store, courseId));
}

function oldestFirst(a, b) {
  return new Date(a.startedAt) - new Date(b.startedAt);
}
