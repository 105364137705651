/**
 * This reducer handles dispatched state change actions that affect the
 *   connection state object
 */

const INITIAL_STATE = Object.freeze({});

export default function socketReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      return {
        isConnecting: false,
        isConnected: true,
      };
    case 'SOCKET_CONNECTING':
      return {
        ...state,
        isConnecting: true,
      };
    case 'SOCKET_DISCONNECTED':
      return {
        ...state,
        isConnected: false,
      };
    default:
      return state;
  }
}
