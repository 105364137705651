export function chatFetching(id) {
  return {
    type: 'CHAT_FETCHING',
    payload: id,
  };
}

export function chatFetched(attrs) {
  return {
    type: 'CHAT_FETCHED',
    payload: attrs,
  };
}

export function chatSent(attrs) {
  return {
    type: 'CHAT_SENT',
    payload: attrs,
  };
}

export function chatDelivered(attrs) {
  return {
    type: 'CHAT_DELIVERED',
    payload: attrs,
  };
}

export function chatRead(attrs) {
  return {
    type: 'CHAT_READ',
    payload: attrs,
  };
}
