import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import ActivityIcon from '@material-ui/icons/Today';
import HandIcon from '@material-ui/icons/PanTool';
import HistoryIcon from '@material-ui/icons/History';
import WaitlistIcon from '@material-ui/icons/List';

import useAccountUser from 'selectors/users/use-account-user';

export default function OfficeHoursMenu({ courseId }) {
  const { path } = useRouteMatch();
  const accountUser = useAccountUser(courseId);
  const role = accountUser?.role;

  return (
    <List
      component="div"
      subheader={<ListSubheader component="div">Office hours</ListSubheader>}
    >
      {['instructor', 'staff'].includes(role) && (
        <ListItem
          button
          component={Link}
          to={`/courses/${courseId}/office-hours/waitlist`}
          selected={path.endsWith('waitlist')}
        >
          <ListItemIcon>
            <WaitlistIcon color="action" />
          </ListItemIcon>
          <ListItemText primary="Waitlist" />
        </ListItem>
      )}

      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/office-hours/hand`}
        selected={path.endsWith('hand')}
      >
        <ListItemIcon>
          <HandIcon color="action" />
        </ListItemIcon>
        <ListItemText primary="Get help" />
      </ListItem>

      <ListItem
        button
        component={Link}
        to={`/courses/${courseId}/office-hours/history`}
        selected={path.endsWith('history')}
      >
        <ListItemIcon>
          <HistoryIcon color="action" />
        </ListItemIcon>
        <ListItemText primary="History" />
      </ListItem>

      {['instructor', 'staff'].includes(role) && (
        <ListItem
          button
          component={Link}
          to={`/courses/${courseId}/office-hours/activity`}
          selected={path.endsWith('activity')}
        >
          <ListItemIcon>
            <ActivityIcon color="action" />
          </ListItemIcon>
          <ListItemText primary="Activity" />
        </ListItem>
      )}
    </List>
  );
}
