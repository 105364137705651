/**
 * This reducer handles dispatched state change actions that affect the auth
 *   state object
 */

const INITIAL_STATE = Object.freeze({});

export default function authReducer(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case 'AUTH_VALIDATED':
    case 'AUTH_CHANGED':
      return {
        isAuthenticated: payload.isAuthenticated,
        account: payload.account,
      };
    case 'AUTH_INVALIDATED':
      return {
        isAuthenticated: false,
        account: null,
      };
    case 'ACCOUNT_CHANGED':
      if (!state.isAuthenticated) {
        throw new Error(
          'Cannot update the authenticated user when the system is not authenticated'
        );
      }
      return {
        isAuthenticated: true,
        account: { ...state.account, ...payload.account },
      };
    case 'PRESENCE_CHANGED':
      if (state.isAuthenticated && state.account.id === payload.id) {
        return {
          isAuthenticated: true,
          account: { ...state.account, presence: payload.presence },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}
