export function courseFetching(id) {
  return {
    type: 'COURSE_FETCHING',
    payload: id,
  };
}

export function courseFetched(payload) {
  return {
    type: 'COURSE_FETCHED',
    payload,
  };
}

export function courseCreated(payload) {
  return {
    type: 'COURSE_CREATED',
    payload,
  };
}

export function courseChanged(payload) {
  return {
    type: 'COURSE_CHANGED',
    payload,
  };
}

export function courseDestroyed(id) {
  return {
    type: 'COURSE_DESTROYED',
    payload: id,
  };
}
