import React from 'react';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';

import UserAvatar from 'components/user/user-avatar';
import UserNames from 'components/user/user-names';

import { FollowupButton, MarkCompletedButton } from '../ticket-action-buttons';

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'flex-end',
  },
}));

export default function HelpingTicket({ ticket, accountId }) {
  const classes = useStyles();

  const studentIds = ticket.students.map((s) => s.userId);
  const isActiveTeacher = ticket.teachers.some(
    (s) => s.accountId === accountId && s.status === 'active'
  );

  return (
    <Box clone mb={2}>
      <Card elevation={3}>
        <CardHeader
          titleTypographyProps={{ variant: 'body1' }}
          avatar={<UserAvatar ids={studentIds} />}
          title={<UserNames ids={studentIds} withGroup />}
          subheader={
            <>
              Getting help from{' '}
              <UserNames ids={ticket.teachers.map((t) => t.userId)} />
            </>
          }
          action={
            <>
              <Typography variant="body1" align="right">
                <Moment
                  date={ticket.startedAt}
                  durationFromNow
                  interval={1000}
                />
              </Typography>
              <Typography variant="body2" color="textSecondary" align="right">
                helping time
              </Typography>
            </>
          }
        />

        {isActiveTeacher && (
          <CardActions className={classes.actions}>
            <FollowupButton id={ticket.id} variant="text" />
            <MarkCompletedButton id={ticket.id} variant="contained" />
          </CardActions>
        )}
      </Card>
    </Box>
  );
}
