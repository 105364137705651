import React from 'react';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ChatBubble from './chat-bubble';

export default function ChatBubbleList({ messages, user, authUserId }) {
  if (!Array.isArray(messages) || messages.length === 0) {
    return <BeginningOfThread user={user} />;
  }

  return messages.map((message, index) => {
    const isFirstMessage = index === 0;
    const isLastMessage = index + 1 === messages.length;
    const isFirstInSeries =
      isFirstMessage || messages[index - 1].senderId !== message.senderId;
    const isLastInSeries =
      isLastMessage || messages[index + 1].senderId !== message.senderId;

    return (
      <ChatBubble
        key={message.id}
        senderId={message.senderId}
        recipientId={message.recipientId}
        body={message.body}
        updatedAt={message.updatedAt}
        status={message.status}
        authUserId={authUserId}
        hideTime={!isLastInSeries}
        hideAvatar={!isFirstInSeries}
      />
    );
  });
}

function BeginningOfThread({ user }) {
  return (
    <Box>
      <ListItem>
        <Typography color="textSecondary" variant="subtitle2" paragraph>
          {!user && 'This is the beginning of the conversation.'}
          {user &&
            `This is the beginning of your conversation with ${user.first} ${user.last}.`}
        </Typography>
      </ListItem>
    </Box>
  );
}
