import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

export default function ViewName({ first, last, onClick }) {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Typography variant="body1">
          Your display name is:{' '}
          <strong>
            {first} {last}
          </strong>
        </Typography>
      </Grid>

      <Grid item>
        <Button
          color="primary"
          variant="text"
          startIcon={<EditIcon />}
          size="small"
          onClick={onClick}
        >
          Change
        </Button>
      </Grid>
    </Grid>
  );
}
