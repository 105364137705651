import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ResetIcon from '@material-ui/icons/Lock';

import MaskedTextField from 'components/inputs/masked-text-field';
import resetPasswordRoute from 'api/routes/passwords/reset-password';
import LoadingButton from 'components/buttons/loading-button';

export default function ResetForm({ onSubmit, token }) {
  const { register, handleSubmit, formState, errors, setError } = useForm();
  const { isSubmitting } = formState;

  const submitForm = useCallback(
    ({ password }) => {
      return resetPasswordRoute({ password, token }).then(({ status }) => {
        switch (status) {
          case 200:
            onSubmit instanceof Function && onSubmit();
            break;
          case 401:
            setError('password', 'unauthorized');
            break;
          default:
            setError('password', 'unknown');
        }
      });
    },
    [onSubmit, setError, token]
  );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <MaskedTextField
        name="password"
        label="New password"
        variant="outlined"
        margin="normal"
        fullWidth
        disabled={isSubmitting}
        error={Boolean(errors.password)}
        helperText={passwordHelperText(errors)}
        inputRef={register({ required: true, minLength: 8 })}
      />

      <Box my={2}>
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<ResetIcon />}
              loading={isSubmitting}
            >
              Reset password
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

function passwordHelperText(errors) {
  switch (errors.password?.type) {
    case 'required':
      return 'Required field';
    case 'unauthorized':
      return 'Invalid or expired password reset link';
    case 'unknown':
      return 'An unknown server error occurred';
    case 'minLength':
    default:
      return 'Must be at least 8 characters';
  }
}
