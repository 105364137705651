import React from 'react';
import Section from 'components/content/section';
import Typography from '@material-ui/core/Typography';
import Chart from './chart';

export default function TrafficHeatmapSection({ tickets, isLoading }) {
  return (
    <Section title="Traffic by week of semester">
      <Typography paragraph>
        This heatmap shows how many requests were made were on different days of
        the semester. Use it to determine the periods when students relied most
        heavily on office hours.
      </Typography>

      <Chart tickets={tickets} isLoading={isLoading} />
    </Section>
  );
}
