import moment from 'moment';

export default function numTicketsPerDate(
  tickets,
  field = 'requestedAt',
  format = 'YYYY-MM-DD'
) {
  return tickets.reduce((obj, ticket) => {
    const day = moment(ticket[field]).format('YYYY-MM-DD');
    if (day in obj) {
      obj[day]++;
    } else {
      obj[day] = 1;
    }
    return obj;
  }, {});
}
