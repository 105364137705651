/**
 * Takes the result of an ajax api request, and loads the records into the store
 */

import { chatFetched } from 'actions/chats/actions';
import { courseFetched } from 'actions/courses/actions';
import { ticketFetched } from 'actions/tickets/actions';
import { userFetched } from 'actions/users/actions';
import { usageFetched } from 'actions/usages/actions';

const actions = {
  chats: chatFetched,
  courses: courseFetched,
  tickets: ticketFetched,
  users: userFetched,
  usages: usageFetched,
};

export default function loadRecords(data) {
  return (dispatch) => {
    Object.keys(data).forEach((key) => {
      const records = data[key];
      const recordFetched = actions[key];
      if (Array.isArray(records) && recordFetched instanceof Function) {
        records.forEach((record) => dispatch(recordFetched(record)));
      }
    });
  };
}
