/**
 * Gets only "calling" tickets from the store for which the authenticated
 *   user is an active teacher, optionally filtered for a particular course
 */

import { useSelector } from 'react-redux';
import { createSelector as createOrmSelector } from 'redux-orm';
import { createSelector } from 'reselect';
import { getAccount } from 'selectors/auth/use-account';
import orm from 'models/orm';

export const getCallingTickets = createOrmSelector(
  orm.Course.tickets,
  (tickets) =>
    tickets
      .flat()
      .filter((t) => t.status === 'calling')
      .sort(newestFirst)
);

export const getMyCallingTickets = createSelector(
  getCallingTickets,
  getAccount,
  (tickets, account) =>
    tickets.filter((t) =>
      t.teachers.some(
        (p) => p.accountId === account.id && p.status === 'active'
      )
    )
);

export default function useMyCallingTickets(courseId) {
  return useSelector((store) => getMyCallingTickets(store, courseId));
}

function newestFirst(a, b) {
  return new Date(b.calledAt) - new Date(a.calledAt);
}
