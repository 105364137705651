import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import useCourse from 'selectors/courses/use-course';
import AppBar from 'components/app-bar/app-bar';
import Section from 'components/content/section';

export default function CourseHomePage() {
  const params = useParams();
  const courseId = Number(params.courseId);
  const course = useCourse(courseId);

  if (!course) {
    return <AppBar title="Home" />;
  }

  return (
    <AppBar title="Home">
      <Box p={2}>
        <Paper>
          <Box p={2}>
            <Section title={`Welcome to ${course.code}!`}></Section>
          </Box>
        </Paper>
      </Box>
    </AppBar>
  );
}
