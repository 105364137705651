import React from 'react';

import Section from 'components/content/section';
import BasicAttributesForm from './basic-attributes-form';

export default function BasicAttributesSection({ courseId }) {
  return (
    <Section title="Basic attributes">
      <BasicAttributesForm courseId={courseId} />
    </Section>
  );
}
