import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import useCourseEntryCodes from 'selectors/courses/use-course-entry-codes';
import NavBar from 'components/app-bar/app-bar';
import capitalize from 'utils/capitalize';
import QrCodeSection from './qr-code-section';
import EntryCodeSection from './entry-code-section';
import ResetCodesSection from './reset-codes-section';
import DisableCodesSection from './disable-codes-section';
import EmailDomainSection from './email-domain-section';

export default function EnrollmentPage() {
  const params = useParams();
  const courseId = Number(params.courseId);
  const codes = useCourseEntryCodes(courseId);
  const [role, setRole] = useState('student');

  const handleTabChange = (event, value) => {
    setRole(value);
  };

  if (!codes) {
    return null;
  }

  return (
    <>
      <NavBar
        title={`${capitalize(role)} enrollment`}
        back={`/courses/${courseId}/settings`}
      />

      <Box m={2}>
        <Paper>
          <Tabs
            value={role}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Students" value="student" />
            <Tab label="Staff" value="staff" />
            <Tab label="Instructors" value="instructor" />
          </Tabs>

          {role !== 'instructor' && (
            <>
              <QrCodeSection role={role} codes={codes} />

              <EntryCodeSection role={role} codes={codes} />

              <ResetCodesSection role={role} courseId={courseId} />

              <DisableCodesSection
                role={role}
                codes={codes}
                courseId={courseId}
              />
            </>
          )}

          {role === 'student' && <EmailDomainSection courseId={courseId} />}
        </Paper>
      </Box>
    </>
  );
}
