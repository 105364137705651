export function usageFetched(attrs) {
  return {
    type: 'USAGE_FETCHED',
    payload: attrs,
  };
}

export function usageChanged(userId) {
  return {
    type: 'USAGE_CHANGED',
    payload: userId,
  };
}
