import useUsers from 'selectors/users/use-users';
import pluralize from 'pluralize';

export default function UserNames({
  ids,
  yourId,
  withToBeConjugation,
  withGroups,
  lowercaseYou,
}) {
  const users = useUsers(ids);

  const includesYou = ids.includes(yourId);
  const names = users
    .filter((u) => u)
    .filter((u) => u.id !== yourId)
    .map((u) =>
      u.group ? `${u.first} ${u.last} (${u.group})` : `${u.first} ${u.last}`
    );
  if (includesYou) {
    names.unshift(lowercaseYou ? 'you' : 'You');
  }

  let result = null;
  if (names.length === 1) {
    result = names[0];
  } else if (names.length === 2) {
    result = names.join(' and ');
  } else if (names.length > 2) {
    const firstName = names[0];
    const secondName = names[1];
    const others = pluralize('other', names.length - 2, true);
    result = [firstName, secondName, `and ${others}`].join(', ');
  }

  if (withToBeConjugation && result !== null) {
    if (names.length === 1 && !includesYou) {
      return `${result} is`;
    } else {
      return `${result} are`;
    }
  } else {
    return result;
  }
}
