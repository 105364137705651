export default function oldestDate(tickets) {
  if (Array.isArray(tickets) && tickets.length > 0) {
    let oldest = new Date(tickets[0].requestedAt);
    tickets.forEach((ticket) => {
      const date = new Date(ticket.requestedAt);
      if (oldest.getTime() > date.getTime()) {
        oldest = date;
      }
    });
    return oldest;
  } else {
    return new Date(NaN);
  }
}
