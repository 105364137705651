/**
 * Fetches a single usage by id from the server; debounces rapid requests for
 * the same usage
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import loadRecords from 'actions/load-records';
import readUsageRoute from 'api/routes/users/read-usage';

const promises = {};

export default function fetchUsage(userId) {
  return (dispatch) => {
    if (!Number.isInteger(userId) || userId <= 0) {
      return;
    } else if (userId in promises) {
      return promises[userId];
    }
    const promise = readUsageRoute(userId)
      .then(({ data }) => {
        dispatch(loadRecords(data));
      })
      .finally(() => {
        delete promises[userId];
      });
    promises[userId] = promise;
    return promise;
  };
}
