import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import InfoIcon from '@material-ui/icons/Info';

import JoinByEmailForm from './join-by-email-form';
import useCourseRole from 'selectors/courses/use-course-role';

export default function JoinByEmail({ course, onJoin }) {
  const [expanded, setExpanded] = useState(false);
  const courseRole = useCourseRole(course?.id);

  if (!course || courseRole || !course.canStudentsEnrollByEmail) {
    return null;
  }

  const handleExpand = () => {
    setExpanded(true);
  };

  return (
    <Grid container spacing={2} alignContent="center">
      <Grid item>
        <InfoIcon color="disabled" />
      </Grid>

      <Grid item xs>
        <Typography variant="body1" color="textSecondary">
          Have an email address ending in <strong>{course.emailDomain}</strong>?
        </Typography>

        {!expanded && (
          <Link variant="body2" component="button" onClick={handleExpand}>
            Enroll as a <strong>student</strong> using your{' '}
            <strong>{course.emailDomain}</strong> email
          </Link>
        )}

        <Collapse in={expanded}>
          <Box mb={2}>
            <JoinByEmailForm
              courseId={course.id}
              domain={course.emailDomain}
              onSubmit={onJoin}
            />
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
}
