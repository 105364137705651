import React from 'react';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import StaffListItem from './staff-list-item';
import useStaffAvailability from './use-staff-availability';
import useCourse from 'selectors/courses/use-course';

export default function StaffSection({ courseId }) {
  const course = useCourse(courseId);
  const { working, online } = useStaffAvailability(courseId);
  const users = [];

  if (course.shiftPolicy !== 'disabled') {
    users.push(...working);
  }

  if (['disabled', 'optional'].includes(course.shiftPolicy)) {
    users.push(...online);
  }

  return (
    <Box pt={2}>
      <Paper>
        <Box px={2} pt={2}>
          <Typography variant="h6" paragraph>
            Available staff
          </Typography>

          <List>
            {users.map((user) => (
              <StaffListItem
                key={user.id}
                courseId={courseId}
                id={user.id}
                first={user.first}
                last={user.last}
                endAt={user.shift?.endAt}
                presence={user.presence}
                location={user.shift?.location}
              />
            ))}

            {users.length === 0 && (
              <Typography paragraph>
                All staff members are currently offline. Please check back
                later.
              </Typography>
            )}
          </List>
        </Box>
      </Paper>
    </Box>
  );
}
