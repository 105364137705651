import React, { useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import useScreenSize from 'hooks/use-screen-size';

import SchoolAutocomplete from './school-autocomplete';
import CourseAutocomplete from './course-autocomplete';
import CourseDetails from './course-details';

export default function CourseSearchDialog({ onClose, ...params }) {
  const [school, setSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const screenSize = useScreenSize();
  const isMobile = screenSize === 'xs';

  const handleSchoolChange = (newSchool) => {
    setSchool(newSchool);
    setCourse(null);
  };

  const handleClose = () => {
    setSchool(null);
    setCourse(null);
    onClose instanceof Function && onClose();
  };

  return (
    <Dialog fullWidth fullScreen={isMobile} onClose={handleClose} {...params}>
      <DialogTitle disableTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">Search for courses</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Use this form to search for courses at your school or university. Only
          active courses will be shown.
        </DialogContentText>

        <SchoolAutocomplete onChange={handleSchoolChange} />

        <Collapse in={Boolean(school)}>
          <CourseAutocomplete school={school} onChange={setCourse} />
        </Collapse>

        <Collapse in={Boolean(course)}>
          <CourseDetails course={course} />
        </Collapse>
      </DialogContent>
    </Dialog>
  );
}
