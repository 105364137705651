import React, { useState, useCallback, useEffect } from 'react';
import ResendIcon from '@material-ui/icons/Refresh';
import LoadingButton from 'components/buttons/loading-button';
import resendConfirmationRoute from 'api/routes/emails/resend-confirmation';

export default function ResendButton({ email, onClick }) {
  const [isSending, setIsSending] = useState(false);

  const handleClick = useCallback(() => {
    setIsSending(true);
  }, []);

  useEffect(() => {
    if (!isSending) {
      return;
    }
    let isActive = true;
    resendConfirmationRoute(email)
      .then(({ status, data }) => {
        if (isActive && onClick instanceof Function) {
          onClick();
        }
      })
      .finally(() => {
        if (isActive) {
          setIsSending(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [isSending, email, onClick]);

  return (
    <>
      <LoadingButton
        loading={isSending}
        startIcon={<ResendIcon />}
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        Resend email
      </LoadingButton>
    </>
  );
}
