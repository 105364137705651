import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

export default forwardRef(AsyncButton);

function AsyncButton({ onClick, children, ...props }, ref) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = useCallback(() => {
    setIsSubmitting(true);
  }, []);

  useEffect(() => {
    if (!isSubmitting || !onClick) {
      return;
    }
    const promise = onClick();
    if (promise instanceof Promise) {
      let isActive = true;
      promise.finally(() => {
        if (isActive) {
          setIsSubmitting(false);
        }
      });
      return () => {
        isActive = false;
      };
    }
  }, [isSubmitting, onClick]);

  return (
    <Button
      {...props}
      ref={ref}
      disabled={props.disabled || isSubmitting}
      onClick={handleClick}
      startIcon={
        (isSubmitting && <CircularProgress size="1em" color="inherit" />) ||
        props.startIcon
      }
    >
      {children}
    </Button>
  );
}
