import { useSelector, useDispatch } from 'react-redux';
import fetchTicket from 'actions/tickets/fetch';
import getTickets from './get-tickets';

export default function useTicket(id) {
  const dispatch = useDispatch();
  const ticket = useSelector((store) => {
    if (Number.isInteger(id) && id > 0) {
      return getTickets(store, id);
    } else {
      return null;
    }
  });
  if (Number.isInteger(id) && id > 0 && !ticket) {
    dispatch(fetchTicket(id));
  }
  return ticket;
}
