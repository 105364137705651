import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Section from 'components/content/section';
import ShiftDialog from './shift-dialog';
import ShiftDetails from './shift-details';
import ShiftEndButton from './shift-end-button';
import useAccountUser from 'selectors/users/use-account-user';
import useCourse from 'selectors/courses/use-course';

export default function ShiftSection({ courseId }) {
  const course = useCourse(courseId);
  const accountUser = useAccountUser(courseId);
  const shift = accountUser?.shift;
  const isShiftEnabled = course?.shiftPolicy !== 'disabled';
  const isShiftActive = useIsShiftActive(shift);

  if (!isShiftEnabled) {
    return null;
  }

  return (
    <Section title="Active shift">
      <Box clone p={2}>
        <Paper elevation={3}>
          <ShiftDetails shift={shift} isShiftActive={isShiftActive} />

          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {isActive(shift) && <ShiftEndButton shiftId={shift.id} />}
            </Grid>
            <Grid item>
              <ShiftDialog shift={shift} courseId={courseId} />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Section>
  );
}

function isActive(shift) {
  return new Date(shift?.endAt).getTime() > Date.now();
}

function useIsShiftActive(shift) {
  const [isShiftActive, setIsShiftActive] = useState(isActive(shift));

  useEffect(() => {
    setIsShiftActive(isActive(shift));
    if (isActive(shift)) {
      const timer = setTimeout(() => {
        setIsShiftActive(false);
      }, new Date(shift.endAt).getTime() - Date.now());
      return () => {
        clearTimeout(timer);
      };
    }
  }, [shift]);

  return isShiftActive;
}
