import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import LogInIcon from '@material-ui/icons/Done';

import signin from 'actions/auth/signin';
import LoadingButton from 'components/buttons/loading-button';
import MaskedTextField from 'components/inputs/masked-text-field';
import emailRegex from 'utils/email-regex';

export default function SigninForm({ onSignin, hideReset, hideSignup, email }) {
  // This hack is to hide password reset as part of Fall 23 fixup to get around MailGun problems.
  hideReset = true;

  const { register, handleSubmit, formState, errors, setError } = useForm();
  const { isSubmitting } = formState;

  useEffect(() => {
    register({ name: 'server' });
  }, [register]);

  const submitForm = useCallback(
    (data) => {
      return signin(data).then(({ status, message }) => {
        if (status !== 200) {
          setError('server', 'serverMessage', message);
        } else if (onSignin instanceof Function) {
          onSignin();
        }
      });
    },
    [onSignin, setError]
  );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <TextField
        name="email"
        label="Email"
        variant="filled"
        margin="normal"
        fullWidth
        autoFocus
        defaultValue={email || ''}
        disabled={isSubmitting}
        error={Boolean(errors.email)}
        helperText={emailHelperText(errors)}
        inputRef={register({ required: true, pattern: emailRegex })}
      />

      <MaskedTextField
        name="password"
        label="Password"
        variant="filled"
        margin="normal"
        fullWidth
        disabled={isSubmitting}
        error={Boolean(errors.password)}
        helperText={passwordHelperText(errors)}
        inputRef={register({ required: true, minLength: 8 })}
      />

      <Collapse in={Boolean(errors?.server)}>
        <Box
          mb={3}
          textAlign="right"
          component="p"
          fontWeight="bold"
          color="error.main"
        >
          {errors?.server?.message}
        </Box>
      </Collapse>

      <Box clone mt={1}>
        <Grid container justify="flex-end" alignItems="center" spacing={2}>
          {!hideReset && (
            <Grid item>
              <Button component={Link} to="/passwords/recover" color="primary">
                Reset password
              </Button>
            </Grid>
          )}

          {!hideSignup && (
            <Grid item>
              <Button component={Link} to="/signup" color="primary">
                Sign up
              </Button>
            </Grid>
          )}

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<LogInIcon />}
              loading={isSubmitting}
            >
              Sign in
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

function emailHelperText(errors) {
  switch (errors.email?.type) {
    case 'required':
      return 'Required field';
    case 'pattern':
      return 'Not a valid email address';
    default:
      return 'Your primary account email address';
  }
}

function passwordHelperText(errors) {
  switch (errors.password?.type) {
    case 'required':
      return 'Required field';
    case 'minLength':
    default:
      return 'Must be at least 8 characters';
  }
}
