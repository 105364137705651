export default function makeData(shifts) {
  const data = shifts.map((shift) => ({
    startDate: shift.startAt,
    endDate: shift.endAt,
    allDay: false,
    title: `${shift.user.first} ${shift.user.last}`,
  }));

  const startHour = Math.min(
    8,
    ...data.map((r) => new Date(r.startDate).getHours())
  );

  const endHour = Math.max(
    20,
    ...data.map((r) => 1 + new Date(r.endDate).getHours())
  );

  const latestDate = new Date(
    Math.max(...data.map((r) => new Date(r.endDate).getTime()))
  );

  return { data, startHour, endHour, latestDate };
}
