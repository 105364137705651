import { createSelector } from 'redux-orm';
import { useSelector } from 'react-redux';
import orm from 'models/orm';

export const getStaff = createSelector(orm.Course.users, (users) =>
  users.flat().filter((u) => ['staff', 'instructor'].includes(u.role))
);

export default function useStaff(courseId) {
  return useSelector((store) => getStaff(store, courseId));
}
