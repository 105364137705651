import React, { useState, useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Link from '@material-ui/core/Link';

import useScreenSize from 'hooks/use-screen-size';
import SigninForm from 'pages/signin/signin-form';
import SignupForm from 'pages/signup/signup-form';
import EnrollRequest from './enroll-request';

export default function EnrollDialog({ code, isAuthenticated, ...params }) {
  const [email, setEmail] = useState(null);
  const [state, setState] = useState('signup');
  const screenSize = useScreenSize();
  const isMobile = screenSize === 'xs';

  const handleTypeChange = (type) => (event) => {
    event.preventDefault();
    setState(type);
  };

  const handleSignup = useCallback(({ email, first }) => {
    setEmail(email);
    setState('confirm');
  }, []);

  if (isAuthenticated) {
    return (
      <Dialog fullWidth fullScreen={isMobile} {...params}>
        <EnrollRequest code={code} />
      </Dialog>
    );
  } else if (state === 'signup') {
    return (
      <Dialog fullWidth fullScreen={isMobile} {...params}>
        <DialogTitle>Enroll by QR code</DialogTitle>

        <DialogContent>
          <DialogContentText>
            You scanned a My Digital Hand enrollment QR code.
          </DialogContentText>

          <DialogContentText>
            Create a My Digital Hand account below to finish enrolling in the
            course.
          </DialogContentText>

          <DialogContentText>
            If you already have an account,{' '}
            <Link
              component="button"
              variant="body1"
              onClick={handleTypeChange('signin')}
            >
              log in instead
            </Link>{' '}
            to complete enrollment.
          </DialogContentText>

          <Box mb={1}>
            <SignupForm code={code} hideSignin onSignup={handleSignup} />
          </Box>
        </DialogContent>
      </Dialog>
    );
  } else if (state === 'signin') {
    return (
      <Dialog fullWidth fullScreen={isMobile} {...params}>
        <DialogTitle>Enroll by QR code</DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            You scanned a My Digital Hand enrollment QR code.
          </DialogContentText>

          <DialogContentText>
            Log in to your My Digital Hand account below to finish enrolling in
            the course.
          </DialogContentText>

          <DialogContentText>
            If this is your first time,{' '}
            <Link
              component="button"
              variant="body1"
              onClick={handleTypeChange('signup')}
            >
              create an account instead
            </Link>{' '}
            to complete enrollment.
          </DialogContentText>

          <Box mb={1}>
            <SigninForm hideReset hideSignup />
          </Box>
        </DialogContent>
      </Dialog>
    );
  } else if (state === 'confirm') {
    return (
      <Dialog fullWidth fullScreen={isMobile} {...params}>
        <DialogTitle>Confirm your email address</DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            An email was sent to {email ?? 'your email address'}.
          </DialogContentText>

          <DialogContentText>
            To complete registration and enrollment, check your inbox for a My
            Digital Hand confirmation email address.
          </DialogContentText>

          <DialogContentText>You may now close this window.</DialogContentText>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}
