import React, { useMemo } from 'react';
import MaterialTable from 'material-table';
import Icons from 'components/tables/table-icons';
import makeData from './make-data';
import msToStr from '../../../data-shapers/ms-to-str';

// Should be able to hard-code the columns array, but it causes a memory leak
// For now, just recompute it every time
// See: https://github.com/mbrn/material-table/issues/2650
const tableColumns = () => [
  {
    title: 'Staff Name',
    field: 'name',
  },
  {
    title: 'Completed Interactions',
    field: 'ticketCount',
    align: 'right',
    defaultSort: 'desc',
  },
  {
    title: 'Shifts',
    field: 'shiftCount',
    align: 'right',
  },
  {
    title: 'Total Interaction Time',
    field: 'msSpentHelping',
    render: (data) => msToStr(data.msSpentHelping),
    align: 'right',
    defaultSort: 'asc',
  },
  {
    title: 'Average Interaction Time',
    field: 'avgHelpingMs',
    render: (data) =>
      data.avgHelpingMs < 0 ? 'N/A' : msToStr(data.avgHelpingMs),
    align: 'right',
  },
  {
    title: 'Total On-Duty Time',
    field: 'msSpentOnDuty',
    render: (data) => msToStr(data.msSpentOnDuty),
    align: 'right',
  },
];

export default function TeacherTable({ tickets, teachers, shifts, isLoading }) {
  const data = useMemo(() => makeData({ tickets, teachers, shifts }), [
    tickets,
    teachers,
    shifts,
  ]);

  return (
    <div style={{ width: '820px' }}>
      <MaterialTable
        title="Staff usage"
        columns={tableColumns()}
        data={data}
        icons={Icons}
        isLoading={isLoading}
        options={{
          padding: 'dense',
          paging: false,
          thirdSortClick: false,
        }}
        components={{ Container: ({ children }) => children }}
      />
    </div>
  );
}
