import React from 'react';

import FreeResponseField from './free-response-field';
import MultipleChoiceField from './multiple-choice-field';
import NumberSliderField from './number-slider-field';
import SelectAllField from './select-all-field';
import ShortAnswerField from './short-answer-field';
import StarRatingField from './star-rating-field';

export default function QuestionField({
  question,
  answer,
  onChange,
  isDisabled,
}) {
  switch (question.type) {
    case 'short-answer':
      return (
        <ShortAnswerField
          value={answer}
          isDisabled={isDisabled}
          isRequired={question.isRequired}
          hasAutocomplete={question.hasAutocomplete}
          onChange={onChange}
        />
      );
    case 'free-response':
      return (
        <FreeResponseField
          value={answer}
          isDisabled={isDisabled}
          isRequired={question.isRequired}
          onChange={onChange}
        />
      );
    case 'multiple-choice':
      return (
        <MultipleChoiceField
          options={question.options}
          selection={answer && answer.selection}
          isDisabled={isDisabled}
          isRequired={question.required}
          isOtherEnabled={question.allowCustomResponse}
          otherValue={answer && answer.otherValue}
          onChange={(selection, otherValue, isValid) =>
            onChange({ selection, otherValue }, isValid)
          }
        />
      );
    case 'select-all':
      return (
        <SelectAllField
          options={question.options}
          selections={answer && answer.selections}
          isDisabled={isDisabled}
          isRequired={question.required}
          isOtherEnabled={question.allowCustomResponse}
          otherValue={answer && answer.otherValue}
          onChange={(selections, otherValue, isValid) =>
            onChange({ selections, otherValue }, isValid)
          }
        />
      );
    case 'number-slider':
      return <NumberSliderField />;
    case 'star-rating':
      return <StarRatingField />;
    default:
      throw new Error('Unimplemented question type');
  }
}
