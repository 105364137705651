import React from 'react';
import AppBar from 'components/app-bar/app-bar';
import StatisticsPage from './statistics-page';

export default function StatisticsRouter() {
  return (
    <AppBar title="Statistics">
      <StatisticsPage />
    </AppBar>
  );
}
