import React, { useMemo, useState } from 'react';
import moment from 'moment';
import useCourse from 'selectors/courses/use-course';
import LoadingButton from 'components/buttons/loading-button';
import makeData from './make-data';

export default function SurveyExportButton({
  courseId,
  tickets,
  isLoading,
  ...props
}) {
  const [isCreating, setIsCreating] = useState(false);
  const course = useCourse(courseId);
  const surveys = useMemo(() => makeData(tickets), [tickets]);

  const handleExport = async () => {
    const fileName = exportShiftsFilename(course);
    const json = JSON.stringify(surveys);
    const blob = new Blob([json], { type: 'application/json' });
    setIsCreating(true);
    const href = await URL.createObjectURL(blob);
    setIsCreating(false);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LoadingButton
      {...props}
      loading={isLoading || isCreating}
      onClick={handleExport}
    />
  );
}

function exportShiftsFilename(course) {
  const date = moment().format('YYYY-MM-DD');
  const time = moment().format('HH-mm');
  return [course.code, course.semester, course.year, 'feedback', date, time]
    .filter((x) => x)
    .join('-')
    .replace(' ', '-');
}
