import React from 'react';

import MuiDialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';

import useTicket from 'selectors/tickets/use-ticket';
import FollowupListItem from './followup-list-item';
import PromptsListItem from './prompts-list-item';
import StudentListItem from './student-list-item';

export default function DialogContent({ ticketId }) {
  const ticket = useTicket(ticketId);

  if (!ticket) {
    return null;
  }

  return (
    <MuiDialogContent>
      <List>
        <StudentListItem ticketId={ticketId} />

        <PromptsListItem prompts={ticket.prompts} />

        <FollowupListItem parentId={ticket.parentId} />
      </List>
    </MuiDialogContent>
  );
}
