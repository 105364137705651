/**
 * Fetches the last completed ticket as a student for each enabled course in
 * which the authenticated user is an active participant
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import loadRecords from 'actions/load-records';
import lastCompletedTicketsRoute from 'api/routes/tickets/last-completed-tickets';

export default function fetchLastCompletedTicket() {
  return (dispatch) => {
    return lastCompletedTicketsRoute().then(({ status, headers, data }) => {
      if (status === 200) {
        dispatch(loadRecords(data));
      }
      return { status, headers, data };
    });
  };
}
