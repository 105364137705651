import React, { useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import EnrollmentsTable from './table';
import useEnrollments from './use-data';
import AcceptInvitationDialog from 'pages/invitations/accept/accept-dialog';

export default function CoursesTableSection() {
  const [dialog, setDialog] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [enrollments, isLoading, reload] = useEnrollments();

  const handleClickAccept = useCallback((userId) => {
    setDialogData(userId);
    setDialog('accept');
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialog(null);
    setDialogData(null);
    reload();
  }, [reload]);

  return (
    <>
      <Typography variant="h6" paragraph>
        Your courses
      </Typography>

      <Typography paragraph>
        Your account is associated with the following courses.
      </Typography>

      <EnrollmentsTable
        enrollments={enrollments}
        isLoading={isLoading}
        onClickAccept={handleClickAccept}
      />

      <AcceptInvitationDialog
        open={dialog === 'accept'}
        onClose={handleCloseDialog}
        id={dialogData}
        isAuthenticated
      />
    </>
  );
}
