/**
 * Gets the chat thread between the authenticated user and a particular userId
 */

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';
import loadThread from 'actions/chats/load-thread';

export const getChatThread = createSelector(
  orm,
  orm.User.course,
  (store, userId) => userId,
  (session, course, userId) => {
    const accountUserId = course?.userId;
    return session.Chat.all()
      .toModelArray()
      .filter(
        ({ senderId, recipientId }) =>
          (senderId === userId && recipientId === accountUserId) ||
          (recipientId === userId && senderId === accountUserId)
      )
      .sort(newestFirst);
  }
);

export default function useChatThread(userId) {
  const dispatch = useDispatch();
  const thread = useSelector((store) => getChatThread(store, userId));

  useEffect(() => {
    dispatch(loadThread(userId));
  }, [dispatch, userId]);

  return thread;
}

function newestFirst(a, b) {
  return new Date(b.sentAt) - new Date(a.sentAt);
}
