import React from 'react';
import pluralize from 'pluralize';
import Section from 'components/content/section';
import TicketCard from 'components/tickets/ticket';
import useMyCallingTickets from 'selectors/tickets/use-my-calling-tickets';

export default function CallingSection({ courseId }) {
  const tickets = useMyCallingTickets(courseId);

  if (!Array.isArray(tickets) || tickets.length === 0) {
    return null;
  }

  return (
    <Section title={`${pluralize('student', tickets.length, true)} on the way`}>
      {tickets.map((ticket) => (
        <TicketCard key={ticket.id} ticket={ticket} />
      ))}
    </Section>
  );
}
