import React, { useState } from 'react';
import pluralize from 'pluralize';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import ShowIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';

import capitalize from 'utils/capitalize';
import Section from 'components/content/section';

export default function EntryCodeSection({ role, codes }) {
  const [isRevealed, setIsRevealed] = useState(false);

  const enrollCode = codes[`${role}EnrollCode`];
  const canEnrollByCode =
    codes[`can${pluralize(capitalize(role))}EnrollByCode`];

  const toggleRevealed = () => {
    setIsRevealed((isRevealed) => !isRevealed);
  };

  return (
    <Section title={`Enroll ${pluralize(role)} by entry code`} m={2}>
      <Collapse in={!canEnrollByCode}>
        <Alert severity="error">
          The entry code method for {role} enrollment is currently disabled for
          this course. It can be enabled below.
        </Alert>
      </Collapse>

      <Collapse in={canEnrollByCode}>
        <Typography paragraph>
          Another way to invite {pluralize(role)} is by sharing an entry code
          with them.
        </Typography>

        <Typography paragraph>
          Anyone who knows this 7-character entry code is authorized to register
          in your course as a {role === 'staff' ? 'staff member' : role}. The
          code can be used more than once.
        </Typography>

        <Typography paragraph>
          {capitalize(pluralize(role))} will need to navigate to{' '}
          <a href={window.location.origin}>the My Digital Hand website</a> on
          their own, sign in or create an account, and enter this code when
          prompted to join your course.
        </Typography>

        <Collapse in={isRevealed}>
          <Typography paragraph>
            The {role} entry code for your course is:{' '}
            <strong>{enrollCode}</strong>
          </Typography>
        </Collapse>

        <Typography paragraph>
          <Button
            onClick={toggleRevealed}
            variant="outlined"
            color="primary"
            endIcon={isRevealed ? <HideIcon /> : <ShowIcon />}
          >
            {isRevealed ? 'Hide' : 'Show'} code
          </Button>
        </Typography>
      </Collapse>
    </Section>
  );
}
