import { Model, fk, attr } from 'redux-orm';

export default class Ticket extends Model {
  static reducer({ type, payload }, Ticket, session) {
    switch (type) {
      case 'TICKET_FETCHED':
      case 'TICKET_CREATED':
      case 'TICKET_JOINED':
      case 'TICKET_UNJOINED':
      case 'TICKET_WAITING':
      case 'TICKET_CALLING':
      case 'TICKET_HELPING':
      case 'TICKET_COMPLETED':
      case 'TICKET_CANCELED':
        Ticket.upsert(payload);
        break;
      case 'FEEDBACK_SUBMITTED':
        if (Ticket.idExists(payload.ticketId)) {
          const ticket = Ticket.withId(payload.ticketId);
          ticket.update({
            students: ticket.students.map((s) =>
              s.userId === payload.userId ? { ...s, hasFeedback: true } : s
            ),
            teachers: ticket.teachers.map((t) =>
              t.userId === payload.userId ? { ...t, hasFeedback: true } : t
            ),
          });
        }
        break;
      case 'AUTH_INVALIDATED':
        Ticket.all()
          .toModelArray()
          .forEach((c) => c.delete());
        break;
      default:
    }
  }

  get lastActionAt() {
    switch (this.status) {
      case 'waiting':
        return this.requestedAt;
      case 'calling':
        return this.calledAt;
      case 'helping':
        return this.startedAt;
      case 'completed':
        return this.completedAt;
      case 'canceled':
        return this.canceledAt;
      default:
        return new Date().toISOString();
    }
  }
}

Ticket.modelName = 'Ticket';

Ticket.fields = {
  id: attr(),
  status: attr(),

  // Url for virtual office hours
  virtualUrl: attr(),

  // Answers to prompts
  prompts: attr(),

  // Timestamps
  requestedAt: attr(),
  calledAt: attr(),
  startedAt: attr(),
  completedAt: attr(),
  canceledAt: attr(),

  // Related users
  teachers: attr({ getDefault: () => [] }),
  students: attr({ getDefault: () => [] }),

  // Followup
  isFollowup: attr(),
  parentId: fk({
    to: 'Ticket',
    as: 'parent',
    relatedName: 'followups',
  }),

  // Course
  courseId: fk({
    to: 'Course',
    as: 'course',
    relatedName: 'tickets',
  }),
};
