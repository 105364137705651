export default function nameList(names) {
  if (Array.isArray(names)) {
    switch (names.length) {
      case 0:
        return '';
      case 1:
        return names[0];
      case 2:
        return names.join(' and ');
      case 3:
        return `${names[0]}, ${names[1]}, and 1 other`;
      default:
        return `${names[0]}, ${names[1]}, and ${names.length - 2} others`;
    }
  } else {
    return '';
  }
}
