import React from 'react';
import Moment from 'react-moment';
// import { Link } from 'react-router-dom';

// import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import Tooltip from '@material-ui/core/Tooltip';

// import ChatIcon from '@material-ui/icons/Chat';

import UserAvatar from 'components/user/user-avatar';

export default function StaffListItem({
  courseId,
  id,
  first,
  last,
  endAt,
  presence,
  location,
}) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <UserAvatar id={id} />
      </ListItemAvatar>

      <ListItemText disableTypography>
        <Typography variant="body1">
          {first} {last}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <StaffAvailability endAt={endAt} presence={presence} />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Location: {location}
        </Typography>
      </ListItemText>

      {/* <ListItemSecondaryAction>
        <Tooltip title={`Message ${first}`}>
          <IconButton
            color="default"
            size="small"
            edge="end"
            component={Link}
            to={`/courses/${courseId}/chat/${id}`}
          >
            <ChatIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction> */}
    </ListItem>
  );
}

function StaffAvailability({ endAt, presence }) {
  if (new Date(endAt).getTime() > Date.now()) {
    return (
      <>
        Available until <Moment format="h:mm a">{endAt}</Moment> (
        <Moment fromNow>{endAt}</Moment>)
      </>
    );
  } else if (presence === 'online') {
    return 'Online';
  } else {
    return <em>Offline</em>;
  }
}
