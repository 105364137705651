/**
 * Gets the list of loaded chat threads for a particular userId
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

export const getChatThreads = createSelector(
  orm.User.chatsSent,
  orm.User.chatsReceived,
  (sent, received) => {
    const threads = {};
    sent.forEach((chat) => {
      threads[chat.recipientId] = threads[chat.recipientId] || [];
      threads[chat.recipientId].push(chat);
    });
    received.forEach((chat) => {
      threads[chat.senderId] = threads[chat.senderId] || [];
      threads[chat.senderId].push(chat);
    });
    return Object.values(threads)
      .map((thread) => thread.sort(newestFirst))
      .map((thread) => thread[0])
      .sort(newestFirst);
  }
);

export default function useChatThreads(userId) {
  return useSelector((store) => getChatThreads(store, userId));
}

function newestFirst(a, b) {
  return new Date(b.sentAt) - new Date(a.sentAt);
}
