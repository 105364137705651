import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import HomeIcon from '@material-ui/icons/Home';

import joinCourse from 'api/routes/courses/join-course';
import useCourse from 'selectors/courses/use-course';
import useCourseRole from 'selectors/courses/use-course-role';

export default function EnrollRequest({ code }) {
  const [response, setResponse] = useState(null);

  // Clear the response when the code is changed
  useEffect(() => {
    setResponse(null);
  }, [code]);

  // Try to join the course when the code is changed
  useEffect(() => {
    if (response) {
      return;
    }
    let isActive = true;
    joinCourse({ code }).then((response) => {
      if (isActive) {
        setResponse(response);
      }
    });
    return () => {
      isActive = false;
    };
  }, [code, response]);

  if (!response) {
    return <EnrollProcessing />;
  } else if (response.status === 200) {
    return <EnrollSuccess courseId={response.data.courseId} />;
  } else {
    return <EnrollFailed />;
  }
}

function EnrollProcessing() {
  return (
    <>
      <DialogTitle>Enroll by QR code</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Please wait while we process your enrollment...
        </DialogContentText>
      </DialogContent>
    </>
  );
}

function EnrollSuccess({ courseId }) {
  const course = useCourse(courseId);
  const courseRole = useCourseRole(courseId);

  if (course) {
    const role = {
      instructor: 'an instructor',
      staff: 'a staff member',
      student: 'a student',
    }[courseRole];

    return (
      <>
        <DialogTitle>Enrolled successfully in {course.code}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Your enrollment was successfully processed!
          </DialogContentText>

          <DialogContentText>
            You are now enrolled in <strong>{course.code}</strong> as{' '}
            <strong>{role}</strong>.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            component={Link}
            to={`/courses/${courseId}`}
            color="primary"
            variant="contained"
            startIcon={<HomeIcon />}
          >
            {course.code} home page
          </Button>
        </DialogActions>
      </>
    );
  } else {
    return (
      <>
        <DialogTitle>Enrolled successfully</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Your enrollment was successfully processed!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            component={Link}
            to={`/courses/${courseId}`}
            color="primary"
            variant="contained"
            startIcon={<HomeIcon />}
          >
            Course home page
          </Button>
        </DialogActions>
      </>
    );
  }
}

function EnrollFailed() {
  return (
    <>
      <DialogTitle>Invalid QR code</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Something went wrong and your enrollment could not be processed.
        </DialogContentText>

        <DialogContentText>
          Check with your instructor to make sure you are using the right QR
          code to enroll in the course.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          component={Link}
          to="/"
          color="primary"
          variant="contained"
          startIcon={<HomeIcon />}
        >
          Back to home
        </Button>
      </DialogActions>
    </>
  );
}
