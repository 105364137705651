import React from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import ActiveIcon from '@material-ui/icons/Check';
import ArchivedIcon from '@material-ui/icons/Close';
import DisabledIcon from '@material-ui/icons/Close';
import InvitedIcon from '@material-ui/icons/MoreHoriz';
import PendingIcon from '@material-ui/icons/Mail';

export default function StatusColumn({ status, courseStatus, email }) {
  switch (courseStatus) {
    case 'archived':
      return (
        <Tooltip placement="top-start" title="This course is archived">
          <Chip
            icon={<ArchivedIcon />}
            variant="outlined"
            label="archived"
            size="small"
          />
        </Tooltip>
      );
    case 'disabled':
      return (
        <Tooltip
          placement="top-start"
          title="Your enrollment in this course has been disabled"
        >
          <Chip
            icon={<DisabledIcon />}
            variant="outlined"
            label="disabled"
            size="small"
          />
        </Tooltip>
      );
    case 'active':
    default:
  }

  switch (status) {
    case 'active':
      return (
        <Tooltip
          placement="top-start"
          title="You are an active member of this course"
        >
          <Chip
            icon={<ActiveIcon />}
            color="primary"
            variant="default"
            label="active"
            size="small"
          />
        </Tooltip>
      );
    case 'pending':
      return (
        <Tooltip
          placement="top-start"
          title={`Confirm your email address ${email} to activate this enrollment`}
        >
          <Chip
            icon={<PendingIcon />}
            color="primary"
            variant="outlined"
            label="pending"
            size="small"
          />
        </Tooltip>
      );
    case 'invited':
      return (
        <Tooltip
          placement="top-start"
          title="Accept the invitation to activate this enrollment"
        >
          <Chip
            icon={<InvitedIcon />}
            color="primary"
            variant="outlined"
            label="invitation"
            size="small"
          />
        </Tooltip>
      );
    default:
      return null;
  }
}
