export default function studentTickets(tickets) {
  const dict = {};
  tickets.forEach((ticket) => {
    const studentId = ticket.studentAccountId;
    if (!Number.isInteger(studentId) || studentId <= 0) {
      return;
    } else if (studentId in dict) {
      dict[studentId].push(ticket);
    } else {
      dict[studentId] = [ticket];
    }
  });
  return dict;
}
