import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import BackArrowIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';

import { NavTheme } from 'styles/theme';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: '275px',
  },
}));

export default function AppBar({ title, back, menu, children, ...props }) {
  const classes = useStyles();

  if (menu) {
    return (
      <MenuAppBar title={title} menu={menu} children={children} {...props} />
    );
  }

  return (
    <>
      <ThemeProvider theme={NavTheme}>
        <MuiAppBar position="static" {...props}>
          <Toolbar>
            {back && (
              <IconButton
                edge="start"
                color="inherit"
                className={classes.backButton}
                component={Link}
                to={back}
              >
                <BackArrowIcon />
              </IconButton>
            )}
            {title && <Typography variant="h6">{title}</Typography>}
          </Toolbar>
        </MuiAppBar>
      </ThemeProvider>

      {children}
    </>
  );
}

export function MenuAppBar({ title, menu, children, ...props }) {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((x) => !x);
  };

  return (
    <>
      <ThemeProvider theme={NavTheme}>
        <MuiAppBar position="static" {...props}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.backButton}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            {title && <Typography variant="h6">{title}</Typography>}
          </Toolbar>
        </MuiAppBar>
      </ThemeProvider>

      <Drawer
        open={isDrawerOpen}
        children={menu}
        onClose={toggleDrawer}
        classes={{ paper: classes.drawer }}
      />

      {children}
    </>
  );
}
