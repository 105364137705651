import React from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

export default function LoadingButton({ loading, children, ...props }) {
  return (
    <Button
      {...props}
      disabled={props.disabled || loading}
      startIcon={
        (loading && <CircularProgress size="1em" color="inherit" />) ||
        props.startIcon
      }
    >
      {children}
    </Button>
  );
}
