/**
 * Gets the number of "waiting" tickets from the store, optionally filtered for
 * a particular course
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

export const getWaitingCount = createSelector(
  orm.Course.tickets,
  (tickets) => tickets.flat().filter((t) => 'waiting' === t.status).length
);

export default function useWaitingCount(courseId) {
  return useSelector((state) => getWaitingCount(state, courseId));
}
