export function userFetching(id) {
  return {
    type: 'USER_FETCHING',
    payload: id,
  };
}

export function userFetched(attrs) {
  return {
    type: 'USER_FETCHED',
    payload: attrs,
  };
}

export function userAdded(attrs) {
  return {
    type: 'USER_ADDED',
    payload: attrs,
  };
}

export function userChanged(attrs) {
  return {
    type: 'USER_CHANGED',
    payload: attrs,
  };
}

export function userRemoved(id) {
  return {
    type: 'USER_REMOVED',
    payload: id,
  };
}

export function presenceChanged(attrs) {
  return {
    type: 'PRESENCE_CHANGED',
    payload: attrs,
  };
}

export function shiftStarted(attrs) {
  return {
    type: 'SHIFT_STARTED',
    payload: attrs,
  };
}

export function shiftChanged(attrs) {
  return {
    type: 'SHIFT_CHANGED',
    payload: attrs,
  };
}

export function shiftEnded(attrs) {
  return {
    type: 'SHIFT_ENDED',
    payload: attrs,
  };
}
