/**
 * Gets the single newest completed ticket in a particular course for which the
 * authenticated user is a student
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getAccount } from 'selectors/auth/use-account';
import { getRecentTickets } from './use-recent-tickets';

export const getMyLastCompletedTicket = createSelector(
  getRecentTickets,
  getAccount,
  (tickets, account) =>
    tickets
      .filter((t) => t.students.some((u) => u.accountId === account.id))
      .sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt))[0]
);

export default function useMyLastCompletedTicket(courseId) {
  return useSelector((state) => getMyLastCompletedTicket(state, courseId));
}
