import { useMemo } from 'react';
import useAccountUsers from './use-account-users';

export default function useAccountUser(courseId) {
  const users = useAccountUsers();
  return useMemo(() => users.find((u) => u.courseId === courseId), [
    users,
    courseId,
  ]);
}
