import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import SettingsNavList from './settings-nav-list';
import NavBar from '../../../../components/app-bar/app-bar';

export default function SettingsIndexPage() {
  const params = useParams();
  const courseId = Number(params.courseId);

  return (
    <>
      <NavBar title="Course settings" />

      <Box p={1} clone>
        <Paper>
          <Box p={2} clone>
            <Typography>
              Select an option below to configure your course.
            </Typography>
          </Box>
          <SettingsNavList courseId={courseId} />
        </Paper>
      </Box>
    </>
  );
}
