import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Add';
import CourseCreateDialog from './course-create-dialog';

export default function CourseCreateButton({ children, ...params }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick} startIcon={<CreateIcon />} {...params}>
        {children || 'Create course'}
      </Button>

      <CourseCreateDialog open={open} onClose={handleClose} />
    </>
  );
}
