/**
 * Prepares the table data. This function should be memoized for performance.
 * Input parameters `tickets` and `teachers` must be arrays, but can be empty.
 */

import teacherTickets from '../../../data-shapers/teacher-tickets';
import teacherShifts from '../../../data-shapers/teacher-shifts';

export default function makeData({
  tickets,
  teachers,
  shifts,
  filterEnabled = true,
  filterMs = 45 * 60 * 1000, // 45 minutes
}) {
  const ticketDict = teacherTickets(tickets);
  const shiftDict = teacherShifts(shifts);

  return teachers.map((user) => {
    // Default values for record
    const record = {
      name:
        user.last || user.first ? `${user.last}, ${user.first}` : user.email,
      ticketCount: 0,
      shiftCount: 0,
      msSpentHelping: 0,
      msSpentOnDuty: 0,
      avgHelpingMs: -1, // indicates invalid value (for table sorting)
    };

    // Incorporate ticket information (if exists)
    if (user.accountId in ticketDict) {
      const completedTickets = ticketDict[user.accountId].filter(
        (t) => t.status === 'completed'
      );
      const helpingDurations = completedTickets
        .map((t) => new Date(t.completedAt) - new Date(t.startedAt))
        .filter((ms) => !filterEnabled || ms < filterMs);
      const msSpentHelping = helpingDurations.reduce((cum, ms) => cum + ms, 0);
      record.ticketCount = completedTickets.length;
      record.msSpentHelping = msSpentHelping;
      if (helpingDurations.length > 0) {
        record.avgHelpingMs = Math.round(
          msSpentHelping / helpingDurations.length
        );
      }
    }

    // Incorporate shift information (if exists)
    if (user.accountId in shiftDict) {
      const userShifts = shiftDict[user.accountId];
      const shiftCount = userShifts.length;
      const msSpentOnDuty = userShifts.reduce(
        (ms, s) => ms + (new Date(s.endAt) - new Date(s.startAt)),
        0
      );
      if (shiftCount > 0) {
        record.shiftCount = shiftCount;
        record.msSpentOnDuty = msSpentOnDuty;
      }
    }

    // All done!
    return record;
  });
}
