import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from 'components/content/dialog';
import EditUserForm from './form';

export default function EditUserDialog({
  fields,
  onClickDelete,
  onClose,
  onComplete,
  ...props
}) {
  return (
    <Dialog {...props}>
      <DialogTitle disableTypography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h6">Edit user record</Typography>
          </Grid>

          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <EditUserForm
          fields={fields}
          courseId={fields?.courseId}
          userId={fields?.id}
          onComplete={onComplete}
          onClickDelete={onClickDelete}
        />
      </DialogContent>
    </Dialog>
  );
}
