import React from 'react';
import { Redirect } from 'react-router-dom';
import useIsAuthenticated from 'selectors/auth/use-is-authenticated';
import SigninDialog from './signin-dialog';

export default function SigninPage() {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated && window.location.pathname === '/signin') {
    return <Redirect to="/" />;
  } else {
    return <SigninDialog open />;
  }
}
