import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';

import useCourse from 'selectors/courses/use-course';
import LoadingButton from 'components/buttons/loading-button';

const headers = [
  'first',
  'last',
  'group',
  'identifier',
  'startAt',
  'endAt',
  'location',
];

export default function ExportShiftsButton({
  courseId,
  shifts,
  isLoading,
  ...props
}) {
  const course = useCourse(courseId);

  const handleClick = () => {
    const csvExporter = new ExportToCsv({
      filename: exportShiftsFilename(course),
      headers,
      showLabels: true,
    });
    csvExporter.generateCsv(format(shifts));
  };

  return <LoadingButton {...props} loading={isLoading} onClick={handleClick} />;
}

function exportShiftsFilename(course) {
  const date = moment().format('YYYY-MM-DD');
  const time = moment().format('HH-mm');
  return [course.code, course.semester, course.year, 'shifts', date, time]
    .filter((x) => x)
    .join('-')
    .replace(' ', '-');
}

function format(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return [{}];
  } else {
    return data.map((t) => ({
      first: formatValue(t.user.first),
      last: formatValue(t.user.last),
      group: formatValue(t.user.group),
      identifier: formatValue(t.user.identifier),
      startAt: formatValue(t.startAt),
      endAt: formatValue(t.endAt),
      location: formatValue(t.location),
    }));
  }
}

function formatValue(value) {
  if ([null, undefined].includes(value)) {
    return '';
  } else if (Array.isArray(value)) {
    return JSON.stringify(value);
  } else {
    return value;
  }
}
