import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import ExportTab from './export-tab/export-tab';
import StudentTab from './student-tab/student-tab';
import StaffTab from './staff-tab/staff-tab';
import SurveyTab from './survey-tab/survey-tab';

import fetchInteractions from 'api/routes/statistics/fetch-interactions';
import fetchRoster from 'api/routes/statistics/fetch-roster';
import fetchShifts from 'api/routes/statistics/fetch-shifts';

export default function StatisticsPage() {
  const [tab, setTab] = useState('student');
  const params = useParams();
  const courseId = Number(params.courseId);
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
  }, [courseId]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    let isActive = true;
    Promise.all([
      fetchInteractions(courseId),
      fetchRoster(courseId),
      fetchShifts(courseId),
    ])
      .then(([{ data: tickets }, { data: users }, { data: shifts }]) => {
        if (isActive) {
          setTickets(tickets);
          setStudents(users.filter((u) => u.role === 'student'));
          setTeachers(
            users.filter((u) => ['staff', 'instructor'].includes(u.role))
          );
          setShifts(shifts);
        }
      })
      .finally(() => {
        if (isActive) {
          setIsLoading(false);
        }
      });

    return () => {
      isActive = false;
    };
  }, [isLoading, courseId]);

  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <>
      <Box m={2}>
        <Paper>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Student Usage" value="student" />
            <Tab label="Staff Usage" value="staff" />
            <Tab label="Surveys" value="survey" />
            <Tab label="Export" value="export" />
          </Tabs>

          <Box p={2}>
            {tab === 'student' && (
              <StudentTab
                tickets={tickets}
                studentCount={students.length}
                isLoading={isLoading}
              />
            )}
            {tab === 'staff' && (
              <StaffTab
                tickets={tickets}
                teachers={teachers}
                shifts={shifts}
                isLoading={isLoading}
              />
            )}
            {tab === 'survey' && (
              <SurveyTab
                courseId={courseId}
                tickets={tickets}
                isLoading={isLoading}
              />
            )}
            {tab === 'export' && (
              <ExportTab
                courseId={courseId}
                tickets={tickets}
                shifts={shifts}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
}
