import React, { forwardRef } from 'react';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import QuestionTypeIcon from './question-type-icon';

export default forwardRef(SelectQuestionType);

function SelectQuestionType(props, ref) {
  return (
    <Select ref={ref} variant="standard" {...props}>
      <MenuItem value="short-answer">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <QuestionTypeIcon type="short-answer" isFilled color="action" />
          </Grid>
          <Grid item>Short answer</Grid>
        </Grid>
      </MenuItem>

      <MenuItem value="free-response">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <QuestionTypeIcon type="free-response" isFilled color="action" />
          </Grid>
          <Grid item>Free response</Grid>
        </Grid>
      </MenuItem>

      <MenuItem value="multiple-choice">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <QuestionTypeIcon type="multiple-choice" isFilled color="action" />
          </Grid>
          <Grid item>Multiple choice</Grid>
        </Grid>
      </MenuItem>

      <MenuItem value="select-all">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <QuestionTypeIcon
              type="select-all"
              isFilled={true}
              color="action"
            />
          </Grid>
          <Grid item>Select all that apply</Grid>
        </Grid>
      </MenuItem>
    </Select>
  );
}
