import moment from 'moment';

export default function numTicketsPerHour(tickets, field = 'requestedAt') {
  const arr = new Array(24);
  for (let i = 0; i < 24; i++) {
    arr[i] = 0;
  }
  tickets.forEach((ticket) => {
    const hour = Number.parseInt(moment(ticket[field]).format('H'));
    arr[hour]++;
  });
  return arr;
}
