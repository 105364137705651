import React, { useMemo } from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import { ResponsiveCalendarCanvas } from '@nivo/calendar';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeData from './make-data';

export default function TrafficHeatmapChart({ tickets, isLoading }) {
  const { data, from, to, colors } = useMemo(() => makeData(tickets), [
    tickets,
  ]);

  if (isLoading) {
    return (
      <Typography>
        <em>Loading data...</em>
      </Typography>
    );
  } else if (data.length === 0) {
    return (
      <Typography>
        <em>No data to display.</em>
      </Typography>
    );
  }

  return (
    <div style={{ height: '150px' }}>
      <ResponsiveCalendarCanvas
        data={data}
        from={from}
        to={to}
        width={820}
        direction="horizontal"
        daySpacing={1}
        dayBorderColor="#ffffff"
        emptyColor="#e0e0e0"
        minValue={1}
        maxValue="auto"
        colors={colors}
        align="top-left"
        margin={{ top: 20, right: 20, bottom: 0, left: 20 }}
        yearLegend={(year) => 'S  F  T  W  T  M  S'}
        tooltip={Tooltip}
      />
    </div>
  );
}

function Tooltip({ date, value }) {
  return (
    <Paper>
      <Box py={0.5} px={1}>
        {pluralize('request', value, true)} on{' '}
        {moment(date).format('ddd, MMM Do')}
      </Box>
    </Paper>
  );
}
