import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

import ExpandIcon from '@material-ui/icons/ExpandMore';

import useAccountUsers from 'selectors/users/use-account-users';
import useCourses from 'selectors/courses/use-courses';
import CourseCard from './course-card';
import CourseSearchButton from './course-search/course-search-button';
import CourseCreateButton from './course-create/course-create-button';

export default function ActiveCoursesSection() {
  const data = useData();

  return (
    <Box clone mb={2}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="h5">Active courses ({data.length})</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box>
            {data.map(({ user, course }) => (
              <CourseCard
                key={user.courseId}
                userStatus={user.status}
                courseStatus={course?.status}
                role={user.role}
                courseId={user.courseId}
                school={course?.school}
                code={course?.code}
                title={course?.title}
                semester={course?.semester}
                year={course?.year}
              />
            ))}
          </Box>
        </AccordionDetails>

        <AccordionActions>
          <CourseSearchButton color="primary" />
          <CourseCreateButton color="primary" variant="contained" />
        </AccordionActions>
      </Accordion>
    </Box>
  );
}

function useData() {
  const users = useAccountUsers();
  const courseIds = useMemo(() => users.map((u) => u.courseId), [users]);
  const courses = useCourses(courseIds);
  const data = useMemo(
    () =>
      users
        .map((user, i) => ({ user, course: courses[i] }))
        .filter(({ course }) => course?.status === 'active'),

    [users, courses]
  );
  return data;
}
