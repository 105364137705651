import { useState, useCallback, useMemo } from 'react';

import useMyRecentTickets from 'selectors/tickets/use-my-recent-tickets';
import useAccount from 'selectors/auth/use-account';

export default function useTicketsAwaitingFeedback() {
  const allTickets = useMyRecentTickets();
  const [loadTime] = useState(Date.now());
  const [finishedIds, setFinishedIds] = useState([]);
  const account = useAccount();

  const markCompleted = useCallback((t) => {
    const id = typeof t === 'number' ? t : t.id;
    setFinishedIds((finishedIds) =>
      finishedIds.includes(id) ? finishedIds : [...finishedIds, id]
    );
  }, []);

  const tickets = useMemo(
    () =>
      allTickets
        .filter((t) => !finishedIds.includes(t.id))
        .filter((t) =>
          [...t.students, ...t.teachers].some(
            (u) => u.accountId === account.id && !u.hasFeedback
          )
        )
        .filter((t) => new Date(t.completedAt).getTime() > loadTime),
    [allTickets, finishedIds, account.id, loadTime]
  );

  return [tickets, markCompleted];
}
