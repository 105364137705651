import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import AppBar from 'components/app-bar/app-bar';

import {
  Scheduler,
  WeekView,
  Appointments,
} from '@devexpress/dx-react-scheduler-material-ui';

export default function CalendarPage() {
  return (
    <AppBar title="Calendar">
      <Box clone p={2}>
        <Paper>
          <Scheduler data={[]}>
            <WeekView startDayHour={8} endDayHour={20} cellDuration={60} />
            <Appointments />
          </Scheduler>
        </Paper>
      </Box>
    </AppBar>
  );
}
