/**
 * Fetches all staff members for a given course from the server; debounces rapid
 * requests for the same course
 *
 * NOTE: This is a redux action, so it must be dispatched
 */

import loadRecords from 'actions/load-records';
import courseStaffIndexRoute from 'api/routes/users/course-staff-index';

const promises = {};

export default function fetchStaff(courseId) {
  return (dispatch) => {
    if (!Number.isInteger(courseId) || courseId <= 0) {
      return;
    } else if (courseId in promises) {
      return promises[courseId];
    }
    const promise = courseStaffIndexRoute(courseId)
      .then(({ data }) => {
        dispatch(loadRecords(data));
      })
      .finally(() => {
        delete promises[courseId];
      });
    promises[courseId] = promise;
    return promise;
  };
}
