import React from 'react';
import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import AlertDialog from '../../../../../components/dialogs/alert';

export default function HelpDialogButton({ currentStep, ...props }) {
  return (
    <AlertDialog
      title="How to get help at office hours"
      body={<HandStepper currentStep={currentStep} />}
      buttonText="More info"
      {...props}
    />
  );
}

function HandStepper({ currentStep }) {
  return (
    <Stepper orientation="vertical">
      <Step active>
        <StepLabel>Raise your hand for help</StepLabel>
        <StepContent>
          Raising your hand will add you to the office hours waitlist. You may
          be asked a few questions about your issue before you are added to the
          waitlist. If other students arrived before you, they&apos;ll be helped
          first.
        </StepContent>
      </Step>

      <Step active>
        <StepLabel>Wait to be called</StepLabel>
        <StepContent>
          Stay near the designated office hours area and keep working while you
          wait. Keep checking My Digital Hand so you know when to be ready.
        </StepContent>
      </Step>

      <Step active>
        <StepLabel>Find your assigned staff member</StepLabel>
        <StepContent>
          Once it&apos;s your turn, you will be assigned an available staff
          member who will help you with your problem. When the system alerts
          you, go find your assigned help staff member.
        </StepContent>
      </Step>

      <Step active>
        <StepLabel>Get help</StepLabel>
        <StepContent>
          The help staff member will do their best to help you with your
          problem. During busy office hours, they may not have time to fully
          resolve your issue. However, they&apos;ll leave you with a plan so
          that you can continue working on the problem on your own.
        </StepContent>
      </Step>

      <Step active>
        <StepLabel>Leave feedback</StepLabel>
        <StepContent>
          After you are finished, please consider giving feedback about your
          experience. This helps make office hours even better!
        </StepContent>
      </Step>
    </Stepper>
  );
}
