import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SuccessIcon from '@material-ui/icons/CheckCircle';

import Link from 'components/content/link';

export default function JoinSuccess({
  status,
  email,
  role,
  courseName,
  courseId,
}) {
  if (status === 'pending') {
    return (
      <Grid container spacing={2} alignContent="center">
        <Grid item>
          <SuccessIcon color="disabled" />
        </Grid>

        <Grid item xs>
          <Typography variant="body1" color="textSecondary">
            To complete your enrollment as a student in {courseName}, you must
            confirm <strong>{email}</strong> as a valid email address. Please
            check your email inbox for a confirmation link.
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={2} alignContent="center">
        <Grid item>
          <SuccessIcon color="disabled" />
        </Grid>

        <Grid item xs>
          <Typography variant="body1" color="textSecondary">
            Success! <RoleText role={role} name={courseName} />
          </Typography>

          <Link to={`/courses/${courseId}`} variant="body2">
            Go to the {courseName} page now
          </Link>
        </Grid>
      </Grid>
    );
  }
}

function RoleText({ role, name = 'this course' }) {
  switch (role) {
    case 'instructor':
      return `You are enrolled as an instructor in ${name}`;
    case 'staff':
      return `You are enrolled as a staff member in ${name}`;
    case 'student':
      return `You are enrolled as a student in ${name}`;
    default:
      return `You are not enrolled in ${name}`;
  }
}
