import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import { TimePicker } from '@material-ui/pickers';
import { Alert } from '@material-ui/lab';

import SaveIcon from '@material-ui/icons/Done';

import LoadingButton from 'components/buttons/loading-button';
import createShiftRoute from 'api/routes/shifts/create-shift';
import updateShiftRoute from 'api/routes/shifts/update-shift';

export default function ShiftForm({ shift, courseId, onSave }) {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    errors,
    control,
    watch,
  } = useForm();

  const { isSubmitting } = formState;
  const startAt = new Date(shift?.startAt);
  const endAt = new Date(shift?.endAt);
  const isActive = endAt.getTime() > Date.now();

  useEffect(() => {
    if (shift) {
      reset({
        location: shift.location,
        endMoment: moment(shift.endAt),
      });
    }
  }, [reset, shift]);

  const onSubmit = async ({ endMoment, location }) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const startTime = isActive ? startAt.getTime() : Date.now();
    let endTime = endMoment.toDate().getTime();
    while (endTime < startTime) {
      endTime += oneDay;
    }
    while (endTime > startTime + oneDay) {
      endTime -= oneDay;
    }
    if (isActive) {
      await updateShiftRoute({
        id: shift.id,
        location,
        endAt: new Date(endTime).toISOString(),
      });
    } else {
      await createShiftRoute({
        courseId,
        location,
        endAt: new Date(endTime).toISOString(),
      });
    }
    onSave instanceof Function && onSave();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TimePicker
        label="Starting time"
        inputVariant="outlined"
        margin="normal"
        fullWidth
        disabled
        value={moment(isActive ? shift.startAt : undefined)}
      />

      <Controller
        label="Expected ending time"
        inputVariant="outlined"
        margin="normal"
        fullWidth
        name="endMoment"
        control={control}
        as={TimePicker}
        error={Boolean(errors.endMoment)}
        helperText="Required field"
        rules={{
          required: true,
          validate: (x) => moment(x).isValid(),
        }}
      />

      <ShiftWarning
        startTime={moment(isActive ? shift.startAt : undefined)}
        endTime={watch('endMoment')}
      />

      <TextField
        label="Location"
        variant="outlined"
        margin="normal"
        fullWidth
        name="location"
        error={Boolean(errors.location)}
        helperText="Tell students where you're holding office hours"
        disabled={isSubmitting}
        inputRef={register()}
      />

      <Box my={2}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isSubmitting}
          type="submit"
          startIcon={<SaveIcon />}
        >
          Done
        </LoadingButton>
      </Box>
    </form>
  );
}

function ShiftWarning({ startTime, endTime }) {
  let hours = Math.round(
    (moment(endTime).valueOf() - moment(startTime).valueOf()) / 1000 / 60 / 60
  );
  while (hours < 0) {
    hours += 24;
  }
  return (
    <Collapse in={hours >= 6}>
      <Alert severity="warning">
        Careful! Did you mean for your shift to be {hours} hour
        {hours === 1 ? '' : 's'} long?
      </Alert>
    </Collapse>
  );
}
