import React, { forwardRef } from 'react';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import RemoteHelpIcon from '@material-ui/icons/PresentToAll';

export default forwardRef(RemoteHelpMenuItem);

function RemoteHelpMenuItem({ id, onClick, ...props }, ref) {
  return (
    <MenuItem onClick={onClick} ref={ref} {...props}>
      <ListItemIcon>
        <RemoteHelpIcon color="primary" />
      </ListItemIcon>
      <ListItemText>Add a meeting link for virtual help</ListItemText>
    </MenuItem>
  );
}
