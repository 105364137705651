import React, { useState, useEffect } from 'react';
import Badge from '@material-ui/core/Badge';
import WaitlistIcon from '@material-ui/icons/List';
import useWaitingCount from 'selectors/tickets/use-waiting-count';

export default function WaitlistBadgeIcon({ courseId, ...props }) {
  const waitingCount = useWaitingCount(courseId);
  const [lastCount, setLastCount] = useState(waitingCount);

  useEffect(() => {
    if (lastCount < waitingCount) {
      new Audio('/sounds/bell.mp3').play();
    }
    if (lastCount !== waitingCount) {
      setLastCount(waitingCount);
    }
  }, [lastCount, waitingCount]);

  return (
    <Badge color="secondary" badgeContent={waitingCount} {...props}>
      <WaitlistIcon />
    </Badge>
  );
}
