export function authValidated(account) {
  return {
    type: 'AUTH_VALIDATED',
    payload: { isAuthenticated: true, account },
  };
}

export function authChanged(isAuthenticated, account) {
  return {
    type: 'AUTH_CHANGED',
    payload: { isAuthenticated, account },
  };
}

export function authInvalidated() {
  return {
    type: 'AUTH_INVALIDATED',
  };
}

export function accountChanged(account) {
  return {
    type: 'ACCOUNT_CHANGED',
    payload: { account },
  };
}
