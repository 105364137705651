/**
 * Gets only "closed" tickets, optionally filtered for a particular course
 */

import { useSelector } from 'react-redux';
import { createSelector } from 'redux-orm';
import orm from 'models/orm';

export const getRecentTickets = createSelector(orm.Course.tickets, (tickets) =>
  tickets
    .flat()
    .filter((t) => t.status === 'completed')
    .sort(newestFirst)
);

export default function useRecentTickets(courseId) {
  return useSelector((state) => getRecentTickets(state, courseId));
}

function oldestFirst(a, b) {
  return new Date(a.requestedAt) - new Date(b.requestedAt);
}

function newestFirst(a, b) {
  return -oldestFirst(a, b);
}
