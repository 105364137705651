import React from 'react';

import useHand from 'hooks/use-hand';

import HandCardDown from './hand-card-down';
import HandCardWaiting from './hand-card-waiting';
import HandCardCalling from './hand-card-calling';
import HandCardHelping from './hand-card-helping';

export default function HandCard({ courseId }) {
  const { status, ticket } = useHand(courseId);

  switch (status) {
    default:
    case 'down':
      return <HandCardDown courseId={courseId} />;
    case 'waiting':
      return <HandCardWaiting ticket={ticket} />;
    case 'calling':
      return <HandCardCalling ticket={ticket} />;
    case 'helping':
      return <HandCardHelping ticket={ticket} />;
  }
}
