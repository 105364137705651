import { useState, useEffect } from 'react';

export default function MinuteCountdown({ endTimestamp, interval = 1000 }) {
  const [mins, setMins] = useState(0);

  useEffect(() => {
    if (mins > 0) {
      const timer = setTimeout(() => {
        setMins(
          Math.ceil((new Date(endTimestamp).getTime() - Date.now()) / 1000 / 60)
        );
      }, interval);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [endTimestamp, mins, interval]);

  useEffect(() => {
    setMins(
      Math.ceil((new Date(endTimestamp).getTime() - Date.now()) / 1000 / 60)
    );
  }, [endTimestamp]);

  if (mins > 1) {
    return `${mins} minutes`;
  } else if (mins === 1) {
    return '1 minute';
  } else {
    return null;
  }
}
