import { useEffect, useState } from 'react';
import useStaff from 'selectors/courses/use-staff';

export default function useStaffAvailability(courseId) {
  const [, setCounter] = useState(0); // just used to force rerender
  const staff = useStaff(courseId);

  const working = [];
  const online = [];
  const offline = [];

  staff.forEach((user) => {
    if (user.shift && new Date(user.shift.endAt).getTime() > Date.now()) {
      working.push(user);
    } else if (user.presence === 'online') {
      online.push(user);
    } else {
      offline.push(user);
    }
  });

  const soonestShiftEndingTimestamp = Math.min(
    ...working.map((u) => new Date(u.shift.endAt).getTime())
  );

  useEffect(() => {
    const endsInMs = soonestShiftEndingTimestamp - Date.now();
    if (endsInMs < Infinity) {
      const timer = setTimeout(() => {
        setCounter((x) => x + 1);
      }, endsInMs);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [soonestShiftEndingTimestamp]);

  return { working, online, offline };
}
