import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CancelIcon from '@material-ui/icons/Close';
import SubmitIcon from '@material-ui/icons/Done';

import openTicketRoute from 'api/routes/tickets/open-ticket';

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
  },
}));

export default function VirtualLinkForm({ ticketId, onSubmit, onCancel }) {
  const classes = useStyles();
  const [url, setUrl] = useState('');

  const handleChange = useCallback((event) => {
    setUrl(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onSubmit instanceof Function && onSubmit(event);
      return openTicketRoute(ticketId, url);
    },
    [url, ticketId, onSubmit]
  );

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container justify="flex-end" spacing={2}>
        <Grid item xs={12}>
          <Box mb={1}>
            <TextField
              onChange={handleChange}
              value={url}
              fullWidth
              autoFocus
              placeholder="Paste your virtual meeting link here"
              variant="outlined"
            />
          </Box>
        </Grid>

        <Grid item>
          <Button
            type="button"
            color="primary"
            onClick={onCancel}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            startIcon={<SubmitIcon />}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
